﻿import { Component } from '@angular/core';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';
import { Router } from '@angular/router';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { DoorDesign, DoorInsulationOption } from 'src/app/shared/models';

@Component({
    selector: 'app-select-insulation',
    templateUrl: './insulation.component.html',
    styleUrls: ['./insulation.component.css'],
})
export class DBDesignInsulationComponent extends DBStateComponent {
    private insulations: DoorInsulationOption[];
    //private wrapperStyling: Object;

    constructor(public dbService: BuildADoorService, public router: Router, public dbFooterService: DBFooterService, private productService: ProductService) {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        super.verifyState();
        //if (!this.dbService.customerDesignedDoor.doorDesign) {
        //    this.router.navigate(['/door-builder/design/design-home']);
        //}
        this.insulations = super.sortCollectionByDisplayOrder(this.dbService.customerDesignedDoor.doorDesign.getInsulationOptions(this.dbService.customerDesignedDoor.width, this.dbService.customerDesignedDoor.width.inches));
        if (this.dbService.customerDesignedDoor.width && this.dbService.customerDesignedDoor.width.inches > 0) {
            let validInsulations = []
            for (var i = 0; i < this.insulations.length; i++) {
                if (!this.insulations[i].disallowInches) {
                    validInsulations.push(this.insulations[i]);
                }
            }
            this.insulations = validInsulations;
        }
        if (this.dbService.customerDesignedDoor.windSpeed) {
            let insulationsForWindspeed = await this.dbService.getInsulationLevelsForWindLoad();
            let validInsulations = [];
            for (var i = 0; i < this.insulations.length; i++) {
                if (insulationsForWindspeed.includes(this.insulations[i]._id)) {
                    validInsulations.push(this.insulations[i]);
                }
            }
            this.insulations = validInsulations;
        }
        this.setCollectionWrapperStyling(this.insulations, true);

        super.ngOnInit();
    }

    getWarrantyLength(ins: DoorInsulationOption) {
        let warranty = '';
        if (ins.specifications) {
            for (var i = 0; i < ins.specifications.length; i++) {
                let spec = ins.specifications[i];
                if (spec.field === 'WORKMANSHIP/HARDWARE WARRANTY') {
                    warranty = spec.value;
                    warranty = warranty.replace('Years', 'Year');
                    break;
                }
            }
        }
        return warranty;
    }

    getCollectionStyle(index) {
        let style = super.collectionStyleNoColumns(index);
        style['grid-template-columns'] = '30% 70%';
        return style;
    }

    getCollectionClass(io) {
        return super.collectionClass(this.dbService.customerDesignedDoor.insulation, io);
    }

    async onClickInsulation(io) {
        this.dbService.customerDesignedDoor.insulation = io;
        if (this.dbService.customerDesignedDoor.windSpeed) {
            let windLoadPrice = await this.dbService.checkExistingWindSpeedPrice();
            this.dbService.customerDesignedDoor.windLoadPrice = windLoadPrice;
        }
        this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
        this.dbService.getWindowPrices();
    }

    isShowContinue() {
        if (this.dbService.customerDesignedDoor.insulation) return true;
        return false;
    }

    onClickContinue() {
        if (this.dbService.isHideWindows()) {
            super.onClickContinue(this.dbService.customerDesignedDoor.color, '/door-builder/design/select-color');
        } else {
            super.onClickContinue(this.dbService.customerDesignedDoor.window, '/door-builder/design/select-window');
        }
    }

    onClickBack() {
        this.dbService.navButtonPressed = true;
        if (this.dbService.isHidePanelDesigns()) {
            this.router.navigate(['/door-builder/design/select-model']);
        } else {
            this.router.navigate(['/door-builder/design/panel-design']);
        }
    }

}