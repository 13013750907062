import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { BuildADoorService, ProductService } from '../shared/services';
import { DoorSpecial, BuildingZone } from '../shared/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    specials: DoorSpecial[];
    private styles: Object[];
    zone: BuildingZone;
    private scrWidth: number;

    constructor(private router: Router, private productService: ProductService, private dbService: BuildADoorService, private changeDetector: ChangeDetectorRef) { }

  async ngOnInit() {
      await this.dbService.initializeZonesAndSpecials();
      this.zone = this.dbService.defaultZone;
      this.productService.getStyles().subscribe(result => {
          if (result && result['styles']) {
              this.styles = result['styles'];
          }
      });
     this.productService.getSpecials({}).subscribe(result => {
          this.specials = [];
          if (result && result['specials']) {
              this.specials = result['specials'];
          }
      });
      this.router.navigate(['/door-builder-home']);
  }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

    getZoneForSpecial(special: DoorSpecial) {
        if (special && special.zone) {
            return special.zone.name;
        }
        return "Residential";
    }

    getButtonLabelForSpecial(special: DoorSpecial) {
        let label = 'Shop ';
        if (special && special.productGroup) {
            label += special.productGroup.name;
            label += ' Style ';
        } else {
            label += 'All ';
        }
        label += 'Doors';
        return label;
    }

    onProductLinkClick(destination: string) {
        this.router.navigateByUrl(destination);
    }

    onClickBuildDoor() {
        this.dbService.startOver();
        this.router.navigate(['/door-builder-home']);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
    }

    isMobile() {
        if (!this.scrWidth) this.getScreenSize();
        return this.scrWidth <= 1224
    }
}
