﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';

@Injectable({
    providedIn: 'root'
})
export class UserService {

  private loggedIn = false;
  private loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  private role = "";
  private role$ = new BehaviorSubject<string>(this.role);
  private hasAdmin = false;
  private hasAdmin$ = new BehaviorSubject<boolean>(this.hasAdmin);
  private username;
  private users = [];
  private users$ = new BehaviorSubject<User[]>(this.users);

  constructor(private http: HttpClient) {
    this.loggedIn = !!localStorage.getItem('token');
  }

  login(userName: string, password: string) {
    this.username = userName;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var userData = {
      username: userName,
      password: password
    };

    return this.http
      .post('/auth/login', userData, { headers: headers })
      .pipe(map(res => {
        localStorage.setItem('token', res['token']);
        var user = res['user'];
        this.loggedIn = true;
        this.loggedIn$.next(this.loggedIn);
        if (user) {
          this.role = user.role;
          if (this.role && this.role.indexOf("admin") > -1) {
            this.hasAdmin = true;
          }
          else {
            this.hasAdmin = false;
          }
          this.hasAdmin$.next(this.hasAdmin);
          this.role$.next(this.role);
        }
        return true;
      }));
  }

  getUsername(): string {
    return this.username;
  }

  isLoggedIn(): Observable<boolean> {
    return this.loggedIn$;
  }

  public get isLoggedInValue(): boolean {
    return this.loggedIn$.value;
  }

  isAdmin(): Observable<boolean> {
    return this.hasAdmin$;
  }

  getUsers(): Observable<User[]> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    });
    return this.http.get<User[]>('users', { headers: headers });
  }

  getUser(id: string): Observable<User> {
    return this.getUsers().pipe(map(result => {
      if (!result['users']) return null;
      return result['users'].find(user => user._id == id);
    }));
  }

  addEditUser(user: User): Observable<boolean | Observable<never>> {
    if (user && user.firstName && user.lastName && user.email) {
      var headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      });
      return this.http.post<User>('users', user, { headers: headers }).pipe(map(r => {
        this.users.filter(function (others) {
          return others._id !== user._id;
        });
        this.users.push(r);
        this.users$.next(this.users);
        return true;
      }));
    }
    return of(false);
  }

  deleteUsers(users: User[]) {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
    });
    var httpOptions = {
      headers: headers, body: users
    };
    return this.http.delete<User[]>('users', httpOptions).pipe(map(r => {
      const setToDelete = new Set(users);
      this.users.filter(function (others) {
        return !setToDelete.has(others);
      });
      this.users$.next(this.users);
      return true;
    }));
  }
}
