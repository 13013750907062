﻿import { Component, ViewChild, ElementRef } from '@angular/core';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';
import { DBNavState } from 'src/app/shared/models';

@Component({
    selector: 'app-db-footer',
    templateUrl: './db-footer.component.html',
    styleUrls: ['./db-footer.component.css'],
})
export class DBFooterComponent {
    @ViewChild('dbFooterDiv') elementView: ElementRef;
    private percentComplete: number;
    private totalStates: number;
    private allStates: DBNavState[];
    private message: string;
    private progressDivWidth: string = "0%";

    constructor(private dbService: BuildADoorService, private dbFooterService: DBFooterService) {
        if (!this.dbService.updateFooterEventSubscriber) {
            this.dbService.invokeUpdateFooter.subscribe((s) => {
                this.onStateChange(s);
            });
        }

        if (!this.dbService.setupFooterEventSubscriber) {
            this.dbService.invokeSetupFooter.subscribe((s) => {
                this.setup(s);
            });
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.dbService.dbFooterHeight = this.elementView.nativeElement.offsetHeight;
    }

    getProgressDivWidth() {
        return this.dbFooterService.percentComplete + '%';
    }

    onStateChange(state: DBNavState) {
        var activatedStates = this.getActivatedStatesCount(state);
        this.percentComplete = Math.round(activatedStates * 100.0 / this.totalStates);
        this.message = state.footerMessage;
        this.progressDivWidth = this.percentComplete + '%';
    }

    setup(statesInfo) {
        if (!statesInfo.states)
            return;
        this.allStates = statesInfo.states;
        var endState = statesInfo.states[statesInfo.states.length - 1];
        if (endState.hasSubstates()) {
            endState = endState.subStates[endState.subStates.length - 1];
        }
        this.totalStates = this.getActivatedStatesCount(endState);
        if (statesInfo.activeState)
            this.onStateChange(statesInfo.activeState);
    }

    getActivatedStatesCount(endState: DBNavState): number {
        var stateCount = 0;
        if (this.allStates) {
            for (var i = 0; i < this.allStates.length; i++) {
                stateCount++;
                if (this.allStates[i].pageURL === endState.pageURL)
                    return stateCount;
                if (this.allStates[i].hasSubstates()) {
                    for (var j = 0; j < this.allStates[i].subStates.length; j++) {
                        stateCount++;
                        if (this.allStates[i].subStates[j].pageURL === endState.pageURL)
                            return stateCount;
                    }
                }
            }
        }
        return stateCount;
    }

    getFooterMainDivClass(): string {
        return this.dbFooterService.footerDisplayClass;
    }
}