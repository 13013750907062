import { Injectable, EventEmitter, Output } from '@angular/core';
import { BuildingZone, DoorStyle, DoorSpecial, DBNavState, ProductGroup, CustomerDesignedDoor, DoorDesign, DoorExtra, DoorOpener, GlassType } from '../models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of, throwError, Subscription, Subject } from 'rxjs';
import { DBFooterService } from './db-footer.service';
import { DoorHardware } from '../models/index';
import { PriceQuery } from '../models/price-query';
import { ProductService } from './product.service';
import { LaborZoneRate } from '../models/labor-zone-rate';
import { UserService } from './user.service';
import { Dimension } from '../models/dimension';
import { OrderService } from './order.service';

@Injectable({
    providedIn: 'root'
})
export class BuildADoorService {
    public data: Object = {};
    public invokeNavMenuEvent = new EventEmitter();
    public invokeNavMenuPreviousStateEvent = new EventEmitter();
    public invokeNavMenuChangeStateEvent = new EventEmitter();
    public navMenuEventSubscriber: Subscription;
    public navMenuPreviousStateEventSubscriber: Subscription;
    public navMenuChangeStateEventSubscriber: Subscription;
    public invokeUpdateFooter = new EventEmitter<DBNavState>();
    public invokeSetupFooter = new EventEmitter();
    public updateFooterEventSubscriber: Subscription;
    public setupFooterEventSubscriber: Subscription;
    public navStates: DBNavState[];
    private maxActive: number;
    public activeState: DBNavState;
    public activeSubstate: DBNavState;
    public designState: DBNavState;
    private extrasState: DBNavState;
    private specials: DoorSpecial[];
    public defaultZone: BuildingZone;
    public previousParentState: DBNavState;
    public readonly DELIVER_ONLY = 'deliverOnly';
    public readonly INSTALL = 'install';
    public readonly CLOSED_MARKUP = 'Closed';
    public readonly NONE_MARKUP = 'None';
    //public readonly CLEAR_MARKUP = 'Clear';
    //public readonly DECRAGLASS_MARKUP = 'Decraglass';
    //public readonly DECRATRIM_MARKUP = 'Decratrim';
    public readonly NO_OPERATOR_LABEL = 'No Door Operator Will Ever Be Used';
    public readonly HAVE_OPERATOR_LABEL = 'I am using an Existing Door Operator';
    public readonly BUYING_OPERATOR_LABEL = 'I am Purchasing a new door operator with my door';
    public readonly NON_IMPACT = 'Non-Impact - (90mph - 150mph / WP1 - WP7)';
    public readonly IMPACT = 'Impact - (130mph - 180mph / WP5i - WP8i)';
    public readonly IMPACT_DADE = 'Dade - (165mph - 189mph / WP8i - WP10i)';
    public readonly DESIGN_SHORT_PANEL = 'Short Panel';
    public readonly DESIGN_LONG_PANEL = 'Long Panel';
    public readonly DESIGN_RIBBED_PANEL = 'Ribbed Panel';
    public readonly DESIGN_FLUSH_PANEL = 'Flush Panel';
    public readonly DESIGN_BEAD_BOARD = 'Bead Board';
    public readonly DESIGN_LONG_PANEL_BEAD_BOARD = 'Long Panel Bead Board';
    public readonly DESIGN_RECESSED = 'Recessed';
    public readonly DESIGN_RAISED = 'Raised';
    public readonly DESIGN_TUSCANY = 'Tuscany';
    public setupDoorSizeState: DBNavState;
    public setupState: DBNavState;
    public setupWindRatingState: DBNavState;
    public maxWidth: number = -1;
    public maxHeight: number = -1;
    public dbNavHeight: number = -1;
    public dbFooterHeight: number = 0;
    public navHeight: number = -1;

    public customerDesignedDoor: CustomerDesignedDoor = new CustomerDesignedDoor();
    public selectedProductGroup;
    public price: number;
    public possibleExtras: Object;
    public extras: DoorExtra[];
    public opener: DoorOpener;
    public hasViewedOpeners: boolean = false;
    public hasViewedHardware: boolean = false;
    public hasViewedLocks: boolean = false;
    public hasViewedExtras: boolean = false;
    public hasWindowPricesLoaded: boolean = false;
    public hasInsulationPricesLoaded: boolean = false;
    private appliedPromotion: DoorSpecial;
    public selectedStyle: DoorStyle;
    public isHidePanelDesign: boolean = false;
    //public isHideInsulation: boolean = false;
    //public isHideWindows: boolean = false;
    public zipcode: string;
    public operatorPrepOption: string;
    public laborRate: LaborZoneRate;
    private isUserLoggedIn = false;
    private isUserAdmin = false;
    public navButtonPressed = false;
    public totalTaxes: number;
    public taxRate: number;
    public editItemId: string;
    public isCustomWindows: boolean = false;
    public additionalShipping: number = 0.0;

    constructor(private http: HttpClient, private dbFooterService: DBFooterService, private productService: ProductService, private userService: UserService, private orderService: OrderService) {
        window.console && console.log("BuildADoorService constructor");
        this.initializeNavStates();
        this.initializeZonesAndSpecials();
        this.userService.isLoggedIn().subscribe(b => this.isUserLoggedIn = b);
        this.userService.isAdmin().subscribe(b => this.isUserAdmin = b);
        this.totalTaxes = 0;
        this.taxRate = 0;
    }

    async initializeZonesAndSpecials() {
        if (this.defaultZone && this.specials) return;

        let result = await this.productService.getBuildingZones().toPromise();

        if (result && result['buildingZones'] && result['buildingZones'].length) {
            this.defaultZone = result['buildingZones'][0];
            this.customerDesignedDoor.zone = this.defaultZone;
            let promoResult = await this.productService.getSpecials({ zone: this.defaultZone._id }).toPromise();
            //this.productService.getSpecials({ zone: this.defaultZone._id }).subscribe(result => {
            if (promoResult && promoResult['specials']) {
                this.specials = promoResult['specials'];
            }
            //});
        }
    }

    getLaborRate() {
        let query = this.getPricesQuery();
        //query.zipcode = this.zipcode;
        this.productService.getLaborRate(query).subscribe(result => {
            if (result && result['rates']) {
                this.laborRate = result['rates'];
            }
        });
    }

    getAppliedPromotion(door?: CustomerDesignedDoor) {
        if (door) {
            this.applySpecials(door);
            return door.appliedPromotion;
        }
        return this.appliedPromotion;
    }

    initializeDoorDesign(dd: any): DoorDesign {
        let design: DoorDesign = new DoorDesign(dd);
        if (this.customerDesignedDoor.width.feet && this.customerDesignedDoor.width.inches && design.insulationOptionsAndColors) {
            let hasAllowedInches = false;
            for (let i = 0; i < design.insulationOptionsAndColors.length; i++) {
                if (!design.insulationOptionsAndColors[i].disallowInches) {
                    hasAllowedInches = true;
                    break;
                }
            }
            if (!hasAllowedInches) return null;
        }
        if (this.customerDesignedDoor.width.feet >= 8 || (this.customerDesignedDoor.width.feet == 7 && this.customerDesignedDoor.width.inches >= 6)) {
            return new DoorDesign(dd);
        } else if (design.name === this.DESIGN_BEAD_BOARD || design.name === this.DESIGN_SHORT_PANEL || design.name === this.DESIGN_TUSCANY) {
            return new DoorDesign(dd);
        }
        return null;
    }

    getPricesQuery() {
        var query = new PriceQuery();

        if (this.customerDesignedDoor.width && this.customerDesignedDoor.height) {
            var selectedWidth = this.customerDesignedDoor.width.feet;
            if (this.customerDesignedDoor.width.inches && this.customerDesignedDoor.width.inches >= 4) {
                if (this.customerDesignedDoor.width.feet === 10 || this.customerDesignedDoor.width.feet === 12 || this.customerDesignedDoor.width.feet === 18) {
                    selectedWidth = this.customerDesignedDoor.width.feet + 2;
                } else {
                    selectedWidth++;
                }
            }
            var selectedHeight = this.customerDesignedDoor.height.feet;
            if (this.customerDesignedDoor.height.inches && this.customerDesignedDoor.height.inches > 0)
                selectedHeight++;

            query.width = selectedWidth >= this.customerDesignedDoor.minWidth ? selectedWidth : this.customerDesignedDoor.minWidth;
            query.height = selectedHeight >= this.customerDesignedDoor.minHeight ? selectedHeight : this.customerDesignedDoor.minHeight;
            if (query.height === 11) query.height = 12;
            if (query.height === 13) query.height = 14;
        }

        if (this.customerDesignedDoor.windSpeed) {
            //query.MPH = this.customerDesignedDoor.windSpeed + " MPH";
            query.MPH = this.customerDesignedDoor.windSpeed;
            query.windLoad = true;
        }

        if (this.customerDesignedDoor.impactType == this.IMPACT) {
            query.isImpact = true;
            query.isDade = false;
        } else if (this.customerDesignedDoor.impactType == this.IMPACT_DADE) {
            query.isImpact = false;
            query.isDade = true;
        } else {
            query.isImpact = false;
            query.isDade = false;
        }

        if (this.customerDesignedDoor.productGroup) {
            query.productGroupId = this.customerDesignedDoor.productGroup._id;
        };

        if (this.customerDesignedDoor.insulation) {
            query.insulationOption = this.customerDesignedDoor.insulation;
        }

        return query;
    }

    applySpecials(designedDoor?: CustomerDesignedDoor) {
        if (!designedDoor) designedDoor = this.customerDesignedDoor;
        // work backwards through specificity of product
        if (!this.specials) return;
        // TODO - null checking and validation
        let applicableHeight = designedDoor.height.inches > 0 ? designedDoor.height.feet + 1 : designedDoor.height;
        let applicableWidth = designedDoor.width.inches > 0 ? designedDoor.width.feet + 1 : designedDoor.width;
        let potentialSpecials = this.specials.filter(s => s.salePrice || s.markdown);
        potentialSpecials = potentialSpecials.filter(s => s.height === applicableHeight || !s.height);
        potentialSpecials = potentialSpecials.filter(s => s.width === applicableWidth || !s.width);
        if (designedDoor.windSpeed) {
            //potentialSpecials = potentialSpecials.filter(s => !s.MPH || s.MPH === designedDoor.windSpeed + ' MPH');
            potentialSpecials = potentialSpecials.filter(s => !s.MPH || s.MPH === designedDoor.windSpeed);
         }
        let applicableSpecials = [];
        for (var i = 0; i < potentialSpecials.length; i++) {
            let sp = potentialSpecials[i];
            if (sp.insulationLevel) {
                if (!designedDoor.insulation) continue;
                if (designedDoor.insulation && sp.insulationLevel._id !== designedDoor.insulation.insulationLevel._id) continue;
            }
            if (sp.productGroup) {
                if (!designedDoor.productGroup) continue;
                if (designedDoor.productGroup._id !== sp.productGroup._id) continue;
            }
            if (sp.style) {
                if (!designedDoor.productGroup || !designedDoor.productGroup.style) continue;
                if (sp.style._id !== (designedDoor.productGroup.style._id ? designedDoor.productGroup.style._id : designedDoor.productGroup.style)) continue;
            }
            //if (sp.zone) {
            //    if (!designedDoor.zone) continue;
            //    if (designedDoor.zone._id !== sp.zone._id) continue;
            //}
            applicableSpecials.push(sp);
        }
        potentialSpecials = applicableSpecials;

        if (potentialSpecials.length == 0) {
            this.appliedPromotion = null;
        } else if (potentialSpecials.length > 1) {
            // find the one with the best deal.
            let totalPrice = this.getTotalPrice(designedDoor);
            let winner = 0;
            let appliedPrice = totalPrice;
            for (var i = 0; i < potentialSpecials.length; i++) {
                var special = potentialSpecials[i];
                if (special.markdown) {
                    var specialPrice = totalPrice * (special.markdown / 100);
                    if (specialPrice < appliedPrice) {
                        appliedPrice = totalPrice - specialPrice;
                        winner = i;
                    }
                } else if (special.salePrice && special.salePrice < appliedPrice) {
                    appliedPrice = special.salePrice;
                    winner = i;
                }
            }
            if (winner) {
                this.appliedPromotion = potentialSpecials[winner];
            }
        } else {
            this.appliedPromotion = potentialSpecials[0];
        }
        designedDoor.appliedPromotion = this.appliedPromotion;
        return this.appliedPromotion;
    }

    getSpecials(opts):DoorSpecial[] {
        let params = {};
        if (opts.zone) {
            params['zone'] = opts.zone;
        } else {
            params['zone'] = this.defaultZone._id;
        }
        if (opts.sytle) params['style'] = opts.style;

        return this.specials;
    }

    getTotalPriceBeforeDiscountForSpecials(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) {
            designedDoor = this.customerDesignedDoor;
        }
        let totalPrice = this.getDoorDesignPrice(designedDoor);
        //totalPrice += this.getTrackOptionsPrice(designedDoor);
        //totalPrice += this.getAllExtrasPrice(designedDoor);
        totalPrice += this.getSetupPrice(designedDoor);
        //if (this.isUserLoggedIn && this.isUserAdmin) {
        //    totalPrice += this.getAddOnsPrice(this.customerDesignedDoor);
        //}
        return totalPrice;
    }

    getTotalPriceBeforeDiscount(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) {
            designedDoor = this.customerDesignedDoor;
        }
        let totalPrice = this.getDoorDesignPrice(designedDoor);
        totalPrice += this.getTrackOptionsPrice(designedDoor);
        totalPrice += this.getAllExtrasPrice(designedDoor);
        totalPrice += this.getSetupPrice(designedDoor);
        if (this.isUserLoggedIn && this.isUserAdmin) {
            totalPrice += this.getAddOnsPrice(this.customerDesignedDoor);
        }
        return totalPrice;
    }

    getTotalPriceForSpecials(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) designedDoor = this.customerDesignedDoor;
        let totalPrice = this.getTotalPriceBeforeDiscountForSpecials(designedDoor);

        if (this.customerDesignedDoor.discount && this.customerDesignedDoor.discount.length > 0) {
            for (var i = 0; i < this.customerDesignedDoor.discount.length; i++) {
                let discount = this.customerDesignedDoor.discount[i];
                if (discount.amount) totalPrice = totalPrice - discount.amount;
                else if (discount.percent) totalPrice = totalPrice * ((100.0 - discount.percent) / 100);
            }
        }
        return totalPrice;
    }

    getTotalPrice(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) designedDoor = this.customerDesignedDoor;
        let totalPrice = this.getTotalPriceBeforeDiscount(designedDoor);

        if (this.customerDesignedDoor.discount && this.customerDesignedDoor.discount.length > 0) {
            for (var i = 0; i < this.customerDesignedDoor.discount.length; i++) {
                let discount = this.customerDesignedDoor.discount[i];
                if (discount.amount) totalPrice = totalPrice - discount.amount;
                else if (discount.percent) totalPrice = totalPrice * ((100.0 - discount.percent) / 100);
            }
        }
        return totalPrice;
    }

    getAddOnsPrice(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) {
            designedDoor = this.customerDesignedDoor;
        }
        let p = 0.0;
        if (designedDoor.addOns.length > 0) {
            for (var i = 0; i < designedDoor.addOns.length; i++) {
                p += designedDoor.addOns[i].price;
            }
        }
        return p;
    }

    getAddOnsPriceDisplay(designedDoor: CustomerDesignedDoor): string {
        if (!designedDoor) {
            designedDoor = this.customerDesignedDoor;
        }
        return this.getDisplayPrice(this.getAddOnsPrice(designedDoor));
    }

    getDiscountAmount(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) {
            designedDoor = this.customerDesignedDoor;
        }
        let totalDiscount = 0;
        if (designedDoor.discount && designedDoor.discount.length > 0) {
            let totalDiscountPercent = 0;
            let totalDiscountAmount = 0;
            for (var i = 0; i < designedDoor.discount.length; i++) {
                let discount = designedDoor.discount[i];
                if (discount.amount) totalDiscountAmount += discount.amount;
                else if (discount.percent) totalDiscountPercent += discount.percent;
            }
            if (totalDiscountPercent > 0) {
                totalDiscount = parseFloat(designedDoor.totalPrice) * (totalDiscountPercent / 100.0);
            }
            if (totalDiscountAmount > 0) {
                totalDiscount = totalDiscount - totalDiscountAmount;
            }
        }
        return totalDiscount;
    }

    getDiscountDisplay(designedDoor: CustomerDesignedDoor): string {
        if (!designedDoor) {
            designedDoor = this.customerDesignedDoor;
        }
        let discount = this.getDiscountAmount(designedDoor);
        return this.getDisplayPrice(Math.abs(discount));
    }

    getSetupPriceDisplay(): string {
        return this.getPriceDisplayNumericOnly(this.getSetupPrice(this.customerDesignedDoor));
    }

    getSetupPrice(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) designedDoor = this.customerDesignedDoor;
        let setupPrice = 0;
        if (designedDoor.windLoadPrice) setupPrice += Number(designedDoor.windLoadPrice);
        if (!this.laborRate || designedDoor.deliveryOption !== this.INSTALL) return setupPrice;
        setupPrice += this.laborRate.rate;
        if (designedDoor.opener) setupPrice += this.laborRate.openerRate;
        return setupPrice;
    }

    getDoorDesignPriceDisplay(): string {
        return this.getPriceDisplayNumericOnly(this.getDoorDesignPrice(this.customerDesignedDoor));
    }

    getDoorDesignPrice(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) designedDoor = this.customerDesignedDoor;
        let totalPrice = +designedDoor.basePrice;
        totalPrice += designedDoor.getUnevenWidthPriceByHeightSlots();
        totalPrice += this.getPrice(designedDoor.insulation);
        totalPrice += designedDoor.getWindowPrice(designedDoor.window);
        //totalPrice += this.customerDesignedDoor.getColorPrice(this.customerDesignedDoor.childColor ? this.customerDesignedDoor.childColor : this.customerDesignedDoor.color);
        totalPrice += designedDoor.getColorPrice(designedDoor.color);
        return this.roundPrice(totalPrice);
    }

    getTrackOptionsPriceDisplay(): string {
        return this.getTrackOptionsPrice(this.customerDesignedDoor).toFixed(2);
    }

    getTrackOptionsPrice(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) designedDoor = this.customerDesignedDoor;
        let trackPrice = this.getPrice(designedDoor.trackAndSpring);
        trackPrice += this.getPrice(designedDoor.springWindingOption);
        trackPrice += this.getPrice(designedDoor.trackMountingOption);
        return this.roundPrice(trackPrice);
    }

    getAppliedPrice(door?: CustomerDesignedDoor): number {
        let tp = this.getTotalPriceForSpecials(door ? door : this.customerDesignedDoor);
        let discount = this.getPriceDiscount(door ? door : this.customerDesignedDoor);
        let appliedPrice = tp - discount;
        appliedPrice += this.getTrackOptionsPrice(door ? door : this.customerDesignedDoor);
        appliedPrice += this.getAllExtrasPrice(door ? door : this.customerDesignedDoor);

        return this.roundPrice(appliedPrice);
    }

    getPriceDiscount(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) designedDoor = this.customerDesignedDoor;
        let discountAmount = 0;
        if (this.appliedPromotion) {
            if (this.appliedPromotion.salePrice)
                discountAmount = this.getTotalPrice(designedDoor) - this.appliedPromotion.salePrice;
            else if (this.appliedPromotion.markdown)
                discountAmount = this.getTotalPriceForSpecials(designedDoor) * (this.appliedPromotion.markdown / 100);
        }
        return discountAmount;
    }

    roundPrice(p): number {
        return (Math.ceil(p * 100) / 100);
    }

    getPriceDisplayNumericOnly(p): string {
        return this.roundPrice(p).toFixed(2);
    }

    getPriceDisplay(p): string {
        if (!p) return 'Included';
        return 'Upgrade $' + this.roundPrice(p).toFixed(2);
    }

    getPriceDiscountDisplay(d?: CustomerDesignedDoor) {
        return this.getPriceDisplayNumericOnly(this.getPriceDiscount(d ? d : this.customerDesignedDoor));
    }

    getTotalPriceDisplay(): string {
        return this.getDisplayPrice(this.getTotalPrice(this.customerDesignedDoor));
    }

    getAppliedPriceDisplay(door?: CustomerDesignedDoor): string {
        return this.getDisplayPrice(this.getAppliedPrice(door));
    }

    getDisplayPrice(price) {
        return (Math.ceil(price * 100) / 100).toFixed(2);
    }

    getPrice(item): number {
        let price = 0;
        if (item && item.price) {
            price += parseFloat(item.price);
        }
        return price;
    }

    getGlassTypePrice(gt): number {
        if (!gt) return 0.00;
        if (!this.customerDesignedDoor.doorDesign) return 0.00;
        let numSlots = this.customerDesignedDoor.getNumWindowsFromWindowPositions();
        return (this.customerDesignedDoor.hasCustomWindows && gt.customPrice ? +gt.customPrice : +gt.price) * numSlots;
    }

    getInsulatedWindowUpgradePrice(w) {
        if (!w || !w.insulatedVersion) return 0.00;
        let insulatedWindow = w.insulatedVersion;
        let winPrice = this.getGlassTypePrice(w);
        let insulatedWinPrice = this.getGlassTypePrice(insulatedWindow);
        return insulatedWinPrice - winPrice;
    }

    getGlassTypeUpgradePrice(gt) {
        if (!gt) return 0.00;
        if (!this.customerDesignedDoor.doorDesign) return 0.00;
        if (!this.customerDesignedDoor.insulation) return 0.00;
        let numSlots = this.customerDesignedDoor.getNumWindowsFromWindowPositions();
        let possibleGlassTypes = this.customerDesignedDoor.doorDesign.getGlassTypesObject(this.customerDesignedDoor.insulation);
        let clearGlassType = possibleGlassTypes[this.customerDesignedDoor.CLEAR_MARKUP];
        let clearPrice = numSlots * (this.customerDesignedDoor.hasCustomWindows ? clearGlassType.customPrice : clearGlassType.price);
        let gtPrice = numSlots * (this.customerDesignedDoor.hasCustomWindows ? gt.customPrice : gt.price);
        return gtPrice - clearPrice;
    }

    getCollectionPrice(collection, designedDoor: CustomerDesignedDoor): number {
        let price = 0;
        if (collection && collection.length > 0) {
            for (var i = 0; i < collection.length; i++) {
                if (collection[i].widthBasedPricing && collection[i].widthBasedPricing.length > 0) {
                    let potentialWidthBasedPrice = collection[i].widthBasedPricing.filter(wbp => (wbp.minWidth == null || wbp.minWidth <= designedDoor.width.feet) && (wbp.maxWidth == null || wbp.maxWidth >= designedDoor.width.feet));
                    if (potentialWidthBasedPrice && potentialWidthBasedPrice.length === 1) {
                        price += potentialWidthBasedPrice[0].price;
                    }
                } else if (collection[i].price) {
                    if (collection[i].quantity) {
                        price += (collection[i].price * collection[i].quantity);
                    } else if (collection[i].setOf && this.customerDesignedDoor.width.feet >= 12) {
                        price += collection[i].price * 2;
                    } else {
                        price += collection[i].price;
                    }
                }
            }
        }
        return this.roundPrice(price);
    }

    getAllExtrasPrice(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) designedDoor = this.customerDesignedDoor;
        let extrasTotal = 0;
        extrasTotal += this.getCollectionPrice(designedDoor.extras, designedDoor);
        extrasTotal += this.getOpenerPrice(designedDoor);
        extrasTotal += this.getCollectionPrice(designedDoor.hardware, designedDoor);
        extrasTotal += this.getCollectionPrice(designedDoor.locks, designedDoor);
        //if (this.customerDesignedDoor.needsOperatorInstall) {
        //    extrasTotal += 35.00;
        //}
        return extrasTotal;
    }

    getOpenerPrice(designedDoor: CustomerDesignedDoor): number {
        if (!designedDoor) designedDoor = this.customerDesignedDoor;
        let openerPrice = 0;
        if (designedDoor.opener && designedDoor.opener.price) {
            openerPrice += designedDoor.opener.price;
        }
        return this.roundPrice(openerPrice);
    }

    getAllExtrasPriceDisplay(): string {
        return this.getPriceDisplayNumericOnly(this.getAllExtrasPrice(this.customerDesignedDoor));
    }

    onNavMenuNextState() {
        this.invokeNavMenuEvent.emit();
    }

    onNaveMenuPreviousState() {
        this.invokeNavMenuPreviousStateEvent.emit();
    }

    onNavMenuChangeState() {
        this.invokeNavMenuChangeStateEvent.emit();
    }

    updateFooter(state: DBNavState) {
        this.invokeUpdateFooter.emit(state);
    }

    setupFooter(states: DBNavState[], activeState: DBNavState) {
        this.invokeSetupFooter.emit({ states: states, activeState: activeState });
    }

    initializeNavStates() {
        //this.setupState = new DBNavState({
        //    index: 1, name: "Setup", pageURL: "/door-builder/setup", isActive: true, isStateComplete: function (cdd: CustomerDesignedDoor) {
        //        if (cdd.deliveryOption && cdd.height && cdd.height.feet && cdd.width && cdd.width.feet && cdd.isWindSpeedAnswered) return true;
        //        return false;
        //    },
        //    footerMessage: 'Choose your delivery option...'
        //});
        this.setupDoorSizeState = new DBNavState({
            index: 1, name: 'Setup', pageURL: '/door-builder/setup/select-size', isActive: false, isStateComplete: function (cdd: CustomerDesignedDoor) {
                if (cdd.deliveryOption && cdd.height && cdd.height.feet && cdd.width && cdd.width.feet && cdd.isWindSpeedAnswered) return true;
                return false;
            }, footerMessage: 'Enter current garage door dimensions' });
        this.setupWindRatingState = new DBNavState({
            index: 2, name: 'Setup', pageURL: '/door-builder/setup/select-wind-rating', isActive: false, isStateComplete: function (cdd: CustomerDesignedDoor) {
                if (cdd.deliveryOption && cdd.height && cdd.height.feet && cdd.width && cdd.width.feet && cdd.isWindSpeedAnswered) return true;
                return false;
            }, footerMessage: 'Enter current garage door dimensions' });
        this.designState = new DBNavState({
            index: 2, name: "Design", pageURL: "/door-builder/design", isActive: false, isStateComplete: function (cdd: CustomerDesignedDoor) {
                if (cdd.productGroup && cdd.doorDesign && cdd.insulation && cdd.window && cdd.color && cdd.hardware) return true;
                return false;
            }, footerMessage: 'Customize your garage door components...' });
        var headroomState = new DBNavState({ index: 3, name: "Track Options", pageURL: "/door-builder/headroom", isActive: false, footerMessage: 'Select your headroom requirements and track options...' });
        this.extrasState = new DBNavState({ index: 4, name: "Extras", pageURL: "/door-builder/design", isActive: false, footerMessage: "Add additional items you may need and Click Continue." });
        //this.hardwareState = new DBNavState({ index: 4, name: "Hardware", pageURL: "/door-builder/design", isActive: false, footerMessage: "Don't forget additional items you may need." });
        //var summaryState = new DBNavState({ index: 5, name: "Summary", pageURL: "/door-builder/summary", isActive: false });
        headroomState.next = this.extrasState;
        //this.extrasState.next = summaryState;
        //headroomState.next = this.hardwareState;
        //this.hardwareState.next = summaryState;
        this.designState.next = headroomState;
        this.designState.subStates = [];
        //this.setupState.next = this.designState;
        //this.setupState.subStates = [];

        this.setupDoorSizeState.next = this.designState;
        this.setupDoorSizeState.subStates = [];
        this.setupWindRatingState.parentState = this.setupDoorSizeState;
        this.setupDoorSizeState.subStates.push(this.setupWindRatingState);
        //this.setupDoorSizeState.next = this.setupWindRatingState;
        //this.setupDoorSizeState.parentState = this.setupState;
        //this.setupWindRatingState.parentState = this.setupState;
        //this.setupState.subStates.push(this.setupDoorSizeState);
        //this.setupState.subStates.push(this.setupWindRatingState);
        //this.navStates = [this.setupDoorSizeState, this.designState, headroomState, this.extrasState, summaryState];
        this.navStates = [this.setupDoorSizeState, this.designState, headroomState, this.extrasState];
        //this.navStates = [setupState, this.designState, headroomState, this.hardwareState, summaryState];
        this.maxActive = this.navStates[0].index;
        this.dbFooterService.message = this.navStates[0].footerMessage;
        this.changeState(this.navStates[0]);
    }

    public startState(): string {
        return this.navStates[0].pageURL;
    }

    public nextState(): string {
        this.applySpecials();
        if (this.activeState.index < this.navStates.length) {
            // does the state have substates?
            if (this.activeState.hasSubstates()) {
                return this.nextSubstate();
            } else {
                return this.nextParentState();
            }
        }
    }

    public previousState(): string {
        this.applySpecials();
        if (this.previousParentState) {
            this.activeState.deactivate();
            this.activeState = this.previousParentState;
            this.activeState.activate();
            this.activeSubstate = null;
            if (this.activeState.index > this.maxActive) {
                this.maxActive = this.activeState.index;
            }
            this.dbFooterService.message = this.activeState.footerMessage;
            return this.activeState.pageURL;
        } else {
            return this.nextState();
        }
    }
        

    public isDesignState(): boolean {
        return this.activeState === this.designState;
    }

    public isExtrasState(): boolean {
        return this.activeState === this.extrasState;
    }
    //public isHardwareState(): boolean {
    //    return this.activeState === this.hardwareState;
    //}

    nextSubstate(): string {
        this.activeSubstate = this.activeState.nextSubstate(this.activeState.activeSubState);
        this.activeState.activeSubState = this.activeSubstate;
        if (this.activeSubstate != null) {
            this.dbFooterService.message = this.activeSubstate.footerMessage;
            return this.activeSubstate.pageURL;
        } else {
            return this.nextParentState();
        }
    }

    nextParentState(): string {
        this.activeState.deactivate();
        this.activeState = this.activeState.nextState();
        this.activeState.activate();
        this.activeSubstate = null;
        if (this.activeState.index > this.maxActive) {
            this.maxActive = this.activeState.index;
        }
        this.dbFooterService.message = this.activeState.footerMessage;
        return this.activeState.pageURL;
    }

    getCurrentPage() {
        if (this.activeSubstate != null)
            return this.activeSubstate.pageURL;
        return this.activeState.pageURL;
    }

    changeState(state: DBNavState): string {
        this.applySpecials();
        let init = false;
        if (this.maxActive >= state.index || (state.index === this.activeState.index + 1 && this.activeState.isStateComplete(this.customerDesignedDoor))) {
            if (this.activeState) {
                if (this.activeState.hasSubstates()) {
                    this.activeSubstate = null;
                    this.activeState.activeSubState = null;
                }
                this.activeState.deactivate();
                if (this.activeState.index < state.index)
                    this.maxActive = state.index;
            } else {
                init = true;
            }
            this.activeState = state;
            this.activeState.activate();
            this.activeSubstate = null;
            this.activeState.activeSubState = null;
            if (!init)
                this.dbFooterService.message = this.activeState.footerMessage;
            return this.activeState.pageURL;
        }
        return null;
    }

    startOver() {
        this.customerDesignedDoor = new CustomerDesignedDoor();
        this.selectedProductGroup = null;
        this.selectedStyle = null;
        //this.price = 0;
        this.dbFooterService.message = "";
        this.possibleExtras = {};
        this.extras = null;
        this.opener = null;
        this.hasViewedOpeners = false;
        this.hasViewedHardware = false;
        this.hasViewedLocks = false;
        this.hasViewedExtras = false;
        this.hasWindowPricesLoaded = false;
        this.hasInsulationPricesLoaded = false;
        this.appliedPromotion = null;
        //this.isHidePanelDesign = false;
        //this.isHideInsulation = false;
        //this.isHideWindows = false;
        this.zipcode = null;
        this.operatorPrepOption = null;
        this.laborRate = null;
        this.maxActive = this.navStates[0].index;
        if (this.activeState) {
            if (this.activeState.hasSubstates()) {
                this.activeSubstate = null;
                this.activeState.activeSubState = null;
            }
            this.activeState.deactivate();
        }
        this.editItemId = null;
        this.changeState(this.navStates[0]);
        this.onNavMenuChangeState();
    }

    updateCartItem(door: CustomerDesignedDoor) {
        this.maxActive = this.navStates[0].index;
        if (this.activeState) {
            if (this.activeState.hasSubstates()) {
                this.activeSubstate = null;
                this.activeState.activeSubState = null;
            }
            this.activeState.deactivate();
        }
        this.editItemId = door.getId();
        this.customerDesignedDoor = door;
        this.selectedProductGroup = door.productGroup;
        this.selectedStyle = door.productGroup.style;
        this.extras = door.extras;
        this.opener = door.opener;
        this.hasViewedOpeners = true;
        this.hasViewedHardware = true;
        this.hasViewedLocks = true;
        this.hasViewedExtras = true;
        this.hasWindowPricesLoaded = true;
        this.hasInsulationPricesLoaded = true;
        this.appliedPromotion = door.appliedPromotion;
        if (!this.customerDesignedDoor.needsOperatorInstall) {
            this.operatorPrepOption = this.NO_OPERATOR_LABEL;
        } else if (door.opener) {
            this.operatorPrepOption = this.BUYING_OPERATOR_LABEL;
        } else {
            this.operatorPrepOption = this.HAVE_OPERATOR_LABEL;
        }
        this.changeState(this.navStates[0]);
        this.onNavMenuChangeState();
    }

    startOverWindLoad() {
        let width = this.customerDesignedDoor.width;
        let height = this.customerDesignedDoor.height;
        let windSpeed = this.customerDesignedDoor.windSpeed;
        let windLoadPrice = this.customerDesignedDoor.windLoadPrice;
        let deliveryOption = this.customerDesignedDoor.deliveryOption;
        let windSpeedAnswered = this.customerDesignedDoor.isWindSpeedAnswered;
        this.customerDesignedDoor = new CustomerDesignedDoor();
        if (this.editItemId) this.customerDesignedDoor.setId(this.editItemId);
        this.customerDesignedDoor.width = width;
        this.customerDesignedDoor.height = height;
        this.customerDesignedDoor.windSpeed = windSpeed;
        this.customerDesignedDoor.windLoadPrice = windLoadPrice;
        this.customerDesignedDoor.deliveryOption = deliveryOption;
        this.customerDesignedDoor.isWindSpeedAnswered = windSpeedAnswered;
        this.selectedProductGroup = null;
        this.selectedStyle = null;
        //this.price = 0;
        this.dbFooterService.message = "";
        this.possibleExtras = {};
        this.extras = null;
        this.opener = null;
        this.hasViewedOpeners = false;
        this.hasViewedHardware = false;
        this.hasViewedLocks = false;
        this.hasViewedExtras = false;
        this.hasWindowPricesLoaded = false;
        this.hasInsulationPricesLoaded = false;
        this.appliedPromotion = null;
        //this.isHidePanelDesign = false;
        //this.isHideInsulation = false;
        //this.isHideWindows = false;
        //this.zipcode = null;
        this.operatorPrepOption = null;
        this.previousParentState = null;
        //this.laborRate = null;
        //this.maxActive = this.navStates[0].index;
        //if (this.activeState) {
        //    if (this.activeState.hasSubstates()) {
        //        this.activeSubstate = null;
        //        this.activeState.activeSubState = null;
        //    }
        //    this.activeState.deactivate();
        //}
        //this.changeState(this.navStates[1]);
        //this.onNavMenuChangeState();
    }

    removeSelections() {
        this.customerDesignedDoor.childColor = null;
        this.customerDesignedDoor.color = null;
        this.customerDesignedDoor.doorDesign = null;
        this.customerDesignedDoor.window = null;
        this.customerDesignedDoor.insulation = null;
        this.customerDesignedDoor.glassType = null;
        this.selectedProductGroup = null;
        //this.isHideInsulation = false;
        //this.isHidePanelDesign = false;
        if (!this.customerDesignedDoor.zone) this.customerDesignedDoor.zone = this.defaultZone;
        this.applySpecials();
    }

    getWindowPrices(callback?) {
        let query = this.getPricesQuery();
        query.windows = this.customerDesignedDoor.doorDesign.windows;
        this.hasWindowPricesLoaded = false;
        this.productService.getWindowPrices(query).subscribe(result => {
            if (result) {
                if (result['windows']) {
                    this.customerDesignedDoor.doorDesign.windows = result['windows'];
                    this.hasWindowPricesLoaded = true;
                    if (callback) callback();
                }
            }
        });
    }

    getStyles() {
        let query = this.getPricesQuery();
        return this.productService.getStylesBySelectors(query);
    }
    async getStylesSync() {
        let query = this.getPricesQuery();
        return this.productService.getStylesBySelectorsSync(query);
    }

    getProductGroups() {
        let query = this.getPricesQuery();
        query.style = this.selectedStyle;
        query.widthFeet = this.customerDesignedDoor.width.feet;
        query.widthInches = this.customerDesignedDoor.width.inches;

        return this.productService.getProductGroups(query);
    }
    async getProductGroupsSync() {
        let query = this.getPricesQuery();
        query.style = this.selectedStyle;

        return this.productService.getProductGroupsSync(query);
    }

    async getDoorDesignsForProductGroupSync() {
        let query = this.getPricesQuery();
        return this.productService.getDoorDesignsForProductGroupSync(query);
    }

    async validateSelections() {
        if (this.selectedStyle) {
            let styles = await this.getStylesSync();
            if (!styles || (!styles.find(s => s._id === this.selectedStyle._id) && !styles.find(s => s._id === this.selectedStyle.id))) {
                this.customerDesignedDoor.productGroup = null;
                this.customerDesignedDoor.price = 0;
                this.selectedStyle = null;
                this.removeSelections();
            }
        }
        if (this.customerDesignedDoor.productGroup) {
            let productGroups = await this.getProductGroupsSync();
            if (!productGroups || !productGroups.find(pg => pg.productGroup._id === this.customerDesignedDoor.productGroup._id)) {
                this.customerDesignedDoor.productGroup = null;
                this.customerDesignedDoor.price = 0;
                this.removeSelections();
            } else {
                this.setProductGroupSelection(productGroups);
            }
        }
        if (this.customerDesignedDoor.doorDesign) {
            let doorDesigns = await this.getDoorDesignsForProductGroupSync();
            this.setDoorDesignSelections(doorDesigns);
        }
    }


    setDoorDesignSelections(doorDesigns) {
        if (!doorDesigns) return;
        if (this.customerDesignedDoor.doorDesign) {
            //this.isHideInsulation = false;
            //this.isHidePanelDesign = false;
            //this.isHideWindows = false;
            let design = doorDesigns.find(dd => dd.name === this.customerDesignedDoor.doorDesign.name);
            if (design) {
                this.customerDesignedDoor.doorDesign = new DoorDesign(design);
                // insulations
                if (this.customerDesignedDoor.insulation) {
                    let ios = this.customerDesignedDoor.doorDesign.getInsulationOptions(this.customerDesignedDoor.width, this.customerDesignedDoor.width ? this.customerDesignedDoor.width.inches : null);
                    let option = ios.find(io => io.name === this.customerDesignedDoor.insulation.name);
                    if (option) {
                        this.customerDesignedDoor.insulation = option;
                    } else {
                        this.customerDesignedDoor.insulation = null;
                        this.customerDesignedDoor.window = null;
                        this.customerDesignedDoor.color = null;
                        this.customerDesignedDoor.childColor = null;
                        this.customerDesignedDoor.glassType = null;
                        return;
                   }
                }
                // windows
                if (this.customerDesignedDoor.window) {
                    let win = this.customerDesignedDoor.doorDesign.windows.find(w => w.name === this.customerDesignedDoor.window.name);
                    if (win) {
                        this.customerDesignedDoor.window = win;
                        if (this.customerDesignedDoor.glassType) {
                            let glassTypes = this.customerDesignedDoor.doorDesign.getGlassTypes(this.customerDesignedDoor.insulation);
                            let glassType = glassTypes.find(gt => gt.name === this.customerDesignedDoor.glassType.name);
                            if (glassType) {
                                this.customerDesignedDoor.glassType = glassType;
                            } else {
                                this.customerDesignedDoor.glassType = null;
                            }
                        }
                    } else {
                        this.customerDesignedDoor.window = null;
                        this.customerDesignedDoor.glassType = null;
                    }
                    // glass type
                }
                // colors
                if (this.customerDesignedDoor.color) {
                    let colors = this.customerDesignedDoor.doorDesign.getColors();
                    let color = colors.find(c => c.name === this.customerDesignedDoor.color.name);
                    if (color) {
                        this.customerDesignedDoor.color = color;
                        // childColor
                        if (this.customerDesignedDoor.childColor) {
                            let childColors = color.childColors;
                            if (childColors) {
                                let childColor = childColors.find(cc => cc.name === this.customerDesignedDoor.childColor.name);
                            } else {
                                this.customerDesignedDoor.childColor = null;
                            }
                        } else {
                            this.customerDesignedDoor.childColor = null;
                        }
                    } else {
                        this.customerDesignedDoor.color = null;
                        this.customerDesignedDoor.childColor = null;
                    }
                }
            } else {
                this.customerDesignedDoor.doorDesign = null;
                this.customerDesignedDoor.insulation = null;
                this.customerDesignedDoor.window = null;
                this.customerDesignedDoor.color = null;
                this.customerDesignedDoor.childColor = null;
                this.customerDesignedDoor.glassType = null;
            }
        }
    }
    setProductGroupSelection(productGroups) {
        let isFound: boolean = false;
        if (this.customerDesignedDoor.productGroup) {
            for (var i = 0; i < productGroups.length; i++) {
                let pg = productGroups[i]['productGroup'];
                if (pg.name === this.customerDesignedDoor.productGroup.name) {
                    this.customerDesignedDoor.productGroup = pg;
                    this.customerDesignedDoor.price = productGroups[i]['basePrice'];
                    this.customerDesignedDoor.basePrice = this.customerDesignedDoor.price;
                    isFound = true;
                    break;
                }
            }
            //if (!this.selectedStyle) {
            //    let styles = await this.productService.getStylesBySelectorsSync(this.getPricesQuery());
            //    let styleToSet = styles.find(s => s._id === this.customerDesignedDoor.productGroup.style);
            //    this.selectedStyle = styleToSet;
            //}
            if (!isFound) {
                this.removeSelections();
                this.customerDesignedDoor.productGroup = null;
            }
        }
    }

    async setProductGroupSelectionSync(productGroup) {
        if (!this.customerDesignedDoor) this.customerDesignedDoor = new CustomerDesignedDoor();
        this.customerDesignedDoor.productGroup = productGroup;
    }

//    getDoorImageSrc() {
//        if (!this.customerDesignedDoor) return '';
//        if (!this.customerDesignedDoor.doorDesign || !this.customerDesignedDoor.doorDesign.prepopulatedImageUrl || !this.customerDesignedDoor.doorDesign.code) return '';
//        let doorDesign = this.customerDesignedDoor.doorDesign;
//        if (!doorDesign) return '';
//        let color = this.customerDesignedDoor.childColor ? this.customerDesignedDoor.childColor : this.customerDesignedDoor.color;
//        if (!color || color.name === this.customerDesignedDoor.COLOR_ZONE_NAME || color.isColorZone) color = doorDesign.getColors()[0];
//        let window = this.customerDesignedDoor.window;
//        if (!window) window = doorDesign.windows[0];
//        let insulation = this.customerDesignedDoor.insulation;
//        let insulationLevel = { name: '1000' };
//        if (insulation) {
//            insulationLevel = insulation.insulationLevel;
//            if (!insulationLevel) insulationLevel = { name: '1000' };
//        }
//        if (!color || !doorDesign || !window) return '';
//        // TODO - hardware and codes
//        let imageSource = doorDesign.prepopulatedImageUrl;
//        if (imageSource.indexOf('%')) {
//            let w = this.customerDesignedDoor.width.feet;
//            if (this.customerDesignedDoor.width.inches) w++;
//            if (w < this.customerDesignedDoor.minWidth) w = this.customerDesignedDoor.minWidth;
//            let h = this.customerDesignedDoor.height.feet;
//            if (this.customerDesignedDoor.height.inches) h++;
//            if (h < this.customerDesignedDoor.minHeight) h = this.customerDesignedDoor.minHeight;
//            let wDim = w * 12;
//            let hDim = h * 12;
//            let dimensions = wDim + 'x' + hDim;

//            imageSource = imageSource.replace('%DIMENSIONS%', dimensions);
//            imageSource = imageSource.replace('%WIN_NAME%', window.nameInURL ? window.nameInURL : window.name);
//            imageSource = imageSource.replace('%ABBREVIATION%', this.customerDesignedDoor.productGroup.abbreviation);
//            imageSource = imageSource.replace('%INSULATION_LEVEL%', insulationLevel.name);
//            imageSource = imageSource.replace('%COLOR_CODE%', color.code);
//            imageSource = imageSource.replace('%COLOR_NAME%', color.name.replace(' ', '_'));
//            imageSource = imageSource.replace('%TRANSPARENCY%', window.transparency);
//            imageSource = imageSource.replace('%IMAGE_NAME%', window.imageName);

//            let isDoubleWidth = false;
//            if (w >= 16) isDoubleWidth = true;
//            imageSource = imageSource.replace('%SINGLE_DOUBLE%', isDoubleWidth ? 'double' : 'single');
//            imageSource = imageSource.replace('%DOUBLE%', isDoubleWidth ? 'double/' : '');
//            let slots = '';
//            let widthSlots = 4;
//            let heightSlots = 4;
//            let heightOnlySlots = 4;
////            if (doorDesign.name.startsWith('Long')) {
//            if (doorDesign.isLongPanel) {
//                if (w >= 20) {
//                    widthSlots = 5;
//                } else if (w >= 15) {
//                    widthSlots = 4;
//                } else if (w >= 12) {
//                    widthSlots = 3;
//                } else {
//                    widthSlots = 2;
//                }
//            } else {
//                if (w >= 20) {
//                    widthSlots = 10;
//                } else if (w >= 16) {
//                    widthSlots = 8;
//                } else if (w >= 12) {
//                    widthSlots = 6;
//                } else if (w == 10) {
//                    widthSlots = 5;
//                } else {
//                    widthSlots = 4;
//                }
//            }
//            if (h >= 14) {
//                heightSlots = 8;
//            } else if (h >= 12) {
//                heightSlots = 7;
//            } else if (h > 8) {
//                heightSlots = 6;
//            } else if (h > 7) {
//                heightSlots = 5;
//            } else {
//                heightSlots = 4;
//            }

//            for (let i = 0; i < heightSlots; i++) {
//                for (let j = 0; j < widthSlots; j++) {
//                    if (window && window.name != doorDesign.windows[0].name && i === 0) {
//                        slots += 'G';
//                    } else {
//                        slots += 'X';
//                    }
//                }
//                if (i < heightSlots - 1) {
//                    slots += '-';
//                }
//            }
//            imageSource = imageSource.replace('%SLOTS%', slots);

//            if (h > 12) {
//                heightOnlySlots = 7;
//            } else if (h > 10) {
//                heightOnlySlots = 6;
//            } else if (h > 8) {
//                heightOnlySlots = 5;
//            } else {
//                heightOnlySlots = 4;
//            }
//            let hSlots = '';
//            for (let i = 0; i < heightOnlySlots; i++) {
//                hSlots += 'G';
//            }
//            imageSource = imageSource.replace('%SLOTS_HEIGHT_ONLY%', hSlots);
//        } else {
//            imageSource += color.code + '/' + doorDesign.code + '_' + window.code + '_' + color.code + '_S.jpg'
//        }
//        return imageSource;
//    }

    //getDoorWidthSlots(doorDesign: DoorDesign): number {
    //    let w = this.customerDesignedDoor.width.feet;
    //    if (this.customerDesignedDoor.width.inches) w++;

    //    let widthSlots = 4;
    //    //if (doorDesign.name.startsWith('Long')) {
    //    if (doorDesign.isLongPanel) {
    //        if (w >= 20) {
    //            widthSlots = 5;
    //        } else if (w >= 15) {
    //            widthSlots = 4;
    //        } else if (w >= 12) {
    //            widthSlots = 3;
    //        } else {
    //            widthSlots = 2;
    //        }
    //    } else {
    //        if (w >= 20) {
    //            widthSlots = 10;
    //        } else if (w >= 16) {
    //            widthSlots = 8;
    //        } else if (w >= 12) {
    //            widthSlots = 6;
    //        } else if (w == 10) {
    //            widthSlots = 5;
    //        } else {
    //            widthSlots = 4;
    //        }
    //    }

    //    return widthSlots;
    //}

    showClassicaWarning(): boolean {
        if (!this.customerDesignedDoor || !this.customerDesignedDoor.height) return false;
        if (!this.customerDesignedDoor.productGroup) return false;
        let heightInches = (this.customerDesignedDoor.height.feet * 12);
        if (this.customerDesignedDoor.height.inches) {
            heightInches += this.customerDesignedDoor.height.inches;
        }
        let nearestHeight = this.customerDesignedDoor.getNearestClassicaImageHeight();
        if (this.customerDesignedDoor.productGroup.name === 'Classica' && (nearestHeight !== heightInches)) return true;
        return false;
    }

    getAddOnsDescription(designedDoor: CustomerDesignedDoor): string {
        if (!designedDoor) {
            designedDoor = this.customerDesignedDoor;
        }
        let addOnDescription = '';
        if (designedDoor.addOns.length > 0) {
            for (var i = 0; i < designedDoor.addOns.length; i++) {
                if (i > 0) addOnDescription += ', ';
                addOnDescription += designedDoor.addOns[i].name;
            }
        }
        return addOnDescription;
    }

    async reloadDoors(doors: CustomerDesignedDoor[]) {
        let reloadedDoors = new Array<CustomerDesignedDoor>();
        for (var i = 0; i < doors.length; i++) {
            let door = await this.productService.reloadDoor(doors[i]).toPromise();
            reloadedDoors.push(door);
        }
        return reloadedDoors;
    }

    async loadDoorsById(doorIds: string) {
        let loadedDoors = await this.productService.loadDoorsById(doorIds).toPromise();
        return loadedDoors['doors'];
    }

    getSummaryItems(designedDoor: CustomerDesignedDoor, includePromotion: boolean) {
        let summaryItems = [];
        if (!designedDoor) {
            designedDoor = this.customerDesignedDoor;
        }
        this.applySpecials(designedDoor);
        summaryItems.push({ label: 'Width', value: this.getDimensionDisplayValue(designedDoor.width), price: '' });
        summaryItems.push({ label: 'Height', value: this.getDimensionDisplayValue(designedDoor.height), price: '' });
        summaryItems.push({ label: 'Collection', value: designedDoor.productGroup.name, price: '$' + this.getDisplayPrice(designedDoor.basePrice) });
        summaryItems.push({ label: 'Door Design', value: designedDoor.doorDesign.name, price: '$0.00' });
        summaryItems.push({ label: 'Construction Type', value: designedDoor.insulation.name, price: '$' + this.getDisplayPrice(this.getPrice(designedDoor.insulation)) });
        summaryItems.push({ label: 'Window Style', value: this.getWindowDescription(designedDoor), price: '$' + this.getDisplayPrice(designedDoor.getWindowPrice()) });
        summaryItems.push({ label: 'Color', value: designedDoor.color.name, price: '$' + this.getDisplayPrice(designedDoor.getColorPrice(designedDoor.color)) });
        if (designedDoor.childColor) {
            summaryItems.push({ label: 'Two Tone Color', value: designedDoor.childColor.name, price: '$' + this.getDisplayPrice(designedDoor.getColorPrice(designedDoor.childColor)) });
        }
        summaryItems = summaryItems.concat(this.addCollectionItems('Decorative Hardware', designedDoor.hardware));
        summaryItems = summaryItems.concat(this.addCollectionItems('Extras', designedDoor.extras));
        if (designedDoor.opener && designedDoor.opener.name) {
            summaryItems.push({ label: 'Openers/Operators', value: designedDoor.opener.name, price: designedDoor.opener ? '$' + this.getDisplayPrice(designedDoor.opener.price) : '$0.00' });
        }
        summaryItems = summaryItems.concat(this.addCollectionItems('Locks', designedDoor.locks));
        // add blank row
        summaryItems.push({ label: '', value: '', price: '' });
        summaryItems.push({ label: 'Wind Load', value: this.getWindLoadLabel(designedDoor), price: designedDoor.windLoadPrice ? '$' + this.getDisplayPrice(designedDoor.windLoadPrice) : '$0.00' });
        summaryItems.push({ label: 'Track Mounting', value: designedDoor.trackMountingOption ? designedDoor.trackMountingOption['name'] : '', price: designedDoor.trackMountingOption.price ? '$' + this.getDisplayPrice(designedDoor.trackMountingOption.price) : '$0.00' });
        summaryItems.push({ label: 'Headroom', value: designedDoor.headroom ? designedDoor.headroom['name'] ? designedDoor.headroom['name'] : designedDoor.headroom : '', price: '' });
        summaryItems.push({ label: 'Track and Spring', value: designedDoor.trackAndSpring.name, price: '$' + this.getDisplayPrice(designedDoor.trackAndSpring.price) });
        if (designedDoor.springWindingOption && designedDoor.springWindingOption.name) {
            summaryItems.push({ label: 'Spring Winding Device', value: designedDoor.springWindingOption.name, price: designedDoor.springWindingOption ? '$' + this.getDisplayPrice(designedDoor.springWindingOption.price) : '' });
        }
        //summaryItems.push({ label: 'Delivery Options', value: designedDoor.deliveryOption });
        if (designedDoor.addOns && designedDoor.addOns.length > 0) {
            summaryItems.push({
                label: 'Add Ons', value: this.getAddOnsDescription(designedDoor), price: this.getAddOnsPriceDisplay(designedDoor)
            });
        }
        if (designedDoor.discount && designedDoor.discount.length > 0) {
            summaryItems.push({
                label: 'Applied Discount', value: '', price: '($' + this.getDiscountDisplay(designedDoor) + ')'
            });
        }
        if (designedDoor.appliedPromotion && includePromotion) {
            let promotionAmount = 0.0;
            if (designedDoor.appliedPromotion.markdown) {
                if (designedDoor.getPrice()) {
                    let tp = this.getTotalPriceBeforeDiscountForSpecials(designedDoor);
                    //let tp = designedDoor.getPrice();
                    let mk = designedDoor.appliedPromotion.markdown / 100.0;
                    promotionAmount = tp * mk;
                } else {
                    promotionAmount = this.getPriceDiscount(designedDoor);
                }
            } else if (designedDoor.appliedPromotion.salePrice) {
                promotionAmount = designedDoor.appliedPromotion.salePrice;
            }
            summaryItems.push({
                label: 'Promotion Discount', value: '', price: '($' + this.getPriceDisplayNumericOnly(promotionAmount) + ')'
            });
        }
        return summaryItems;
    }

    addCollectionItems(itemLabel, itemCollection): any[] {
        let collectionItems = [];
        if (itemCollection && itemCollection.length > 0) {
            for (var i = 0; i < itemCollection.length; i++) {
                let p = itemCollection[i].quantity ? itemCollection[i].price * itemCollection[i].quantity : itemCollection[i].price;
                if (itemCollection[i].setOf) {
                    if (this.customerDesignedDoor.width.feet >= 12) {
                        p = p * 2;
                    }
                }
                let v = itemCollection[i].quantity ? '' + itemCollection[i].quantity + ' ' + itemCollection[i].name : itemCollection[i].name;
                if (i == 0) {
                    collectionItems.push({ label: itemLabel, value: v, price: '$' + this.getDisplayPrice(p) });
                } else {
                    collectionItems.push({ label: '', value: v, price: '$' + this.getDisplayPrice(p) });
                }
            }
        } else {
            collectionItems.push({ label: itemLabel, value: 'None', price: '$0.00' });
        }
        return collectionItems;
    }

    getWindowDescription(designedDoor: CustomerDesignedDoor) {
        if (!designedDoor) {
            designedDoor = this.customerDesignedDoor;
        }
        let desc = designedDoor.window.name;
        if (designedDoor.glassType) {
            desc += ' ';
            desc += designedDoor.glassType.name;
        } else if (designedDoor.window.insulatedSelected) {
            desc += ' Insulated';
        }
        return desc;
    }

    getDimensionDisplayValue(dimension: Dimension): string {
        let dimDisplay = '';
        if (dimension) {
            dimDisplay += dimension.feet + '\'';
            if (dimension.inches > 0) {
                dimDisplay += ' ' + dimension.inches + '"';
            }
        }
        return dimDisplay;
    }

    getWindLoadLabel(designedDoor: CustomerDesignedDoor) {
        if (!designedDoor) {
            designedDoor = this.customerDesignedDoor;
        }
        let label = '';
        if (designedDoor.windSpeed) {
            label += 'Yes / ' + designedDoor.windSpeed;
            if (designedDoor.impactType && designedDoor.impactType != this.NON_IMPACT) {
                label += ' / ' + designedDoor.impactType;
            }
        } else {
            label = 'No';
        }
        return label;
    }

    isHidePanelDesigns(): boolean {
        if (this.selectedProductGroup && this.selectedProductGroup.doorDesign) return true;
        return false;
    }

    isHideWindows(): boolean {
        if (this.customerDesignedDoor.doorDesign && this.customerDesignedDoor.doorDesign.windows.length === 1) return true;
        return false;
    }

    isHideInsulation(): boolean {
        if (this.selectedProductGroup && this.selectedProductGroup.insulationOption) return true;
        return false;
    }

    async checkExistingWindSpeedPrice() {
        if (this.customerDesignedDoor.productGroup) {
            let query = this.getPricesQuery();
            let result = await this.productService.getWindLoadPrice(query).toPromise();
            if (result && result['windLoadPrice']) {
                return result['windLoadPrice'];
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    async getInsulationLevelsForWindLoad() {
        if (this.customerDesignedDoor.productGroup) {
            let query = this.getPricesQuery();
            let result = await this.productService.getInsulationLevelsForWindLoad(query).toPromise();
            if (result && result['insulationIds']) {
                return result['insulationIds'];
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    public async setTaxRate(taxRate) {
        this.taxRate = taxRate;
    }

    public getTotalTaxes(cartItems: CustomerDesignedDoor[]) {
        if (!cartItems || cartItems.length == 0) return 0;
        let totalApplicableTaxes = 0.00;
        for (var i = 0; i < cartItems.length; i++) {
            let currentItem = cartItems[i];
            let itemTaxableAmount = (typeof currentItem.getPrice() === 'string' ? parseFloat(currentItem.getPrice()) : currentItem.getPrice());
            let itemTax = Math.round(itemTaxableAmount * this.taxRate * 100) / 100;
            cartItems[i].setTax(itemTax);
            totalApplicableTaxes += (itemTax * currentItem.getQuantity());
        }
        this.setApplicableTaxes(totalApplicableTaxes);
        return totalApplicableTaxes;
    }

    public async setApplicableTaxes(taxes) {
        this.totalTaxes = taxes;
        //if (zipCode && taxableAmount) {
        //    this.orderService.calculateTaxes(zipCode).subscribe(result => {
        //        if (result && result['taxAmount']) {
        //            var taxRate = result['taxAmount'];
        //            let taxAmount = Math.round(taxableAmount * taxRate * 100) / 100;
        //            this.totalTaxes = taxAmount;
        //        }
        //    });
        //} else {
        //    this.totalTaxes = 0;
        //}
    }

    public getShippingCost(quantity: number) {
        if (quantity > 2) return (350 + (175 * (quantity - 2))) + this.additionalShipping;
        if (quantity > 0) return 350.00 + this.additionalShipping;
        return 0.00;
    }
}