﻿import { Component } from '@angular/core';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { DoorOpener } from 'src/app/shared/models';

@Component({
    selector: 'app-operators-openers',
    templateUrl: './operators-openers.component.html',
    styleUrls: ['./operators-openers.component.css'],
})
export class DBExtrasOperatorsOpenersComponent extends DBStateComponent {
    private openers: DoorOpener[];

    async ngOnInit() {
        if (!this.dbService.customerDesignedDoor.doorDesign) {
            this.router.navigate(['/door-builder/design/design-home']);
        }

        this.dbService.hasViewedOpeners = true;
        if (this.dbService.possibleExtras && this.dbService.possibleExtras['openers']) {
            if (this.dbService.customerDesignedDoor.trackAndSpring && this.dbService.customerDesignedDoor.trackAndSpring.isLowHeadroom) {
                this.openers = this.dbService.possibleExtras['openers'].filter(ex => ex.isOperator === true && !ex.noLowHeadroom);
            } else {
                this.openers = this.dbService.possibleExtras['openers'].filter(o => o.isOperator === true);
            }
            this.dbService.possibleExtras['openers'].forEach(o => {
                if (o.canChangeQuantity) {
                    o.quantity = 0;
                }
            });
        } else {
            this.openers = [];
        }

        if (this.openers && this.openers.length > 0) {
            this.openers.forEach(e => {
                let existing = this.dbService.customerDesignedDoor.opener;
                if (existing && existing._id === e._id) e.quantity = existing.quantity;
                else e.quantity = 0;
            });
        }
        this.setCollectionWrapperStyling(this.openers, true);
        super.ngOnInit();
    }

    getCollectionOuterStyle(index) {
        let style = {};
        if (this.isMobile()) {
            style = {
                'grid-row': index,
                'padding': '2%',
                'background-color': '#ffffff',
                'margin-bottom': '2px'
            }
        } else {
            var rowNum = (index / 2) + 1;
            style = {
                'grid-row': '' + rowNum,
                'margin-right': '5px',
                'margin-bottom': '5px',
                'padding': '10px 5px',
                'background-color': '#ffffff'
             };
        }
        return style;
    }

    getCollectionInnerStyle(index) {
        let style = {};
        if (this.isMobile()) {
            style = {
                'display': 'grid',
                'text-align': 'center',
                'grid-template-columns': '40% 60%',
            }
        } else {
            style = {
                'display': 'grid',
                'text-align': 'center',
                'grid-template-columns': '40% 60%',
           };
        }
        //if (hasRows) {
        //    style['grid-auto-rows'] = 'minmax(min-content, max-content)';
        //};
        //return style;
        //let style = super.collectionStyle(index);
        style['grid-template-columns'] = '30% 70%';
        return style;
    }

    getCollectionClass(o) {
        return super.collectionClass(this.dbService.customerDesignedDoor.opener, o);
    }

    onClickOpener(o) {
        if (this.dbService.customerDesignedDoor.opener === o) {
            this.dbService.customerDesignedDoor.opener = null;
        } else {
            this.dbService.customerDesignedDoor.opener = o;
        }
    }

    isNotAvailableInCalifornia(o) {
        if (o.isOperator && !o.hasBatteryBackup) return true;
        return false;
    }

    getButtonClass() {
        if (this.dbService.customerDesignedDoor.opener) return 'Rectangle Step-Continue-Button-Wrapper Fixed-Bottom';
        return 'Changed-Mind-Button';
    }

    getButtonText() {
        if (this.dbService.customerDesignedDoor.opener)
            return 'Continue';
        return 'I changed my mind';
    }

    //isShowContinue() {
    //    if (this.dbService.customerDesignedDoor.opener) return true;
    //    return false;
    //}

    onClickContinue() {
        if (this.dbService.customerDesignedDoor.opener) {
            if (!this.dbService.hasViewedLocks) {
                this.router.navigate(['/door-builder/design/locks']);
            } else {
                this.router.navigate(['/door-builder/design/extras-home']);
            }
        } else {
            this.router.navigate(['/door-builder/design/operator-prep-options']);
        }
    }

    getPriceDisplay(opener) {
        if (opener)
            return '$' + this.dbService.getPriceDisplayNumericOnly(opener.price);
        return '$0.00';
    }

    incrementQuantity(e) {
        let selectedItem = this.dbService.customerDesignedDoor.opener;
        if (selectedItem && selectedItem._id === e._id) {
            event.stopPropagation();
            selectedItem.quantity++;
        }
    }

    decrementQuantity(e) {
        let selectedItem = this.dbService.customerDesignedDoor.opener;
        if (selectedItem && selectedItem._id === e._id) {
            if (selectedItem.quantity > 0) {
                selectedItem.quantity--;
            }
            if (selectedItem.quantity > 0) {
                event.stopPropagation();
            }
        }
    }

}