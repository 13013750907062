﻿import { Injectable, Inject } from '@angular/core';
import { CartItem, CartService, CART_ITEM_CLASS, CART_SERVICE_CONFIGURATION } from 'ng-shopping-cart';
import { OrderService } from '../shared/services';
import { DoorWindow } from '../shared/models';

/**
 * An implementation of the CartService using an in-memory array to store items
 * @order 2
 */
@Injectable({ providedIn: 'root' })
export class GDSHCartService<T extends CartItem> extends CartService<T> {
    protected _items: T[] = [];
    protected _taxRate = 0;
    protected _tax = 0;
    protected _shipping = 0;
    protected itemClass: any;
    protected storage: Storage;
    protected storageKey: string;
    protected clearOnError: boolean;

    constructor(@Inject(CART_ITEM_CLASS) itemClass: any, @Inject(CART_SERVICE_CONFIGURATION) configuration: any, private orderService: OrderService) {
        super(); // <-- Mandatory in all derived classes
        //this.storageKey = configuration && configuration.storageKey ? configuration.storageKey : 'NgShoppingCart-gdsh';
        this.storageKey = 'NgShoppingCart-gdsh';
        this.clearOnError = configuration && configuration.clearOnError !== undefined ? configuration.clearOnError : true;
        this.itemClass = itemClass;
        this.storage = window.localStorage;
        this.restore();
    }

    protected _addItem(item: T): void {
        const foundIdx = this._items.findIndex(i => i.getId() === item.getId());
        if (foundIdx === -1) {
            this._items.push(item);
        } else {
            this._items[foundIdx] = item;
        }
        this.onItemAdded.emit(item);
        this.onItemsChanged.emit(this._items.length);
        this.onChange.emit({ change: 'items', value: this.getItems() });
        this.setShipping(this.getShippingCost());
        this.save();
    }

    protected _removeItem(id: any): void {
        const idx = this._items.findIndex(i => i.getId() === id);
        if (idx !== -1) {
            const removed = this._items.splice(idx, 1);
            this.onItemRemoved.emit(removed[0]);
            this.onItemsChanged.emit(this._items.length);
            this.onChange.emit({ change: 'items', value: this.getItems() });
        }
        this.setShipping(this.getShippingCost());
        this.save();
    }

    getShippingCost(): number {
        if (this.quantityCount() > 0) {
            if (this.quantityCount() > 2) return 350.00 + (175.00 * (this.quantityCount() - 2))
            return 330.00;
        }
        return 0.00;
    }

    public getItem(id: any): T {
        return this._items.find(i => i.getId() === id);
    }

    public getItems(): T[] {
        return this._items.slice();
    }

    public itemCount(): number {
        return this._items.length;
    }

    public quantityCount(): number {
        let q = 0;
        for (var i = 0; i < this._items.length; i++) {
            q += this._items[i].getQuantity();
        }
        return q;
    }

    public entries(): number {
        return this._items.reduce((curr, i) => (curr + i.getQuantity()), 0);
    }

    public addItem(item: T): void {
        this._addItem(item);
    }

    public removeItem(id: any): void {
        this._removeItem(id);
    }

    public cost(): number {
        return this._items.reduce((curr, i) => (curr + i.getPrice() * i.getQuantity()), 0);
    }

    public clear() {
        this._items = [];
        this.onItemsChanged.emit(this._items.length);
        this.onChange.emit({ change: 'items', value: this.getItems() });
        this.save();
    }

    public getShipping(): number {
        if (this._shipping === 0) {
            this._shipping = this.getShippingCost();
        }
        return this._shipping;
    }

    public setShipping(shipping: number): void {
        this._shipping = shipping;
        this.onShippingChange.emit(this._shipping);
        this.onChange.emit({ change: 'shipping', value: this._shipping });
        this.save();
    }

    public getTaxRate(): number {
        return this._taxRate;
    }

    public setTaxRate(taxRate: number): void {
        this._taxRate = taxRate;
        this.onTaxChange.emit(this._taxRate);
        this.onChange.emit({ change: 'taxRate', value: this._taxRate });
        this.save();
    }

    //public async setTax() {
    //    let result = await this.orderService.getOrderTaxes(this.getItems(), this.getShipping(), null).toPromise();
    //    if (result && result['tax']) {
    //        this._tax = result['tax'];
    //    }
    //    return this._tax;
    //}

    //public getTax(): number {
    //    return this._tax;
    //}

    public isEmpty(): boolean {
        return this._items.length === 0;
    }

    protected save() {
        let thisObject = this.toObject();
        //for (var i = 0; i < thisObject.items.length; i++) {
        //    thisObject.items[i]['doorwindow'] = new DoorWindow(thisObject.items[i].window);
        //}
        this.storage.setItem(this.storageKey, JSON.stringify(thisObject));
    }

   private resetStorage(error: boolean | string | Error) {
        if (this.clearOnError || !error) {
            this.setTaxRate(0);
            this.setShipping(0);
            this.clear();
            this.save();
        } else {
            if (typeof error === 'string') {
                throw new Error(error);
            }
            throw error;
        }
    }

    protected restore() {
        if (!this.storage.getItem(this.storageKey)) {
            this.resetStorage(false);
            return;
        }
        try {
            const sc = JSON.parse(this.storage.getItem(this.storageKey));
            if (!(sc.hasOwnProperty('items') && Array.isArray(sc.items) && sc.hasOwnProperty('taxRate') && sc.hasOwnProperty('shipping'))) {
                this.resetStorage('The object found under the key ' + this.storageKey + ' is not a valid cart object');
                return;
            }
            this._items = sc.items.map(i => {
                if (this.itemClass.fromJSON) {
                    let currentItem = this.itemClass.fromJSON(i);
                    //currentItem['window'] = new DoorWindow(currentItem['doorwindow']);
                    return currentItem;
                }
                return new this.itemClass(i);
            });
            this.setTaxRate(parseFloat(sc.taxRate));
            this.setShipping(parseFloat(sc.shipping));
        } catch (e) {
            this.resetStorage(e);
        }
    }

}