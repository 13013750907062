﻿export class CustomerReview {
    _id: string;
    title: string;
    quote: string;
    name: string;
    location: string;

    constructor(cr?) {
        if (cr) {
            this.title = cr.title;
            this.quote = cr.quote;
            this.name = cr.name;
            this.location = cr.location;
        }
    }
}