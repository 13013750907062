﻿import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { DBStateComponent } from '../../shared/components/db-state.component';
import { Router } from '@angular/router';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';
import { ProductService } from 'src/app/shared/services';

@Component({
    selector: 'app-select-size',
    templateUrl: './select-size.component.html',
    styleUrls: ['./select-size.component.css'],
})
export class DBSetupSelectSizeComponent extends DBStateComponent {
    private isShowSizingInfoWindow: boolean = false;
    public widths: Object[] = [6, 8, 9, 10, 12, 14, 15, 16, 17, 18, 20];
    public heights: Object[] = [];
    public widthInches: number[] = [2, 4, 6, 8, 10];
    //public widthInches: number[] = [6, 8];
    public heightInches: number[] = [3, 6, 9];
    selectedWidthFeet: number;
    selectedWidthInches: number;
    isWidthInchesDisabled: boolean = false;
    selectedHeightFeet: number;
    selectedHeightInches: number;
    isHeightInchesDisabled: boolean = false;
    //hasIssuedChangeState: boolean = false;
    //isPGBack: boolean = false;
    //isStyleBack: boolean = false;

    constructor(private http: HttpClient,
        public dbService: BuildADoorService,
        private modalService: ModalService,
        public dbFooterService: DBFooterService,
        private productService: ProductService,
        public router: Router)
    {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        super.verifyState();
        if (this.dbService.customerDesignedDoor.width && this.dbService.customerDesignedDoor.width.feet > 0) this.selectedWidthFeet = this.dbService.customerDesignedDoor.width.feet;
        if (this.dbService.customerDesignedDoor.width && this.dbService.customerDesignedDoor.width.inches > 0) this.selectedWidthInches = this.dbService.customerDesignedDoor.width.inches;
        if (this.dbService.customerDesignedDoor.height && this.dbService.customerDesignedDoor.height.feet > 0) {
            this.selectedHeightFeet = this.dbService.customerDesignedDoor.height.feet;
            this.setHeightInches();
        }
        if (this.dbService.customerDesignedDoor.height && this.dbService.customerDesignedDoor.height.inches > 0) this.selectedHeightInches = this.dbService.customerDesignedDoor.height.inches;
        this.http.get('products/minMaxWidthHeight').subscribe(result => {
            if (result) {
                if (result['minWidth']) {
                    this.dbService.customerDesignedDoor.minWidth = result['minWidth'];
                    if (!this.selectedWidthFeet) this.selectedWidthFeet = this.dbService.customerDesignedDoor.minWidth;
                }
                if (result['maxWidth'])
                    this.dbService.maxWidth = result['maxWidth'];
                if (result['minHeight']) {
                    this.dbService.customerDesignedDoor.minHeight = result['minHeight'];
                    if (!this.selectedHeightFeet) {
                        this.selectedHeightFeet = this.dbService.customerDesignedDoor.minHeight;
                        this.setHeightInches();
                    }
                }
                if (result['maxHeight'])
                    this.dbService.maxHeight = result['maxHeight'];

                //for (var w = 6; w < this.dbService.customerDesignedDoor.minWidth; w++) {
                //    this.widths.push(w);
                //}
                //for (var w = this.dbService.customerDesignedDoor.minWidth; w <= this.dbService.maxWidth; w++) {
                //    this.widths.push(w);
                //}

                for (var h = 6; h < this.dbService.customerDesignedDoor.minHeight; h++) {
                    this.heights.push(h);
                }
                for (var h = this.dbService.customerDesignedDoor.minHeight; h <= this.dbService.maxHeight; h++) {
                    this.heights.push(h);
                }
            }
        });
        this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumSetupStepsComplete());
        super.ngOnInit();
    }

    async nextState() {
        const formerHeightFeet = this.dbService.customerDesignedDoor.height.feet;
        this.dbService.customerDesignedDoor.width.feet = this.selectedWidthFeet;
        this.dbService.customerDesignedDoor.width.inches = this.selectedWidthInches;
        this.dbService.customerDesignedDoor.height.feet = this.selectedHeightFeet;
        this.dbService.customerDesignedDoor.height.inches = this.selectedHeightInches;

        if (this.dbService.customerDesignedDoor.opener && formerHeightFeet !== this.selectedHeightFeet) {
            this.dbService.customerDesignedDoor.opener = null;
            this.productService.getDoorExtras(this.dbService.customerDesignedDoor).subscribe(result => {
                if (result) {
                    if (result['doorExtras']) {
                        this.dbService.possibleExtras = result['doorExtras'];
                        this.dbService.possibleExtras['extras'].forEach(pe => {
                            pe.quantity = 0;
                        });
                        this.dbService.extras = this.dbService.possibleExtras['extras'];
                        if (this.dbService.possibleExtras['openers']) {
                            this.dbService.extras = this.dbService.extras.concat(this.dbService.possibleExtras['openers'].filter(ex => ex.isOperator === false));
                        }
                    }
                }
            });
        }
        //if (!this.dbService.laborRate) {
        //    this.dbService.getLaborRate();
        //}

        //let isNextStateNeeded = true;
        if (this.dbService.selectedStyle || this.dbService.customerDesignedDoor.productGroup) {
            await this.dbService.validateSelections();
        }
        super.nextState(this.dbService.customerDesignedDoor.getNumSetupStepsComplete());
    }

    showSizingInfoWindow() {
        this.isShowSizingInfoWindow = true;
        this.modalService.open('sizing-info');
    }

    hideSizingInfoWindow() {
        this.isShowSizingInfoWindow = false;
        this.modalService.close('sizing-info');
    }

    onWidthFeetChange() {
        //this.selectedWidthInches = null;
        //if (this.selectedWidthFeet == this.dbService.maxWidth) {
        //    this.isWidthInchesDisabled = true;
        //} else {
        //    this.isWidthInchesDisabled = false;
        //}

        this.selectedWidthInches = null;
        if (this.selectedWidthFeet == this.dbService.maxWidth || this.selectedWidthFeet < 8) {
            this.isWidthInchesDisabled = true;
        } else {
            this.isWidthInchesDisabled = false;
        }
        if (this.selectedWidthFeet === 18) {
            this.widthInches = [2];
        } else {
            this.widthInches = [2, 4, 6, 8, 10];
        }
        ///**** supply problems ***/
        //if (this.selectedWidthFeet === 15) {
        //    this.isWidthInchesDisabled = false;
        //} else {
        //    this.isWidthInchesDisabled = true;
        //}
    }

    onHeightFeetChange() {
        this.selectedHeightInches = null;
        if (this.selectedHeightFeet == this.dbService.maxHeight) {
            this.isHeightInchesDisabled = true;
        } else {
            this.isHeightInchesDisabled = false;
        }
        this.setHeightInches();
    }

    setHeightInches() {
        if (this.selectedHeightFeet == 7) {
            this.heightInches = [6, 9];
        } else {
            this.heightInches = [3, 6, 9];
        }
    }

    isNextButtonDisabled() {
        if (this.selectedHeightFeet && this.selectedWidthFeet) {
            return false;
        } else {
            return true;
        }
    }
}