﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { FAQ } from '../models';

@Injectable({
    providedIn: 'root'
})
export class FAQService {
    constructor(private http: HttpClient) { }

    getFAQs(): Observable<FAQ> {
        return this.http.get<FAQ>('faq');
    }
}