export class ProductPrice {
  _id: Object;
  product: Object;
  MPH: number;
  Drawing: string;
  width: number;
  height: number;
  basePrice: number;
  glazedMarkup: number;
  insulatedMarkup: number;
  decratrimMarkup: number;
  decraglassMarkup: number;
};