﻿import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CustomerReview } from '../shared/models';
import { BuildADoorService } from '../shared/services';

@Component({
    selector: 'app-reviews',
    templateUrl: './reviews.component.html',
    styleUrls: ['./reviews.component.css']
})
export class ReviewsComponent implements OnInit {
    reviews: CustomerReview[];

    constructor(private dbService: BuildADoorService, private changeDetector: ChangeDetectorRef) { }

    async ngOnInit() {
        this.reviews = [new CustomerReview({
            title: 'Something I Could Afford',
            quote: 'I’m on a fixed income and was worried that I couldn’t afford a new door. But we worked together to find one to fit my garage and my budget.',
            name: 'Oliver P.',
            location: 'Newark'
        }), new CustomerReview({
            title: 'Found What I Needed with our Old Home',
            quote: 'Despite having an older home, they were able to install a door that felt like it always belonged there.',
            name: 'Henry R.',
            location: 'Escondido'
        }), new CustomerReview({
            title: 'Choices and More Choices',
            quote: 'We loved their selection and found exactly what we wanted at the price we wanted. Excellent',
            name: 'Rick and Barbara B.',
            location: 'Houston'
        }), new CustomerReview({
            title: 'Affordable!',
            quote: 'Their prices were great!',
            name: 'Tim K.',
            location: 'Detroit'
        }), new CustomerReview({
            title: 'Thumbs Up',
            quote: 'Their staff was quick and courteous and left the work site spotless.',
            name: 'Rachel S.',
            location: 'Milwaukee'
        }), new CustomerReview({
            title: 'Wonderful Experience',
            quote: 'My family had a wonderful experience with a garage door installation on a small and much older garage.',
            name: 'Ann B.',
            location: 'SC'
        }), new CustomerReview({
            title: 'Not Too Heavy Now!',
            quote: 'My old wooden garage door was too heavy for an elderly woman to handle. They did a good job.',
            name: 'Arlington',
            location: 'WV'
        })];
    }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

}