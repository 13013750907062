﻿import { WidthBasedPricing } from '.';

export class DoorLock {
    _id: string;
    name: string;
    imageURL: string;
    price: number;
    canChangeQuantity: boolean;
    quantity: number = 1;
    widthBasedPricing: WidthBasedPricing[];
}
