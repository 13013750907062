﻿import { Component, ChangeDetectorRef } from '@angular/core';
import { BuildADoorService } from '../shared/services';

@Component({
    selector: 'app-return-policy',
    templateUrl: './return-policy.component.html',
    styleUrls: ['./return-policy.component.css']
})
export class ReturnPolicyComponent {
    constructor(private dbService: BuildADoorService, private changeDetector: ChangeDetectorRef) { }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

}