﻿import { Component } from '@angular/core';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';
import { Router } from '@angular/router';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { DoorDesign } from 'src/app/shared/models';

@Component({
    selector: 'app-panel-design',
    templateUrl: './panel-design.component.html',
    styleUrls: ['./panel-design.component.css'],
})
export class DBDesignPanelDesignComponent extends DBStateComponent {
    private doorDesigns: DoorDesign[];
    private wrapperStyling: Object;

    constructor(public dbService: BuildADoorService, public router: Router, public dbFooterService: DBFooterService, private productService: ProductService) {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        super.verifyState();
        //if (!this.dbService.customerDesignedDoor.productGroup ||
        //    !this.dbService.customerDesignedDoor.productGroup._id) {
        //    this.router.navigate(['/door-builder/design/design-home']);
        //}

        this.productService.getDoorDesignsForProductGroup(this.dbService.getPricesQuery()).subscribe(result => {
            if (result) {
                if (result['doorDesigns']) {
                    this.doorDesigns = super.sortCollectionByDisplayOrder(this.initializeDoorDesigns(result['doorDesigns']));
                    this.dbService.setDoorDesignSelections(this.doorDesigns);
                    this.setCollectionWrapperStyling(this.doorDesigns);
                }
            }
        });

        super.ngOnInit();
    }

    initializeDoorDesigns(designs) {
        let initializedDesigns: DoorDesign[] = [];
        if (!designs) return initializedDesigns;
        for (var i = 0; i < designs.length; i++) {
            let design: DoorDesign = this.dbService.initializeDoorDesign(designs[i]);
            if (design) {
                initializedDesigns.push(design);
            }
            //let design: DoorDesign = new DoorDesign(designs[i]);
            //if (this.dbService.customerDesignedDoor.width.feet >= 8 || (this.dbService.customerDesignedDoor.width.feet == 7 && this.dbService.customerDesignedDoor.width.inches >= 6)) {
            //    initializedDesigns.push(design);
            //} else {
            //    if (design.name === this.dbService.DESIGN_BEAD_BOARD || design.name === this.dbService.DESIGN_SHORT_PANEL || design.name === this.dbService.DESIGN_TUSCANY) {
            //        initializedDesigns.push(design);
            //    }
            //}
        }
        return initializedDesigns;
    }

    getCollectionStyle(index) {
        let style = super.collectionStyle(index);
        if (!this.isMobile()) {
            var colNum = 1 + (index % 2);
            style['grid-column'] = '' + colNum;
        }
        return style;
    }

    getCollectionClass(dd) {
        return super.collectionClass(this.dbService.customerDesignedDoor.doorDesign, dd);
    }

    onClickDoorDesign(dd) {
        if (dd != this.dbService.customerDesignedDoor.doorDesign) {
            this.dbService.customerDesignedDoor.doorDesign = dd;
            this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());

            if (this.dbService.customerDesignedDoor.color) {
                if (this.dbService.customerDesignedDoor.insulation) {
                    let colorExists = null;
                    for (var i = 0; i < this.dbService.customerDesignedDoor.doorDesign.insulationOptionsAndColors.length; i++) {
                        if (this.dbService.customerDesignedDoor.doorDesign.insulationOptionsAndColors[i].insulationOption === this.dbService.customerDesignedDoor.insulation) {
                            colorExists = this.dbService.customerDesignedDoor.doorDesign.insulationOptionsAndColors[i].colors.find(c => c === this.dbService.customerDesignedDoor.color);
                            break;
                        }
                    }
                    if (!colorExists) this.dbService.customerDesignedDoor.color = null;
                } else {
                    this.dbService.customerDesignedDoor.color = null;
                }
            }

            //var self = this;
            //this.dbService.getWindowPrices(function () {
            //    if (self.dbService.customerDesignedDoor.doorDesign.windows.length === 1) {
            //        self.dbService.customerDesignedDoor.window = self.dbService.customerDesignedDoor.doorDesign.windows[0];
            //        self.dbService.isHideWindows = true;
            //    } else {
            //        self.dbService.isHideWindows = false;
            //    }
            //});
            let query = this.dbService.getPricesQuery();
            query.insulationOptions = this.dbService.customerDesignedDoor.doorDesign.getInsulationOptions(this.dbService.customerDesignedDoor.width, this.dbService.customerDesignedDoor.width.inches);
            this.dbService.hasInsulationPricesLoaded = false;
            this.productService.getInsulationOptionPrices(query).subscribe(result => {
                if (result) {
                    if (result['insulationOptions']) {
                        let insulationOptions = result['insulationOptions'];
                        this.dbService.customerDesignedDoor.doorDesign.updateInsulationOptions(insulationOptions);
                        this.dbService.hasInsulationPricesLoaded = true;
                        if (insulationOptions.length === 1) {
                            this.dbService.customerDesignedDoor.insulation = insulationOptions[0];
//                            this.dbService.isHideInsulation = true;
                            this.dbService.getWindowPrices();
                        }
                    }
                }
            });

        }
    }

    isShowContinue() {
        if (this.dbService.customerDesignedDoor.doorDesign) return true;
        return false;
    }

    onClickContinue() {
        if (this.dbService.isHideInsulation()) {
            if (this.dbService.isHideWindows()) {
                super.onClickContinue(this.dbService.customerDesignedDoor.color, '/door-builder/design/select-color');
            } else {
                super.onClickContinue(this.dbService.customerDesignedDoor.window, '/door-builder/design/select-window');
            }
        } else {
            super.onClickContinue(this.dbService.customerDesignedDoor.insulation, '/door-builder/design/select-insulation');
        }
    }

    onClickBack() {
        this.dbService.navButtonPressed = true;
        this.router.navigate(['/door-builder/design/select-model']);
    }

}