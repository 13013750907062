import { DoorSpecial } from './index';
import { ProductGroup } from './index';
import { GalleryEntry } from './gallery-entry';
export class DoorStyle {
    _id: string;
    id: string;
    name: string;
    displayName: string;
  description: string;
    imageURL: string;
    thumbnail: string;
    special: DoorSpecial;
    productGroups: ProductGroup[];
    galleryEntries: GalleryEntry[];

    constructor(s?) {
        if (s) {
            this._id = s._id;
            this.id = s.id;
            this.name = s.name;
            this.displayName = s.displayName;
            this.description = s.description;
            this.imageURL = s.imageURL;
            this.thumbnail = s.thumbnail;
            this.special = s.special;
            this.productGroups = s.productGroups;
            this.galleryEntries = s.galleryEntries;
        }
    }
}