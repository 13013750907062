﻿import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { SafeHtml } from "./SafeHtml.pipe";

@NgModule({
    declarations: [SafeHtml],
    imports: [CommonModule],
    exports: [SafeHtml]
})

export class Pipes{}