﻿import { DoorStyle, ProductGroup, DoorInsulationLevel, TrackAndSpring } from './index';

export class Headroom {
    _id: string;
    name: string;
    style: DoorStyle;
    productGroup: ProductGroup;
    insulationLevel: DoorInsulationLevel;
    tracksAndSprings: TrackAndSpring[];

    constructor(hr?) {
        if (hr) {
            this._id = hr._id;
            this.name = hr.name;
            this.style = hr.style;
            this.productGroup = hr.productGroup;
            this.insulationLevel = hr.insulationLevel;
            this.tracksAndSprings = hr.tracksAndSprings;
        }
    }

}