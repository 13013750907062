﻿import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindHelpComponent } from './find-help.component';
import { RouterModule } from '@angular/router';
import {
    MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
    MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule
} from '@angular/material';

@NgModule({
    imports: [MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
        MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule, CommonModule, RouterModule],
    declarations: [FindHelpComponent],
    exports: [FindHelpComponent]
})
export class FindHelpModule { }
