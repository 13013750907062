﻿import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrderService } from '../../services/order.service';
import { ModalService } from '../../services';

@Component({
    selector: 'app-newsletter-subscribe',
    templateUrl: './newsletter-subscribe.component.html',
    styleUrls: ['./newsletter-subscribe.component.css']
})
export class NewsletterSubscribeComponent implements OnInit {
    subscribeForm: FormGroup;
    email = new FormControl(null, [Validators.required, Validators.email]);

    constructor(private formBuilder: FormBuilder, private orderService: OrderService, private modalService: ModalService) { }

    ngOnInit() {
        this.subscribeForm = this.formBuilder.group({
            email: this.email
        });
    }

    onSubmit(form: FormGroup) {
        this.orderService.newsletterSubscribe(this.email.value).subscribe(result => {
            if (result && result['contact']) {
                // popup modal saying thanks
                this.showThankYouWindow();
            }
        });
    }

    showThankYouWindow() {
        this.modalService.open('thank-you');
    }

    hideThankYouWindow() {
        this.modalService.close('thank-you');
    }
}
