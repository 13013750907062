﻿import { DoorInsulationOption } from './door-insulation-option';
import { DoorWindow } from './door-window';
import { DoorStyle } from './door-style';

export class PriceQuery {
    width: number;
    widthFeet: number;
    widthInches: number;
    height: number;
    MPH: string;
    windLoad: boolean;
    isImpact: boolean;
    isDade: boolean;
    productGroupId: string;
    //zipcode: string;
    style: DoorStyle;
    insulationOptions: DoorInsulationOption[];
    insulationOption: DoorInsulationOption;
    windows: DoorWindow[];
}