import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from '../../shared/services/product.service';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';

@Component({
    selector: 'app-size-selection',
    templateUrl: './size-selection.component.html',
    styleUrls: ['./size-selection.component.css']
})
export class SizeSelectionComponent implements OnInit {
    public minWidth: number = -1;
    public maxWidth: number = -1;
    public minHeight: number = -1;
    public maxHeight: number = -1;
    public widths: number[] = [];
    public heights: number[] = [];
    selectedWidth = new FormControl();
    selectedHeight = new FormControl();

    constructor(private http: HttpClient,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private productService: ProductService,
        private buildADoorService: BuildADoorService
) { }

    ngOnInit() {
        this.http.get('products/minMaxWidthHeight').subscribe(result => {
            if (result) {
                if (result['minWidth'])
                    this.minWidth = result['minWidth'];
                if (result['maxWidth'])
                    this.maxWidth = result['maxWidth'];
                if (result['minHeight'])
                    this.minHeight = result['minHeight'];
                if (result['maxHeight'])
                    this.maxHeight = result['maxHeight'];

                for (var w = this.minWidth; w <= this.maxWidth; w++)
                    this.widths.push(w);

                for (var h = this.minHeight; h <= this.maxHeight; h++)
                    this.heights.push(h);
            }
        });
    }

    onClickNext(route: ActivatedRouteSnapshot) {
        //this.buildADoorService.data = {};
        //if (this.selectedWidth && this.selectedWidth.value) {
        //    this.buildADoorService.data.width = this.selectedWidth;
        //}
        //if (this.selectedHeight && this.selectedHeight.value) {
        //    this.buildADoorService.data.height = this.selectedHeight;
        //}
        //this.router.navigate(['door-builder/style-selection'], { queryParams: { returnUrl: this.createURL(route) } });
    }

  createURL(route: ActivatedRouteSnapshot): string {
    var url = this.router.routerState.snapshot.url;
    if (route && route.url && route.url.length > 0) {
      url = "";
      for (var i = 0; i < route.url.length; i++) {
        url += "/" + route.url[i];
      }
    }
    return url;
  }

}