﻿import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProductService } from '../shared/services/product.service';
import { BuildADoorService } from '../shared/services';
import { DoorSpecial } from '../shared/models';

@Component({
    selector: 'app-promotions',
    templateUrl: './promotions.component.html',
    styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {
    private specials: DoorSpecial[];

    constructor(private dbService: BuildADoorService, private changeDetector: ChangeDetectorRef) { }

    async ngOnInit() {
        await this.dbService.initializeZonesAndSpecials();
        this.specials = this.dbService.getSpecials({});
    }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

    getAppliesTo(promo: DoorSpecial) {
        if (promo.product) return promo.product.name;
        if (promo.productGroup) return promo.productGroup.display_name;
        if (promo.style) return promo.style.displayName;
        if (promo.zone) return promo.zone.name;
        return 'All Products'
    }
}