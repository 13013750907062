import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { Credentials } from '../../shared/models/credentials';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    errors: string;
    isRequesting: boolean;
    submitted: boolean = false;
    credentials: Credentials = { email: '', password: '' };
    return: string = '';

    constructor(private userService: UserService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => this.setReturnRoute(params));
    }

    setReturnRoute(params) {
        this.return = params.returnUrl || '';
    }

    login({ value, valid }: { value: Credentials, valid: boolean }) {
        this.submitted = true;
        this.isRequesting = true;
        this.errors = '';
        if (valid) {
            this.userService.login(value.email, value.password)
                .subscribe(
                    result => {
                        this.isRequesting = false;
                        if (result) {
                            this.router.navigateByUrl(this.return);
                        }
                    },
                    error => {
                        this.errors = error.statusText;
                        this.isRequesting = false;
                    });
        }
    }
}
