﻿import { Component, ChangeDetectorRef } from '@angular/core';
import { BuildADoorService } from '../shared/services';

@Component({
    selector: 'app-warranties',
    templateUrl: './warranties.component.html',
    styleUrls: ['./warranties.component.css']
})
export class WarrantiesComponent {
    constructor(private dbService: BuildADoorService, private changeDetector: ChangeDetectorRef) { }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

}