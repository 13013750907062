import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  constructor(@Inject('BASE_API_URL') private baseUrl: string, @Inject('PAYTRACE_API_URL') private paytraceUrl: string) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.startsWith('https://cors-anywhere')) {
            return next.handle(req);
        } else if (req.url.startsWith('//paytrace')) {
            var url = req.url.replace('//paytrace', this.paytraceUrl);
            const apiReq = req.clone({ url: `${url}` });
            return next.handle(apiReq);
        } else if (req.url.includes('amarr.com')) {
            const apiReq = req.clone({ url: `${req.url}` });
            return next.handle(apiReq);
        } else {
            const apiReq = req.clone({ url: `${this.baseUrl}/${req.url}` });
            return next.handle(apiReq);
        }
  }
}
