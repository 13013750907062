﻿import { Component, OnInit, NgZone } from '@angular/core';
//import { ShoppingCartModule, CartService } from 'ng-shopping-cart';
import { CustomerDesignedDoor } from 'src/app/shared/models';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BuildADoorService } from 'src/app/shared/services';
import { GDSHCartService } from '../gdsh-cart.service';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
    private total: string = "0";

    constructor(private cartService: GDSHCartService<CustomerDesignedDoor>, public router: Router, public dbService: BuildADoorService, private zone: NgZone, private activeRoute: ActivatedRoute) { }

    ngOnInit() {
        this.dbService.zipcode = null;
        this.dbService.selectedStyle = null;
        this.dbService.removeSelections();
        this.cartService.clear();
        this.total = this.activeRoute.snapshot.paramMap.get('total');
    }

    hideThankYouWindow() {
        //this.dbService.startOver();
        this.zone.run(() => {
            this.router.navigate(['/door-builder-home']);
        });
    }
}
