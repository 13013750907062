import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { BuildingZone, DoorStyle, DoorSpecial } from 'src/app/shared/models';
import { ProductService } from 'src/app/shared/services/product.service';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { MatSelect } from '@angular/material';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-style-selection',
    templateUrl: './style-selection.component.html',
    styleUrls: ['./style-selection.component.css']
})
export class StyleSelectionComponent implements OnInit {
    private zones: BuildingZone[];
    private selectedZone: BuildingZone;
    private styles: DoorStyle[];
    private zoneSpecials: {};
    private styleSpecials: {};
    private width: string;
    private height: string;

    constructor(private http: HttpClient,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private productService: ProductService,
        private buildADoorService: BuildADoorService
    ) { }

    ngOnInit() {
        this.width = this.activeRoute.snapshot.paramMap.get('width');
        this.height = this.activeRoute.snapshot.paramMap.get('height');
        this.zoneSpecials = {};
        this.styleSpecials = {};
        this.buildADoorService.getSpecials({ zone: this.selectedZone }).forEach(sp => {
            if (sp.zone)
                this.getZoneSpecial(sp);
            if (sp.style)
                this.styleSpecials[sp.style._id] = sp;
        });
        this.getBuildingZones();
        //this.buildADoorService.getSpecials({ zone: this.selectedZone }).subscribe(result => {
        //    if (result && result['specials']) {
        //        result['specials'].forEach(sp => {
        //            if (sp.zone)
        //                this.getZoneSpecial(sp);
        //            if (sp.style)
        //                this.styleSpecials[sp.style._id] = sp;
        //        });
        //    }
        //    this.getBuildingZones();
        //});
    }

    getZoneSpecial(sp: DoorSpecial) {
            if (sp.zone && !sp.style && !sp.productGroup && !sp.product && !sp.insulationLevel && !sp.MPH && !sp.width && !sp.height)
                this.zoneSpecials[sp.zone._id] = sp;
    }

    onZoneChange() {
        this.getDoorStyles();
    }

    getBuildingZones() {
        this.zones = [];
        this.productService.getBuildingZones().subscribe(result => {
            if (result && result['buildingZones']) {
                this.zones = result['buildingZones'];
                if (this.zones && this.zones.length == 1) {
                    this.selectedZone = this.zones[0];
                    this.onZoneChange();
                }
            }
        });
    }

    getDoorStyles() {
        if (this.selectedZone) {
            this.productService.getStylesForZone(this.selectedZone).subscribe(result => {
                if (result && result['styles']) {
                    this.styles = result['styles'];
                    this.styles.forEach(s => {
                        s.special = this.styleSpecials[s._id];
                        if (!s.special)
                            s.special = this.zoneSpecials[this.selectedZone._id];
                    });
                }
            });
        }
    }

    onStyleClick(style: DoorStyle, route: ActivatedRouteSnapshot) {
        //if (this.selectedZone) {
        //    this.buildADoorService.data.zone = this.selectedZone;
        //}
        //this.router.navigate(['door-builder/product-group-selection'], { queryParams: { returnUrl: this.createURL(route) } });
    }

    createURL(route: ActivatedRouteSnapshot): string {
        var url = this.router.routerState.snapshot.url;
        if (route && route.url && route.url.length > 0) {
            url = "";
            for (var i = 0; i < route.url.length; i++) {
                url += "/" + route.url[i];
            }
        }
        return url;
    }
}
