﻿import { Component } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { BuildADoorService, DBFooterService, ModalService } from 'src/app/shared/services';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { Router, Route, NavigationStart, NavigationEnd } from '@angular/router';
import { Dimension } from 'src/app/shared/models/dimension';
import { GDSHCartItem, CustomerDesignedDoor, AddOn } from 'src/app/shared/models';
import { OrderService } from 'src/app/shared/services/order.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Discount } from 'src/app/shared/models/discount';
import { GDSHCartService } from 'src/app/cart/gdsh-cart.service';

@Component({
    selector: 'app-db-summary',
    templateUrl: './db-summary.component.html',
    styleUrls: ['./db-summary.component.css'],
})
export class DBSummaryComponent extends DBStateComponent {
    private summaryItems: Object[];
    private gridAutoRows: string = "12%";
    sendQuoteForm: FormGroup;
    email = new FormControl(null, [Validators.required, Validators.email]);
    addOnsForm: FormGroup;
    addOn = new FormControl(null, [Validators.required]);
    addOnPrice = new FormControl(null, [Validators.required]);
    discountForm: FormGroup;
    discountAmount = new FormControl(null, [Validators.pattern("^[0-9]*$")]);
    discountPercent = new FormControl(null, [Validators.pattern("^[0-9]*$")]);
    private isUserLoggedIn = false;
    private isUserAdmin = false;
    private taxRate: number;
    isSendQuoteDisabled = false;

    constructor(public dbService: BuildADoorService, public router: Router, public dbFooterService: DBFooterService, private cartService: GDSHCartService<CustomerDesignedDoor>,
        private orderService: OrderService, private formBuilder: FormBuilder, private modalService: ModalService, private userService: UserService) {
        super(dbService, router, dbFooterService);
        this.routeSub = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.initializeSummaryItems();
                this.dbFooterService.hideFooter();
            } else if (event instanceof NavigationStart) {
                this.dbFooterService.showFooter();
            }
        });
    }

    async ngOnInit() {
        this.sendQuoteForm = this.formBuilder.group({
            email: this.email
        });
        this.addOnsForm = this.formBuilder.group({
            addOn: this.addOn,
            addOnPrice: this.addOnPrice
        });
        this.discountForm = this.formBuilder.group({
            discountAmount: this.discountAmount,
            discountPercent: this.discountPercent
        }, { validator: this.oneDiscountValue });
        this.dbFooterService.setSetupState();
        this.dbFooterService.updateParentState(this.dbFooterService.EXTRAS_POSITION);
        this.userService.isLoggedIn().subscribe(b => this.isUserLoggedIn = b);
        this.userService.isAdmin().subscribe(b => this.isUserAdmin = b);
        //if (this.dbService.zipcode) {
        //    this.orderService.calculateTaxes(this.dbService.zipcode).subscribe(result => {
        //        if (result && result['taxAmount']) {
        //            this.taxRate = result['taxAmount'] * 100;
        //        }
        //    });
        //}
    }

    ngOnDestroy() {
        if (this.routeSub) {
            this.routeSub.unsubscribe();
        }
    }

    oneDiscountValue(formGroup: FormGroup): ValidationErrors {
        let discountAmountField = formGroup.controls['discountAmount'].value;
        let discountPercentField = formGroup.controls['discountPercent'].value;
        if ((discountAmountField === null || discountAmountField === undefined) &&
            (discountPercentField === null || discountPercentField === undefined)) {
            return { oneRequired: 'One of Amount and Percent must be provided.' };
        } else if (discountAmountField && discountPercentField) {
            return { oneRequired: 'Only one of Amount and Percent can be provided.' };
        }
        return null;
    }

    atLeastOneValidator(form: FormGroup): ValidationErrors {
        return Object.keys(form.value).some(key => !!form.value[key]) ?
            null :
            { atLeastOneRequired: 'At least one should be selected' };
    }

    //getWindowDescription() {
    //    let desc = this.dbService.customerDesignedDoor.window.name;
    //    if (this.dbService.customerDesignedDoor.glassType) {
    //        desc += ' ';
    //        desc += this.dbService.customerDesignedDoor.glassType.name;
    //    } else if (this.dbService.customerDesignedDoor.window.insulatedSelected) {
    //        desc += ' Insulated';
    //    }
    //    return desc;
    //}

    initializeSummaryItems() {
        this.summaryItems = this.dbService.getSummaryItems(this.dbService.customerDesignedDoor, false);
        //this.summaryItems = [];
        //this.summaryItems.push({ label: 'Collection', value: this.dbService.customerDesignedDoor.productGroup.name, price: '$' + this.dbService.getDisplayPrice(this.dbService.price) });
        //this.summaryItems.push({ label: 'Door Design', value: this.dbService.customerDesignedDoor.doorDesign.name, price: '$0.00' });
        //this.summaryItems.push({ label: 'Construction Type', value: this.dbService.customerDesignedDoor.insulation.name, price: '$' + this.dbService.getDisplayPrice(this.dbService.getPrice(this.dbService.customerDesignedDoor.insulation)) });
        ////this.summaryItems.push({ label: 'Window Style ' + this.dbService.customerDesignedDoor.doorDesign.name, value: this.getWindowDescription(), price: '$' + this.dbService.getDisplayPrice(this.dbService.customerDesignedDoor.getWindowPrice()) });
        ////this.summaryItems.push({ label: 'Color ' + this.dbService.customerDesignedDoor.doorDesign.name + ' ' + this.dbService.customerDesignedDoor.window.name, value: this.dbService.customerDesignedDoor.color.name, price: '$' + this.dbService.getDisplayPrice(this.dbService.customerDesignedDoor.getColorPrice(this.dbService.customerDesignedDoor.color)) });
        //this.summaryItems.push({ label: 'Window Style', value: this.getWindowDescription(), price: '$' + this.dbService.getDisplayPrice(this.dbService.customerDesignedDoor.getWindowPrice()) });
        //this.summaryItems.push({ label: 'Color', value: this.dbService.customerDesignedDoor.color.name, price: '$' + this.dbService.getDisplayPrice(this.dbService.customerDesignedDoor.getColorPrice(this.dbService.customerDesignedDoor.color)) });
        //this.addCollectionItems('Decorative Hardware', this.dbService.customerDesignedDoor.hardware);
        //this.addCollectionItems('Extras', this.dbService.customerDesignedDoor.extras);
        //if (this.dbService.customerDesignedDoor.opener && this.dbService.customerDesignedDoor.opener.name) {
        //    this.summaryItems.push({ label: 'Openers/Operators', value: this.dbService.customerDesignedDoor.opener.name, price: this.dbService.customerDesignedDoor.opener ? '$' + this.dbService.getDisplayPrice(this.dbService.customerDesignedDoor.opener.price) : '$0.00' });
        //}
        //this.addCollectionItems('Locks', this.dbService.customerDesignedDoor.locks);
        //// add blank row
        //this.summaryItems.push({ label: '', value: '', price: '' });
        //this.summaryItems.push({ label: 'Width', value: this.getDimensionDisplayValue(this.dbService.customerDesignedDoor.width), price: '' });
        //this.summaryItems.push({ label: 'Height', value: this.getDimensionDisplayValue(this.dbService.customerDesignedDoor.height), price: '' });
        //this.summaryItems.push({ label: 'Wind Load', value: this.getWindLoadLabel(), price: this.dbService.customerDesignedDoor.productGroup.windLoadPrice ? '$' + this.dbService.getDisplayPrice(this.dbService.customerDesignedDoor.productGroup.windLoadPrice) : '$0.00' });
        //this.summaryItems.push({ label: 'Headroom', value: this.dbService.customerDesignedDoor.headroom ? this.dbService.customerDesignedDoor.headroom['name'] : '', price: '' });
        //this.summaryItems.push({ label: 'Track and Spring', value: this.dbService.customerDesignedDoor.trackAndSpring.name, price: '$' + this.dbService.getDisplayPrice(this.dbService.customerDesignedDoor.trackAndSpring.price) });
        //if (this.dbService.customerDesignedDoor.springWindingOption && this.dbService.customerDesignedDoor.springWindingOption.name) {
        //    this.summaryItems.push({ label: 'Spring Winding Device', value: this.dbService.customerDesignedDoor.springWindingOption.name, price: this.dbService.customerDesignedDoor.springWindingOption ? '$' + this.dbService.getDisplayPrice(this.dbService.customerDesignedDoor.springWindingOption.price) : '' });
        //}
        //this.summaryItems.push({ label: 'Delivery Options', value: this.dbService.customerDesignedDoor.deliveryOption });

        for (var i = 0; i < this.summaryItems.length; i++) {
            this.gridAutoRows += " auto";
        }
    }

    //getWindLoadLabel() {
    //    let label = '';
    //    if (this.dbService.customerDesignedDoor.windSpeed) {
    //        label += 'Yes / ' + this.dbService.customerDesignedDoor.windSpeed;
    //        if (this.dbService.customerDesignedDoor.impactType && this.dbService.customerDesignedDoor.impactType != this.dbService.NON_IMPACT) {
    //            label += ' / ' + this.dbService.customerDesignedDoor.impactType;
    //        }
    //    } else {
    //        label = 'No';
    //    }
    //    return label;
    //}

    //addCollectionItems(itemLabel, itemCollection) {
    //    if (itemCollection && itemCollection.length > 0) {
    //        for (var i = 0; i < itemCollection.length; i++) {
    //            let p = itemCollection[i].quantity ? itemCollection[i].price * itemCollection[i].quantity : itemCollection[i].price;
    //            let v = itemCollection[i].quantity ? '' + itemCollection[i].quantity + ' ' + itemCollection[i].name : itemCollection[i].name;
    //            if (i == 0) {
    //                this.summaryItems.push({ label: itemLabel, value: v, price: '$' + this.dbService.getDisplayPrice(p) });
    //            } else {
    //                this.summaryItems.push({ label: '', value: v, price: '$' + this.dbService.getDisplayPrice(p) });
    //            }
    //        }
    //    } else {
    //        this.summaryItems.push({ label: itemLabel, value: 'None', price: '$0.00' });
    //    }
    //}

    //getDimensionDisplayValue(dimension: Dimension): string {
    //    let dimDisplay = '';
    //    if (dimension) {
    //        dimDisplay += dimension.feet + '\'';
    //        if (dimension.inches > 0) {
    //            dimDisplay += ' ' + dimension.inches + '"';
    //        }
    //    }
    //    return dimDisplay;
    //}

    getProductPrice() {
        let price = this.dbService.price;
        price += this.dbService.getSetupPrice(this.dbService.customerDesignedDoor);
        return this.dbService.getDisplayPrice(price);
    }

    nextState() {
        this.dbService.onNavMenuNextState();
    }

    onClickAddToCart() {
        this.dbService.setApplicableTaxes(0);
        this.dbService.customerDesignedDoor.discount = [];
        this.dbService.customerDesignedDoor.setPrice(this.getAppliedPrice());
        this.dbService.customerDesignedDoor.imageURL = this.dbService.customerDesignedDoor.getImage();
        //let cartItem: GDSHCartItem = new GDSHCartItem({
        //    width: this.getDimensionDisplayValue(this.dbService.customerDesignedDoor.width),
        //    height: this.getDimensionDisplayValue(this.dbService.customerDesignedDoor.height),
        //    productGroupId: this.dbService.customerDesignedDoor.productGroup._id,
        //    designId: this.dbService.customerDesignedDoor.doorDesign._id,
        //    insulationOptionId: this.dbService.customerDesignedDoor.insulation._id,
        //    windowId: this.dbService.customerDesignedDoor.window._id,
        //    colorId: this.dbService.customerDesignedDoor.color._id,
        //    price: this.dbService.getTotalPrice(),
        //    quantity: 1
        //});
        this.cartService.addItem(this.dbService.customerDesignedDoor);
        this.cartService.setTaxRate(this.taxRate ? this.taxRate : 0);
        this.router.navigate(['/cart']);
    }

    getAddToCartText() {
        if (this.dbService.editItemId) return "Update Cart";
        return "Add To Cart";
    }

    getImageClass() {
        if (this.dbService.customerDesignedDoor.getImage()) {
            return 'DBImage';
        }
        return '';
    }

    getTotalPrice() {
        return this.dbService.getTotalPriceDisplay();
    }

    getTotalPriceDisplay() {
        if (this.dbService.getAppliedPromotion()) {
            return '$' + this.dbService.getTotalPriceDisplay();
        }
        return '';
    }

    getPromotionDiscountDisplay() {
        return '$' + this.dbService.getPriceDiscountDisplay();
    }

    getAppliedPrice() {
        if (this.dbService.getAppliedPromotion())
            return this.dbService.getAppliedPriceDisplay();
        return this.getTotalPrice();
    }

    onSendQuote() { 
        this.isSendQuoteDisabled = true;
        this.dbService.customerDesignedDoor.setPrice(this.dbService.getAppliedPrice());
        let displayPrice = (Math.ceil(this.dbService.customerDesignedDoor.totalPrice * 100) / 100).toFixed(2);
        this.dbService.customerDesignedDoor.setPrice(displayPrice);
        this.dbService.customerDesignedDoor.imageURL = this.dbService.customerDesignedDoor.getImage();
        this.dbService.customerDesignedDoor.isClassicaWarning = this.dbService.showClassicaWarning();
        let quantity = this.dbService.customerDesignedDoor.getQuantity();
        let totalDoorPrice = quantity * this.dbService.getAppliedPrice();
        let totalAmount = totalDoorPrice + this.dbService.customerDesignedDoor.getShipping();
        let amount = totalAmount.toFixed(2);
        var quoteData = {
            quantity: quantity,
            amount: amount,
            customer: {
                contact: {
                    emailAddress: this.email.value,
                },
            },
            customerDesignedDoors: [this.dbService.customerDesignedDoor],
            emailAddress: this.email.value,
            summaryItems: [this.dbService.getSummaryItems(this.dbService.customerDesignedDoor, true)],
            shipping: this.dbService.customerDesignedDoor.getShipping()
        }
        this.orderService.sendQuote(quoteData).subscribe(result => {
            if (result && result['contact']) {
                // popup modal saying thanks
                this.showThankYouWindow();
            }
            this.isSendQuoteDisabled = false;
        }, err => {
            this.isSendQuoteDisabled = false;
        });
    }

    onAddOn() {
        let a = new AddOn();
        a.name = this.addOn.value;
        a.price = this.addOnPrice.value;
        this.dbService.customerDesignedDoor.addAddOn(a);
    }

    onDiscount() {
        let d = new Discount();
        d.amount = this.discountAmount.value;
        d.percent = this.discountPercent.value;
        this.dbService.customerDesignedDoor.addDiscount(d);
        //this.dbService.customerDesignedDoor.setPrice(this.getAppliedPrice());
    }

    showThankYouWindow() {
        this.modalService.open('thank-you');
    }

    hideThankYouWindow() {
        this.modalService.close('thank-you');
    }

    isAdmin() {
        return this.isUserLoggedIn && this.isUserAdmin;
    }
}