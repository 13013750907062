﻿import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NavMenuComponent } from './nav-menu.component';
import {
    MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
    MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule
} from '@angular/material';
//import { CartModule } from '../cart/cart.module';
//import { GDSHCartSummaryComponent } from '../cart/cart-summary/cart-summary.component';
//import { ShoppingCartModule } from 'ng-shopping-cart';

@NgModule({
    imports: [CommonModule, MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
        MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule,
        RouterModule],
    declarations: [NavMenuComponent], //GDSHCartSummaryComponent],
    exports: [NavMenuComponent]
})
export class NavMenuModule { }
