﻿import { Component } from '@angular/core';
import { BuildADoorService, ProductService, DBFooterService } from 'src/app/shared/services';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { DoorExtra, DoorOpener, CustomerDesignedDoor } from 'src/app/shared/models';
import { Route, Router } from '@angular/router';
import { GDSHCartService } from 'src/app/cart/gdsh-cart.service';

@Component({
    selector: 'app-extras-home',
    templateUrl: './extras-home.component.html',
    styleUrls: ['./extras-home.component.css'],
})
export class DBExtrasHomeComponent extends DBStateComponent {
    //private extras: DoorExtra[];
    //selectedButton: string;
    //private readonly NO_LABEL = 'No Door Operator Will Ever Be Used';
    //private readonly HAVE_LABEL = 'I am using an Existing Door Operator';
    //private readonly BUYING_LABEL = 'I am Purchasing a new door operator with my door';
    //private buttonClasses: Object = {};
    private taxRate: number;

    constructor(public dbService: BuildADoorService, public router: Router, public dbFooterService: DBFooterService, private productService: ProductService, private cartService: GDSHCartService<CustomerDesignedDoor>) {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        if (!this.dbService.customerDesignedDoor.productGroup ||
            !this.dbService.customerDesignedDoor.productGroup._id) {
            this.router.navigate(['/door-builder/design/design-home']);
        }
        this.dbFooterService.setParentState();
        //this.dbFooterService.totalStates = 4;
        this.dbFooterService.updateParentState(this.dbFooterService.HEADROOM_POSITION);
        this.dbFooterService.updateNumComplete();

        this.productService.getDoorExtras(this.dbService.customerDesignedDoor).subscribe(result => {
            if (result) {
                if (result['doorExtras']) {
                    this.dbService.possibleExtras = result['doorExtras'];
                    this.dbService.possibleExtras['extras'].forEach(pe => {
                        pe.quantity = 0;
                    });
                    this.dbService.extras = this.dbService.possibleExtras['extras'];
                    if (this.dbService.possibleExtras['openers']) {
                        this.dbService.extras = this.dbService.extras.concat(this.dbService.possibleExtras['openers'].filter(ex => ex.isOperator === false));
                    }
                }
            }
        });
        //this.productService.getDoorExtras(this.dbService.customerDesignedDoor).subscribe(result => {
        //    if (result) {
        //        if (result['doorExtras']) {
        //            this.dbService.possibleExtras = result['doorExtras'];
        //            this.extras = this.dbService.possibleExtras['extras'];
        //            this.setCollectionWrapperStyling(this.extras);
        //            this.setupExtras();
        //           //this.buttonClasses[this.NO_LABEL] = 'White-BG DB-Button-Container';
        //            //this.buttonClasses[this.HAVE_LABEL] = 'White-BG DB-Button-Container';
        //            //this.buttonClasses[this.BUYING_LABEL] = 'White-BG DB-Button-Container';
        //        }
        //    }


        //});
        super.ngOnInit();
        if (!this.dbService.operatorPrepOption) {
            this.router.navigate(['/door-builder/design/operator-prep-options']);
        } else if (this.dbService.operatorPrepOption === this.dbService.BUYING_OPERATOR_LABEL && !this.dbService.customerDesignedDoor.opener) {
            this.router.navigate(['/door-builder/design/operators-openers']);
        }
    }

    //setupExtras() {
    //    if (this.dbService.operatorPrepOption == this.dbService.HAVE_OPERATOR_LABEL) {
    //        // if extra .isAutoSelected then show and select, otherwise hide
    //        for (var i = 0; i < this.extras.length; i++) {
    //            let ex = this.extras[i];
    //            if (ex.isAutoSelect) {
    //                let selectedExtra = this.dbService.customerDesignedDoor.extras.find(e => e._id === ex._id);
    //                if (!selectedExtra) this.onClickExtra(ex);
    //            }
    //            ex.isHidden = false;
    //        }
    //    } else {
    //        // show all, do not auto select any
    //        for (var i = 0; i < this.extras.length; i++) {
    //            let ex = this.extras[i];
    //            if (ex.isAutoSelect) {
    //                let selectedExtra = this.dbService.customerDesignedDoor.extras.find(e => e._id === ex._id);
    //                if (selectedExtra) this.onClickExtra(ex);
    //                ex.isHidden = true;
    //            } else {
    //                // hide
    //                ex.isHidden = false;
    //            }
    //        }
    //    }
    //    this.showContinue = true;
    //}

    //buttonClick(button) {
    //    //this.buttonClasses[this.HAVE_LABEL] = 'White-BG DB-Button-Container';
    //    //this.buttonClasses[this.NO_LABEL] = 'White-BG DB-Button-Container';
    //    //this.buttonClasses[this.BUYING_LABEL] = 'White-BG DB-Button-Container';
    //    //this.buttonClasses[button] += ' Div-Selected';
    //    this.selectedButton = button;
    //    if (this.selectedButton == this.HAVE_LABEL) {
    //        // if extra .isAutoSelected then show and select, otherwise hide
    //        for (var i = 0; i < this.extras.length; i++) {
    //            let ex = this.extras[i];
    //            if (ex.isAutoSelect) {
    //                let selectedExtra = this.dbService.customerDesignedDoor.extras.find(e => e._id === ex._id);
    //                if (!selectedExtra) this.onClickExtra(ex);
    //            }
    //            ex.isHidden = false;
    //        }
    //    } else {
    //        // show all, do not auto select any
    //        for (var i = 0; i < this.extras.length; i++) {
    //            let ex = this.extras[i];
    //            if (ex.isAutoSelect) {
    //                let selectedExtra = this.dbService.customerDesignedDoor.extras.find(e => e._id === ex._id);
    //                if (selectedExtra) this.onClickExtra(ex);
    //                ex.isHidden = true;
    //            } else {
    //                // hide
    //                ex.isHidden = false;
    //            }
    //        }
    //    }
    //    this.showContinue = true;
    //}

    //buttonClass(button) {
    //    return this.buttonClasses[button];
    //}

    //getOpenerDescription() {
    //    let desc = '';
    //    if (this.dbService.customerDesignedDoor.opener) {
    //        desc = this.dbService.customerDesignedDoor.opener.name;
    //        if (this.dbService.customerDesignedDoor.opener.price && this.dbService.customerDesignedDoor.opener.price > 0) {
    //            desc += ' ($' + this.dbService.getPriceDisplay(this.dbService.customerDesignedDoor.opener.price) + ')';
    //        }
    //    }
    //    return desc;
    //}

    //getCollectionStyle(e, index) {
    //    if (e.isHidden) return { 'display': 'none' };
    //    let numHiddenBefore = 0;
    //    for (var i = 0; i < this.extras.length; i++) {
    //        if (this.extras[i]._id === e._id) break;
    //        if (this.extras[i].isHidden) numHiddenBefore++;
    //    }
    //    index = index - numHiddenBefore;
    //    let style = super.collectionStyle(index);
    //    if (!this.isMobile()) {
    //        var colNum = 1 + (index % 2);
    //        style['grid-column'] = '' + colNum;
    //    }
    //    return style;
    //}

    //getCollectionClass(e) {
    //    let existing = this.dbService.customerDesignedDoor.extras.find(h => h._id === e._id);
    //    if (existing) {
    //        return 'Collection-Button-Selected';
    //    } else {
    //        return 'Collection-Button-Unselected';
    //    }
    //}

    //getHardwareDescription() {
    //    let desc = '';
    //    let hwTotal = 0;
    //    if (this.dbService.customerDesignedDoor.hardware && this.dbService.customerDesignedDoor.hardware.length > 0) {
    //        //if (this.dbService.customerDesignedDoor.hardware.length === 1 && this.dbService.customerDesignedDoor.hardware[0] === this.dbService.noneHardware) {
    //        //    desc += this.dbService.noneHardware.name;
    //        //} else {
    //            for (var i = 0; i < this.dbService.customerDesignedDoor.hardware.length; i++) {
    //                if (i > 0) desc += ', ';
    //                let hw = this.dbService.customerDesignedDoor.hardware[i];
    //                if (hw.size) {
    //                    desc += hw.size + '" ';
    //                }
    //                desc += hw.name;
    //                if (hw.description) {
    //                    desc += ' (' + hw.description + ')';
    //                }
    //                hwTotal += hw.price;
    //            }
    //            if (hwTotal > 0)
    //                desc += ' ($' + this.dbService.getPriceDisplay(hwTotal) + ')';
    //        //}
    //    }
    //    return desc;
    //}

    getDescription(field: string): string {
        let desc = '';
        let total = 0;
        if (this.dbService.customerDesignedDoor[field] && this.dbService.customerDesignedDoor[field].length > 0) {
            for (var i = 0; i < this.dbService.customerDesignedDoor[field].length; i++) {
                if (i > 0) desc += ', ';
                let item = this.dbService.customerDesignedDoor[field][i];
                if (item.quantity && item.quantity > 1) {
                    desc += item.quantity + ' ';
                }
                if (item.size) {
                    desc += item.size + '" ';
                }
                desc += item.name;
                if (item.description) {
                    desc += ' (' + item.description + ')';
                }
                if (item.quantity && item.quantity > 0) {
                    total += item.quantity * item.price;
                } else {
                    total += item.price;
                }
            }
            if (total > 0)
                desc += ' ($' + this.dbService.getPriceDisplayNumericOnly(total) + ')';
        }
        return desc;
    }

    getOpenerDescription(): string {
        let desc = '';
        if (this.dbService.customerDesignedDoor.opener)
            desc = this.dbService.customerDesignedDoor.opener.name;
        return desc;
    }

    //onClickExtra(e) {
    //    let originalLength = this.dbService.customerDesignedDoor.extras.length;
    //    this.dbService.customerDesignedDoor.extras = this.dbService.customerDesignedDoor.extras.filter(function (value, index, arr) {
    //        return value != e;
    //    });
    //    if (originalLength === this.dbService.customerDesignedDoor.extras.length)
    //        this.dbService.customerDesignedDoor.extras.push(e);
    //}

    //getPrice(e) {
    //    if (e.price > 0) {
    //        return '$' + e.price;
    //    }
    //    return 'Price Included';
    //}

    onClickContinue() {
        super.nextState();
    }

    isShowOperators() {
        return this.dbService.operatorPrepOption === this.dbService.BUYING_OPERATOR_LABEL;
    }

    isShowContinue() {
        if (!this.dbService.operatorPrepOption) return false;
        if (this.dbService.operatorPrepOption !== this.dbService.BUYING_OPERATOR_LABEL) return true;
        if (!this.dbService.customerDesignedDoor.opener) return false;
        return true;
    }

    getTotalPrice() {
        return this.dbService.getTotalPriceDisplay();
    }

    getAppliedPrice() {
        if (this.dbService.getAppliedPromotion())
            return this.dbService.getAppliedPriceDisplay();
        return this.getTotalPrice();
    }

    onClickAddToCart() {
        this.dbService.setApplicableTaxes(0);
        this.dbService.customerDesignedDoor.discount = [];
        this.dbService.customerDesignedDoor.setPrice(this.getAppliedPrice());
        this.dbService.customerDesignedDoor.imageURL = this.dbService.customerDesignedDoor.getImage();
        this.cartService.addItem(this.dbService.customerDesignedDoor);
        this.cartService.setTaxRate(this.taxRate ? this.taxRate : 0);
        this.router.navigate(['/cart']);
    }
}