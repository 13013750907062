﻿export class TrackMountingOption {
    _id: string;
    name: string;
    description: string;
    price: number;

    constructor(tam?) {
        if (tam) {
            this._id = tam._id;
            this.name = tam.name;
            this.description = tam.description;
            this.price = tam.price;
        }
    }
}