import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BuildADoorRoutingModule } from './build-a-door-routing.module';
import { BuildADoorComponent } from './build-a-door.component';
import { StyleSelectionComponent } from './style-selection/style-selection.component';
import { ProductGroupSelectionComponent } from './product-group-selection/product-group-selection.component';
//import { DoorOptionsSelectionComponent } from './door-options-selection/door-options-selection.component';
import { SizeSelectionComponent } from './size-selection/size-selection.component';
import { DBNavMenuModule } from '../shared/components/db-nav-menu/db-nav-menu.module';
import { DBFooterModule } from '../shared/components/db-footer/db-footer.module';
import { HowToMeasureModule } from '../shared/components/how-to-measure/how-to-measure.module';
import { DBSetupComponent } from './db-setup/db-setup.component';
import { DBSetupSelectSizeComponent } from './db-setup/select-size/select-size.component';
import { DBSetupSelectWindRatingComponent } from './db-setup/select-wind-rating/select-wind-rating.component';
import { DBDesignComponent } from './db-design/db-design.component';
import { DBDesignHomeComponent } from './db-design/design-home/design-home.component';
import { DBDesignModelComponent } from './db-design/model/model.component';
import { DBDesignSelectCollectionComponent } from './db-design/select-collection/select-collection.component';
import { DBDesignPanelDesignComponent } from './db-design/panel-design/panel-design.component';
import { DBDesignInsulationComponent } from './db-design/insulation/insulation.component';
import { DBDesignWindowComponent } from './db-design/window/window.component';
import { DBDesignColorComponent } from './db-design/color/color.component';
import { DBDesignHardwareComponent } from './extras-home/hardware/hardware.component';
import { DBExtrasHomeComponent } from './extras-home/extras-home.component';
import { DBExtrasOperatorsOpenersComponent } from './extras-home/operators-openers/operators-openers.component';
import { DBLocksComponent } from './extras-home/locks/locks.component';
import { DBHeadroomComponent } from './db-headroom/db-headroom.component';
import { DBSummaryComponent } from './db-summary/db-summary.component';
import { NavMenuModule } from '../nav-menu/nav-menu.module';
import { FooterModule } from '../shared/components/footer/footer.module';
import { ModalModule } from '../shared/components/modal/modal.module';
import { FlexLayoutModule } from '@angular/flex-layout';
//import { ShoppingCartModule } from 'ng-shopping-cart';
import {
    MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
    MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule
} from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DBOperatorPrepOptionsComponent } from './extras-home/operator-prep-options/operator-prep-options.component';
import { DBExtrasComponent } from './extras-home/extras/extras.component';
import { CartModule } from '../cart/cart.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        BuildADoorRoutingModule,
        MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
        MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule,
        MatAutocompleteModule,
        CartModule, NavMenuModule, FooterModule, ModalModule, DBNavMenuModule, DBFooterModule, HowToMeasureModule
    ],
    declarations: [BuildADoorComponent, StyleSelectionComponent, SizeSelectionComponent, ProductGroupSelectionComponent,
        DBSetupComponent, DBSetupSelectSizeComponent, DBSetupSelectWindRatingComponent,
        DBDesignComponent, DBDesignHomeComponent, DBDesignSelectCollectionComponent, DBDesignPanelDesignComponent, DBDesignInsulationComponent, DBDesignWindowComponent, DBDesignColorComponent, DBDesignHardwareComponent,
        DBExtrasHomeComponent, DBExtrasOperatorsOpenersComponent, DBLocksComponent, DBOperatorPrepOptionsComponent, DBExtrasComponent, DBDesignModelComponent,
        DBHeadroomComponent, DBSummaryComponent]
})
export class BuildADoorModule { }
