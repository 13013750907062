﻿export class DoorOpener {
    _id: string;
    name: string;
    details: string[];
    imageURL: string;
    price: number;
    brochureURL: string;
    description: string;
    canChangeQuantity: boolean;
    quantity: number = 1;
    hasBatteryBackup: boolean;
    noLowHeadroom: boolean;
    noHighLift: boolean;
    noVerticalLift: boolean;
    noExtensionSprings: boolean;
    noRoofPitch: boolean;
}
