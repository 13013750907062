﻿import { Component, OnInit, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProductService } from '../shared/services/product.service';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ProductGroup, DoorDesign, DoorInsulationOption, DoorInsulationLevel, InsulationSpecification, DoorColor, CustomerDesignedDoor } from '../shared/models';
import { SafeHtml } from '../shared/pipes/SafeHtml.pipe';
import { BuildADoorService } from '../shared/services';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
    @ViewChild('reviewsDiv') el: ElementRef;
    private productGroup: ProductGroup;
    private allProductGroups: ProductGroup[];
    private doorDesigns: DoorDesign[];
    private activeColors: DoorColor[];
    private scrWidth: any;
    private selectedDoorDesign: DoorDesign;
    private allInsulationOptions: DoorInsulationOption[] = [];
    private combinedInsulationOptions: DoorInsulationOption[] = [];
    private specificationFootnotes: string[] = [];
    private colorFootnotes: Object[] = [];
    private designsWrapperStyling: Object = {
        'text-align': 'center',
        'display': 'grid',
        'margin': '5px 40px 5px 0px',
        'max-height': '450px',
        'overflow-y': 'auto'
    };
    private productImageURL: string;
    //private htmlTemplate: any = "";
    //private POWERREVIEWS: any = window['POWERREVIEWS'];

    constructor(private http: HttpClient,
        private productService: ProductService,
        private dbService: BuildADoorService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private changeDetector: ChangeDetectorRef
    ) {
        let pg = this.activeRoute.snapshot.paramMap.get('productGroup');
        if (!this.productGroup) {
            this.loadProductGroup(pg);
        }
    }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

    async ngOnInit() {
        let pg = this.activeRoute.snapshot.paramMap.get('productGroup');
        if (!this.allProductGroups || !this.allProductGroups.length) this.allProductGroups = await this.productService.getAllProductGroups();
        if (!this.productGroup) {
            this.loadProductGroup(pg);
        }
    }

    initializeDoorDesigns(designs) {
        let initializedDesigns: DoorDesign[] = [];
        if (!designs) return initializedDesigns;
        for (var i = 0; i < designs.length; i++) {
            let design: DoorDesign = new DoorDesign(designs[i]);
            initializedDesigns.push(design);
        }
        return initializedDesigns;
    }

    async loadProductGroup(urlFriendlyId: string) {
        if (!this.allProductGroups || !this.allProductGroups.length) this.allProductGroups = await this.productService.getAllProductGroups();
        let pg = this.allProductGroups.find(p => p.urlFriendlyId === urlFriendlyId);
        if (pg) {
            let pgId = pg._id;
            this.productService.getProductGroup(pgId).subscribe(result => {
                if (result && result['productGroup']) {
                    this.productGroup = result['productGroup'];
                    this.productImageURL = this.productGroup.imageURL;
                    //this.POWERREVIEWS.display.snippet(document, {
                    //    pr_page_id: 'stratford',
                    //    pr_write_review: '',
                    //    pr_read_review: ''
                    //});
                }
            });
            this.productService.getDoorDesignsForProductGroup({ productGroup: pgId }).subscribe(result => {
                if (result) {
                    if (result['doorDesigns']) {
                        this.allInsulationOptions = [];
                        this.combinedInsulationOptions = [];
                        let existingCombinedOptions = {};
                        let existingAllOptions = {};
                        let colors = [];
                        this.doorDesigns = this.initializeDoorDesigns(result['doorDesigns']);
                        var gridStyling = '';
                        if (this.doorDesigns && this.doorDesigns.length > 0) {
                            var numRows = this.doorDesigns.length / 4;
                            if (this.doorDesigns.length % 4 > 0) numRows++;
                            for (var i = 0; i < numRows; i++) {
                                if (i != numRows - 1)
                                    gridStyling += 'auto ';
                            }
                            gridStyling += 'auto';
                            let panelPercent = (100 / this.doorDesigns.length);
                            for (var i = 0; i < this.doorDesigns.length; i++) {
                                colors = colors.concat(this.doorDesigns[i].getColors());
                                let existingLevels = {};
                                let currentDoorDesign = this.doorDesigns[i];
                                //if (i != this.doorDesigns.length - 1)
                                //    gridStyling += panelPercent + '% ';

                                // create insulationOptions object with no duplicates across designs
                                if (currentDoorDesign) {
                                    let insulationOptions = currentDoorDesign.getInsulationOptions(null, null);
                                    if (insulationOptions && insulationOptions.length > 0) {
                                        for (var io = 0; io < insulationOptions.length; io++) {
                                            let currentIO = insulationOptions[io];
                                            if (!currentIO) continue;
                                            if (!existingLevels[currentIO.insulationLevel._id]) {
                                                existingLevels[currentIO.insulationLevel._id] = [];
                                                existingLevels[currentIO.insulationLevel._id].push(currentIO)
                                            } else {
                                                existingLevels[currentIO.insulationLevel._id].push(currentIO)
                                            }
                                            if (!existingAllOptions[currentIO._id]) {
                                                existingAllOptions[currentIO._id] = true;
                                                this.allInsulationOptions.push(currentIO);
                                            }
                                        }

                                        insulationOptions = [];
                                        for (var il in existingLevels) {
                                            let currentIOs = existingLevels[il];
                                            if (currentIOs && currentIOs.length > 0) {
                                                let currentIO = currentIOs[0];
                                                currentIO.displayName = this.getLastWordInString(currentIO.name);
                                                for (var idx = 1; idx < currentIOs.length; idx++) {
                                                    currentIO.displayName += ' / ' + this.getLastWordInString(currentIOs[idx].name);
                                                }
                                                insulationOptions.push(currentIO);
                                                if (!existingCombinedOptions[currentIO._id]) {
                                                    existingCombinedOptions[currentIO._id] = true;
                                                    this.combinedInsulationOptions.push(currentIO);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            //gridStyling += panelPercent + '%';
                        }
                        this.designsWrapperStyling['grid-template-rows'] = gridStyling;
                        this.designsWrapperStyling['grid-template-columns'] = '25% 25% 25% 25%';
                        let unique = {};
                        this.activeColors = [];
                        for (var j = 0; j < colors.length; j++) {
                            unique[colors[j]._id] = colors[j];
                        }
                        for (var key in unique) {
                            this.activeColors.push(unique[key]);
                        }
                        this.determineColorFootnotes();
                    }
                }
            });
        }
    }

    getReviewsURL() {
        return 'https://www.amarr.com/mobile/collection/' + this.productGroup.name;
    }

    determineColorFootnotes() {
        let footnotes = {};
        let maxIocs = 0;
        this.activeColors = [];
        if (this.doorDesigns && this.doorDesigns.length > 0) {
            for (var ddIdx = 0; ddIdx < this.doorDesigns.length; ddIdx++) {
                let doorDesign = this.doorDesigns[ddIdx];
                if (doorDesign.insulationOptionsAndColors.length > maxIocs) maxIocs = doorDesign.insulationOptionsAndColors.length;
                for (var iocIdx = 0; iocIdx < doorDesign.insulationOptionsAndColors.length; iocIdx++) {
                    let ioc = doorDesign.insulationOptionsAndColors[iocIdx];
                    for (var cIdx = 0; cIdx < ioc.colors.length; cIdx++) {
                        let currentColor = ioc.colors[cIdx];
                        if (!footnotes[currentColor._id]) {
                            footnotes[currentColor._id] = {
                                color: currentColor,
                                doorDesigns: {}
                            }
                        }
                        if (!footnotes[currentColor._id].doorDesigns[doorDesign.name]) {
                            footnotes[currentColor._id].doorDesigns[doorDesign.name] = {};
                        }
                        footnotes[currentColor._id].doorDesigns[doorDesign.name][ioc.insulationOption.name] = true;
                    }
                }
            }
        }
        this.activeColors = [];
        let footnoteTextBegin = 'Available in';
        let footnoteTexts = {};
        var numFootnote = 0;
        for (var key in footnotes) {
            let footnoteText = footnoteTextBegin;
            let foundInsulation = {};
            let missingDesigns = {};
            for (var ddKey in footnotes[key].doorDesigns) {
                if (Object.keys(footnotes[key].doorDesigns).length != this.doorDesigns.length) {
                    // add a footnote, add to footnote text
                    footnoteText += ' ' + ddKey;
                    if (Object.keys(footnotes[key].doorDesigns[ddKey]).length != maxIocs) {
                        for (var iocKey in footnotes[key].doorDesigns[ddKey]) {
                            if (!foundInsulation[iocKey]) {
                                foundInsulation[iocKey] = true;
                                footnoteText += ' ' + iocKey;
                            }
                        }
                    }
                } else if (Object.keys(footnotes[key].doorDesigns[ddKey]).length != maxIocs) {
                    for (var iocKey in footnotes[key].doorDesigns[ddKey]) {
                        if (!foundInsulation[iocKey]) {
                            foundInsulation[iocKey] = true;
                            footnoteText += ' ' + iocKey;
                        }
                        if (!missingDesigns[ddKey]) {
                            missingDesigns[ddKey] = true;
                        }
                    }
                }
            }
            if (footnotes[key].color.isWoodGrained) {
                let priceUpcharge = 'Price upcharge applies';
                footnotes[key].color['footnote'] = priceUpcharge;
                footnoteTexts[priceUpcharge] = '‡';
            } else if (footnoteText != footnoteTextBegin) {
                if (Object.keys(missingDesigns).length != this.doorDesigns.length) {
                    footnoteText += ' for ';
                    for (var missingDesign in missingDesigns) {
                        footnoteText += missingDesign;
                    }
                }
                footnotes[key].color['footnote'] = footnoteText;
                if (!footnoteTexts[footnoteText]) {
                    footnoteTexts[footnoteText] = ++numFootnote;
                }
            }
            this.activeColors.push(footnotes[key].color);
        }
        this.colorFootnotes = [];
        if (footnoteTexts && Object.keys(footnoteTexts) && Object.keys(footnoteTexts).length > 0) {
            for (var key in footnoteTexts) {
                this.colorFootnotes.push({
                    symbol: footnoteTexts[key],
                    text: key
                });
            }
        }
    }

    getLastWordInString(inString: string) {
        let splitString = inString.split(' ');
        if (splitString && splitString.length > 0) {
            return splitString[splitString.length - 1];
        }
        return inString;
    }

    public collectionClass(matchAgainst) {
        if (!matchAgainst || !this.selectedDoorDesign) return 'Collection-Button-Unselected';
        if (matchAgainst._id === this.selectedDoorDesign._id) {
            return 'Collection-Button-Selected';
        } else {
            return 'Collection-Button-Unselected';
        }
    }

    public collectionStyle(index: number) {
        let style = {
            'grid-column': (index % 4) + 1,
            'grid-row': (index / 4) + 1,
            'text-align': 'center',
            'padding-left': '5px',
            'padding-right': '5px',
            'padding-top': '2px',
            'padding-bottom': '2px',
            'background-color': '#ffffff'
            //'height': '114px'
        };
        return style;
    }

    getActiveColors() {
        if (this.selectedDoorDesign) return this.selectedDoorDesign.getColors();
        let colors = [];
        for (var i = 0; i < this.doorDesigns.length; i++) {
            colors = colors.concat(this.doorDesigns[i].getColors());
        }
        let unique = {};
        for (var j = 0; j < colors.length; j++) {
            unique[colors[j]._id] = colors[j];
        }
        let uniqueColors = [];
        for (var key in unique) {
            uniqueColors.push(unique[key]);
        }
        return uniqueColors;
    }

    getActiveWoodGrainedColors() {
        if (!this.activeColors) return [];

        let rowSize = this.isMobile() ? 3 : 5;
        let colors = this.activeColors.filter(function (c) {
            return c.isWoodGrained;
        });
        let nonWgColors = this.activeColors.filter(function (c) {
            return !c.isWoodGrained;
        });
        let numRows = ((nonWgColors.length / rowSize) | 0) + 1;
        if (colors.length < numRows) {
            let empty = new DoorColor;
            for (var i = 0; i < numRows - colors.length; i++) {
                colors.push(empty);
            }
        }
        return colors;
    }
    getActiveNonWoodGrainedColors() {
        if (!this.activeColors) return [];

        let rowSize = this.isMobile() ? 3 : 5;
        let colors = this.activeColors.filter(function (c) {
            return !c.isWoodGrained;
        });
        let extras = colors.length % rowSize;
        if (extras != 0) {
            let empty = new DoorColor;
            for (var i = 0; i < rowSize - extras; i++) {
                colors.push(empty);
            }
        }
        return colors;
    }
    getColorCodeDisplay(color: DoorColor): string {
        let displayName = '';
        if (color.code) displayName += ' (' + color.code + ')';
        if (color.footnote) {
            for (var i = 1; i < this.colorFootnotes.length + 1; i++) {
                if (this.colorFootnotes[i-1]['text'] == color.footnote) {
                    displayName += '<sup>' + this.colorFootnotes[i - 1]['symbol'] + '</sup>'
                }
            }
        }
        return displayName;
    }

    getActiveCombinedInsulationOptions() {
        if (this.selectedDoorDesign) return this.selectedDoorDesign.getInsulationOptions(null, null);
        return this.combinedInsulationOptions;
    }

    getActiveAllInsulationOptions() {
        if (this.selectedDoorDesign) return this.selectedDoorDesign.getInsulationOptions(null, null);
        return this.allInsulationOptions;
        //let options = this.allInsulationOptions;
        //if (this.selectedDoorDesign) options = this.selectedDoorDesign.insulationOptions;

        //let outOptions = [];
        //for (var i = options.length - 1; i > 0; i--) {
        //    let currentOption = options[i];
        //    let priorOption = options[i - 1];
        //    if (currentOption.insulationLevel._id === priorOption.insulationLevel._id) {
        //        priorOption.name += ' / ' + currentOption.name;
        //        continue;
        //    }
        //    outOptions.push(currentOption);
        //}
        //outOptions.push(options[0]);
        //return outOptions.reverse();
    }

    getColorStyle(index) {
        let rowSize = 5;
        let isMobile = this.isMobile();
        if (isMobile) rowSize = 3;
        // 5 colors per row - bitwise OR with zero rounds any fractions down
        let row = ((index / rowSize) | 0) + 1;
        let column = (index % rowSize) + 1;
        let style = {
            'grid-row': row,
            'grid-column': column,
            'padding': '1vw 1vh;'
        };
        if (!isMobile) {
            if ((index + 1) % rowSize === 0) {
                style['border-right'] = '1px solid gainsboro';
            }
        }
        return style;
    }

    getNonWoodGrainedFootnoteStyle() {
        let isMobile = this.isMobile();
        let rowSize = isMobile ? 3 : 5;
        let style = {
            'grid-column': '1 / span ' + rowSize,
            'text-align': 'left',
        };
        if (this.isMobile()) {
            let maxRow = this.getMaxColorRow(this.getActiveNonWoodGrainedColors(), rowSize);
            style['row'] = maxRow + 1;
            style['border-bottom'] = '1px solid gainsboro';

        } else {
            style['border-right'] = '1px solid gainsboro';
        }

        return style;
    }

    getWoodGrainedFootnoteStyle() {
        let isMobile = this.isMobile();
        let rowSize = isMobile ? 3 : 5;
        let style = {
            'text-align': 'left',
            'padding-bottom': '2vh',
            'padding-left': '2vw',
            'padding-right': '2vw'
        };
        if (this.isMobile()) {
            style['grid-column'] = '1 / span ' + rowSize;
            let maxNonWoodGrainedRow = this.getMaxColorRow(this.getActiveNonWoodGrainedColors(), rowSize);
            let maxWoodGrainedRow = this.getMaxColorRow(this.getActiveNonWoodGrainedColors(), rowSize);
            style['row'] = maxNonWoodGrainedRow + maxWoodGrainedRow + 2;
            style['border-bottom'] = '1px solid gainsboro';
        } else {
            style['grid-column'] = '6';
            style['border-right'] = '1px solid gainsboro';
            style['border-left'] = '1px solid gainsboro'; 
        }
        return style;
    }

    getColorWheelStyle() {
        let isMobile = this.isMobile();
        let rowSize = isMobile ? 3 : 5;
        let style = {};
        if (this.isMobile()) {
            style['grid-column'] = '1 / span ' + rowSize;
            let maxNonWoodGrainedRow = this.getMaxColorRow(this.getActiveNonWoodGrainedColors(), rowSize);
            let maxWoodGrainedRow = this.getMaxColorRow(this.getActiveNonWoodGrainedColors(), rowSize);
            style['grid-row'] = maxNonWoodGrainedRow + maxWoodGrainedRow + 3;
            return style;
        } else {
            return {
                'grid-row': '1/-1',
                'grid-column': '7'
            };
        }
    }

    getMaxColorRow(colors: DoorColor[], rowSize: number) {
        if (colors) {
            return Math.ceil(colors.length / rowSize);
        }
        return 0;
    }

    getWoodGrainedColorStyle(index) {
        if (this.isMobile()) {
            let rowSize = 3;
            let maxColorRow = this.getMaxColorRow(this.getActiveNonWoodGrainedColors(), rowSize);
            let row = ((index / rowSize) | 0) + maxColorRow + 1 + 1; // 1 extra to put beneath other colors, 1 more for color footnotes
            let column = (index % rowSize) + 1;
            let style = {
                'grid-row': row,
                'grid-column': column
            };
            if (Math.ceil(index / rowSize) == this.getMaxColorRow(this.getActiveNonWoodGrainedColors(), rowSize)) {
                style['border-bottom'] = '1px solid gainsboro';
            }
            return style;
        }
        return {
            'grid-row': index + 1,
            'grid-column': 6,
            'border-right': '1px solid gainsboro',
            'padding-left': '20px',
            'padding-right': '20px'
        };
    }

    getColorContainerStyle() {
        let style = {};

        let rowSize = this.isMobile() ? 3 : 5;
       let rowAutos = '';
        let colors = this.getActiveColors();
        if (colors && colors.length > 0) {
            let numRows = (colors.length / rowSize);
            if (colors.length % rowSize !== 0)
                numRows++;
            let percent = (100 / numRows);
            for (var i = 0; i < numRows; i++) {
                    rowAutos += 'auto ';
            }
            rowAutos += 'auto auto'; // two extra rows for the text underneath the colors
        }
        style['grid-template-rows'] = rowAutos;

        return style;
    }

    getConstructionContainerStyle() {
        let style = {
            'display': 'grid',
            'background-color': '#ececec'
        };
        let activeInsulations = this.getActiveCombinedInsulationOptions();

        if (this.isMobile()) {
            let gridRows = "auto";
            if (activeInsulations && activeInsulations.length > 0) {
                for (var i = 1; i < activeInsulations.length; i++) {
                    gridRows += ' auto';
                }
            }
            style['grid-auto-rows'] = gridRows;
        } else {
            let gridPercentages = '100%';
            if (activeInsulations && activeInsulations.length > 0) {
                let percent = (1 / activeInsulations.length) * 100;
                gridPercentages = '';
                for (var i = 0; i < activeInsulations.length; i++) {
                    if (i == 0)
                        gridPercentages += percent + '%';
                    else
                        gridPercentages += ' ' + percent + '%';
                }
            }
            style['grid-template-columns'] = gridPercentages;
        }
        return style;
    }

    getSpecificationsTable() {
        let tableEntries = {};
        for (var i = 0; i < this.getActiveAllInsulationOptions().length; i++) {
            let currentIO = this.getActiveAllInsulationOptions()[i];
            for (var specIdx = 0; specIdx < currentIO.specifications.length; specIdx++) {
                let currentSpec = currentIO.specifications[specIdx];
                let rowName = this.checkForFootnote(currentSpec);
               
                if (!tableEntries[rowName]) {
                    tableEntries[rowName] = {
                        values: [],
                        class: 'cellGroupTitle'
                    };
                }
                if (!currentSpec.exists) {
                    tableEntries[rowName].values.push('');
                } else if (currentSpec.value) {
                    tableEntries[rowName].values.push(currentSpec.value);
                } else if (currentSpec.values && currentSpec.values.length > 0) {
                    let isNewField = true;
                    let concatVals = '';
                    let subIdx = 0;
                    // TODO -- make this recursive?
                    for (var subSpec in currentSpec.values) {
                        let currentSubSpec = currentSpec.values[subSpec];
                        let subSpecName = this.checkForFootnote(currentSubSpec);
                        if (currentSubSpec.field) {
                            if (!tableEntries[subSpecName]) {
                                tableEntries[subSpecName] = {
                                    values: [],
                                    class: 'cellSubgroupTitle'
                                };
                            }
                            if (!currentSubSpec.exists) {
                                tableEntries[subSpecName].values.push('');
                            } else if (currentSubSpec.value) {
                                tableEntries[subSpecName].values.push(currentSubSpec.value);
                            } else {
                                tableEntries[subSpecName].values.push('\u2022');
                            }
                        } else {
                            // just a list of values, not new sub fields
                            isNewField = false;
                            concatVals += this.checkForFootnote(currentSubSpec);
                            subIdx++;
                            if (subIdx !== currentSpec.values.length) {
                                concatVals += ' - ';
                            }
                        }
                    }
                    if (isNewField) {
                        tableEntries[rowName].values.push('');
                    } else {
                        tableEntries[rowName].values.push(concatVals);
                    }
                } else {
                    // add a bullet
                    tableEntries[rowName].values.push('\u2022');
                }
            }
        }
        let table = Object.entries(tableEntries).map(([k, v]) => {
            return { name: k, values: v['values'], class: v['class'] }
        });
        return table;
    }

    checkForFootnote(currentSpec: InsulationSpecification): string {
        let rowName = currentSpec.field;
        let footnoteIdx = -1;
        if (!rowName) rowName = currentSpec.value;
        if (currentSpec.footnote) {
            for (var idx = 0; idx < this.specificationFootnotes.length; idx++) {
                if (this.specificationFootnotes[idx] === currentSpec.footnote) {
                    footnoteIdx = idx + 1;
                    break;
                }
            }
            if (footnoteIdx > 0) {
                return rowName += '<sup>' + footnoteIdx + '</sup>';
            } else {
                this.specificationFootnotes.push(currentSpec.footnote);
                return rowName += '<sup>' + this.specificationFootnotes.length + '</sup>';
            }
        }
        return rowName;
    }

    getFootnoteDisplayText(footnote: string, index: number) {
        let idx = index + 1;
        return '<sup>' + idx + '</sup>' + footnote;
    }

    onClickDoorDesign(dd) {
        this.productImageURL = dd.galleryImageURL;
        //this.dbService.customerDesignedDoor.doorDesign = dd;
        //query.insulationOptions = this.dbService.customerDesignedDoor.doorDesign.insulationOptions;
        //this.dbService.hasInsulationPricesLoaded = false;
        //this.productService.getInsulationOptionPrices(query).subscribe(result => {
        //    if (result) {
        //        if (result['insulationOptions']) {
        //            this.dbService.customerDesignedDoor.doorDesign.insulationOptions = result['insulationOptions'];
        //            this.dbService.hasInsulationPricesLoaded = true;
        //        }
        //    }
        //});
    }

    onCustomizeAndPrice(pg: ProductGroup) {
        this.dbService.customerDesignedDoor = new CustomerDesignedDoor();
        this.dbService.selectedStyle = pg.style;
        this.dbService.customerDesignedDoor.productGroup = pg;
        this.router.navigate(['/door-builder-home']);
    }

    downloadLink(pg) {
        window.open(pg.brochureURL);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
    }

    isMobile() {
        if (!this.scrWidth) this.getScreenSize();
        return this.scrWidth <= 1224
    }
}