﻿import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartComponent } from './cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SummaryComponent } from './summary/summary.component';
import { ThankYouComponent } from './thank-you/thank-you.component';

const routes: Routes = [
    {
        path: 'cart', component: CartComponent, data: { breadcrumb: 'Shopping Cart' }, children: [
            { path: '', component: SummaryComponent, data: { breadcrumb: 'Shopping Cart Summary' } },
            { path: 'checkout', component: CheckoutComponent, data: { breadcrumb: 'Checkout' } },
            { path: 'successful-order/:total', component: ThankYouComponent, data: { breadcrumb: 'Success' } },
            //{ path: 'quote/:ids', component: CartComponent, data: {breadcrumb: 'Shopping Cart'} }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class CartRoutingModule { }