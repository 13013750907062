﻿import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GDSHCartService } from '../gdsh-cart.service';
import { CustomerDesignedDoor } from 'src/app/shared/models';
import { LocaleFormat, parseLocaleFormat, CartViewDisplay } from 'ng-shopping-cart';
import { BuildADoorService, OrderService, ModalService } from 'src/app/shared/services';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Discount } from 'src/app/shared/models/discount';
import { FormControl, FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
    selector: 'app-cart-view',
    templateUrl: './cart-view-combined.component.html',
    styleUrls: ['./cart-view.component.css']
})
export class GDSHCartViewComponent implements OnInit, OnChanges, OnDestroy {
    private _serviceSubscription: any;
    private isUserLoggedIn = false;
    private isUserAdmin = false;
    private allSummaryItems: Map<CustomerDesignedDoor, any[]> = new Map <CustomerDesignedDoor, any[]>();
    /**
     * Changes the appearance how the cart view displays in different screen sizes
     */
    @Input() display: CartViewDisplay = 'responsive-table';
    /**
     * Whether to include images in the cart or not.
     */
    @Input() images = true;
    /**
     * The text to show when the cart has no items in it.
     */
    @Input() emptyText = 'Your cart is empty';
    /**
     * When set to `true` and the cart is empty displays the projected content of the component as the empty content.
     */
    @Input() customEmptyContent = false;
    /**
     * The text to display in the header of the name column.
     */
    @Input() nameHeaderText = 'Name';
    /**
     * The text to display in the header of the quantity column.
     */
    @Input() quantityHeaderText = 'Quantity';
    /**
     * The text to display in the header of the price column.
     */
    @Input() priceHeaderText = 'Price';
    /**
     * The text to display in the header of the total per item column.
     */
    @Input() totalHeaderText = 'Total';
    /**
     * The text to display in the tax section of the footer.
     */
    @Input() taxFooterText = 'Tax';
    /**
     * The text to display in the shipping section of the footer.
     */
    @Input() shippingFooterText = 'Shipping';
    /**
     * The text to display in the total section of the footer.
     */
    @Input() totalFooterText = 'Total';
    /**
     * Changes currency display format for the component. Overrides the value set from the service using `setCurrencyFormat`.
     */
    @Input() localeFormat: string;
    format: LocaleFormat;
    empty = true;
    items: CustomerDesignedDoor[];
    taxRate = 0;
    tax = 0;
    shipping = 0;
    cost = 0;
    totalTaxes = 0;
    totalDelivery = 0;
    totalPrice = 0;
    couponCode: string;
    oldCode: string;
    validCouponCode: Discount;
    sendQuoteForm: FormGroup;
    email = new FormControl(null, [Validators.required, Validators.email]);
    isSendQuoteDisabled = false;

    constructor(private cartService: GDSHCartService<any>,
        public router: Router,
        public dbService: BuildADoorService,
        public orderService: OrderService,
        private formBuilder: FormBuilder,
        private modalService: ModalService,
        private userService: UserService,
        private activeRoute: ActivatedRoute) {
    }

    async update() {
        this.empty = this.cartService.isEmpty();
        this.items = this.cartService.getItems();
        //this.items = await this.dbService.reloadDoors(this.items);
        this.taxRate = this.cartService.getTaxRate() / 100;
        this.tax = this.cartService.getTax();
        this.shipping = this.cartService.getShipping();
        this.cost = this.cartService.totalCost() - this.tax;
        if (!this.localeFormat) {
            this.format = <LocaleFormat>this.cartService.getLocaleFormat(true);
        }
    }

    getDeliveryCharge() {
        let totalQuantity = 0;
        this.totalDelivery = 350.00;
        for (var i = 0; i < this.items.length; i++) {
            totalQuantity += this.items[i].quantity;
        }
        this.totalDelivery = this.dbService.getShippingCost(totalQuantity);
        return this.totalDelivery.toFixed(2);
        //if (totalQuantity > 2) {
        //    this.totalDelivery = 310.00 + (100.00 * (totalQuantity - 2));
        //}
        //if (totalQuantity < 1) {
        //    this.totalDelivery = 0;
        //}
        //return this.totalDelivery.toFixed(2);
    }

    getTaxAmount(): string {
        this.totalTaxes = this.dbService.getTotalTaxes(this.items);
        if (this.totalTaxes) return this.totalTaxes.toFixed(2);
        return new Number(0.00).toFixed(2);
    }

    getTotalPrice(): string {
        let price = this.cost + this.totalDelivery + this.totalTaxes;
        price = price - Math.abs(this.getDiscountAmount());
        //for (var i = 0; i < this.items.length; i++) {
        //    let discount = this.dbService.getDiscountAmount(this.items[i]);
        //    price = price - Math.abs(discount);
        //}
        return price.toFixed(2);
    }

    increase(item: CustomerDesignedDoor) {
        item.setQuantity(item.getQuantity() + 1);
        this.cartService.addItem(item);
    }

    decrease(item: CustomerDesignedDoor) {
        if (item.getQuantity() > 1) {
            item.setQuantity(item.getQuantity() - 1);
            this.cartService.addItem(item);
        } else {
            this.cartService.removeItem(item.getId());
            this.totalDelivery = 0;
        }
    }

    async ngOnInit() {
        let doorIds = this.activeRoute.snapshot.queryParams['ids'];
        if (doorIds) {
            // clear cart and reload
            this.items = await this.dbService.loadDoorsById(doorIds);
            this.cartService.clear();
            for (var i = 0; i < this.items.length; i++) {
                let door = new CustomerDesignedDoor(this.items[i]);
                this.cartService.addItem(door);
                this.dbService.applySpecials(door);
                this.dbService.selectedStyle = door.productGroup.style;
            }
        }
        this.userService.isLoggedIn().subscribe(b => this.isUserLoggedIn = b);
        this.userService.isAdmin().subscribe(b => this.isUserAdmin = b);
        this.sendQuoteForm = this.formBuilder.group({
            email: this.email
        });
        this.update();
        this._serviceSubscription = this.cartService.onChange.subscribe(() => {
            this.update();
        });
        this.initializeSummaryItems();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['localeFormat']) {
            this.format = this.localeFormat ?
                parseLocaleFormat(this.localeFormat) :
                <LocaleFormat>this.cartService.getLocaleFormat(true);
        }
    }

    ngOnDestroy(): void {
        this._serviceSubscription.unsubscribe();
    }

    onBuildAnotherDoorClick() {
        this.dbService.startOver();
        this.router.navigate(['/door-builder-home']);
    }

    editItem(item: CustomerDesignedDoor) {
        this.dbService.updateCartItem(item);
        this.router.navigate(['/door-builder/setup/select-size']);
    }

    onCouponCodeChange(cc) {
        if (!this.validCouponCode) {
            this.couponCode = cc;
            if (this.couponCode.length === 8 && this.couponCode.match(/^[0-9a-zA-Z]+$/)) {
                this.orderService.getCouponCode(this.couponCode).subscribe(isCodeValid => {
                    if (isCodeValid && isCodeValid['couponCode']) {
                        let discount = new Discount(isCodeValid['couponCode']);
                        this.validCouponCode = discount;
                        if (discount.percent) {
                            for (var i = 0; i < this.items.length; i++) {
                                let codeExists = this.items[i].discount && this.items[i].discount.filter(d => d.code === this.couponCode);
                                if (!codeExists || codeExists.length === 0) {
                                    this.items[i].addDiscount(discount);
                                }
                            }
                        } else if (discount.amount) {
                            if (this.items && this.items.length > 0) {
                                let codeExists = this.items[0].discount && this.items[0].discount.filter(d => d.code === this.couponCode);
                                if (!codeExists || codeExists.length === 0) this.items[0].addDiscount(discount);
                            }
                        }
                        this.update();
                    } else {
                        this.validCouponCode = null;
                        this.removeCouponCode(this.oldCode);
                    }
                });
            } else {
                this.validCouponCode = null;
                this.removeCouponCode(this.oldCode);
            }
        } else {
            if (this.couponCode.length !== 8 || !this.couponCode.match(/^[0-9a-zA-Z]+$/)) {
                this.validCouponCode = null;
                if (this.couponCode) this.removeCouponCode(this.oldCode);
            }
        }
        this.oldCode = this.couponCode;
    }

    getItemName(item: CustomerDesignedDoor) {
        let name = null;
        if (item.insulation) name = item.insulation.name;
        else if (item.productGroup) name = item.productGroup.display_name;
        if (item.doorDesign) name += ' - ' + item.doorDesign.name;
        return name;
    }
    initializeSummaryItems() {
        for (var i = 0; i < this.items.length; i++) {
            this.allSummaryItems.set(this.items[i], this.dbService.getSummaryItems(this.items[i], false));
        }
    }

    getSummaryItems(item: CustomerDesignedDoor) {
        return this.allSummaryItems.get(item);
    }

    getDescriptionBullets(item: CustomerDesignedDoor) {
        let summaryItems = this.dbService.getSummaryItems(item, false);
        return summaryItems;
        //let desc = [];
        //if (item.width && item.height) {
        //    let sizeDesc = '';
        //    sizeDesc += item.width.feet + '\'';
        //    if (item.width.inches) sizeDesc += item.width.inches + '\"';
        //    sizeDesc += ' x ';
        //    sizeDesc += item.height.feet + '\'';
        //    if (item.height.inches) sizeDesc += item.height.inches + '\"';
        //    desc.push(sizeDesc);
        //}
        //if (item.windSpeed) {
        //    let wsDesc = '';
        //    wsDesc += item.windSpeed + ' MPH';
        //    if (item.impactType) wsDesc += ' ' + item.impactType;
        //    desc.push(wsDesc);
        //}
        //if (item.color) desc.push(item.color.name);
        //if (item.window) {
        //    let winDesc = '';
        //    winDesc += item.window.name;
        //    if (item.glassType) {
        //        winDesc += ' ';
        //        winDesc += item.glassType.name;
        //    } else if (item.window.isInsulated) {
        //        winDesc += ' Insulated';
        //    }
        //    desc.push(winDesc);
        //}
        //if (item.headroom) desc.push(item.headroom.name);
        //if (item.trackAndSpring) desc.push(item.trackAndSpring.name);
        //if (item.springWindingOption) desc.push(item.springWindingOption.name);
        //if (item.hardware && item.hardware.length > 0) desc.push(this.addCollectionItems('Hardware', item.hardware));
        //if (item.extras && item.extras.length > 0) desc.push(this.addCollectionItems('Extras', item.extras));
        //if (item.opener) desc.push(item.opener.name);
        //if (item.locks && item.locks.length > 0) desc.push(this.addCollectionItems('Locks', item.locks));
        //return desc;
    }

    private addCollectionItems(itemLabel, itemCollection): string {
        if (itemCollection && itemCollection.length > 0) {
            let collectionItems = itemLabel + ': ';
            for (var i = 0; i < itemCollection.length; i++) {
                if (i > 0) collectionItems += ', ';
                collectionItems += itemCollection[i].quantity ? '' + itemCollection[i].quantity + ' ' + itemCollection[i].name : itemCollection[i].name;
            }
            return collectionItems;
        }
        return '';
    }

    removeCouponCode(code: string) {
        for (var i = 0; i < this.items.length; i++) {
            this.items[i].discount = this.items[i].discount.filter(d => d.code !== code);
        }
    }

    getDiscountAmount() {
        let discountAmount = 0;
        let totalAvailableForDiscount = this.cartService.totalCost() - this.tax;
        if (this.validCouponCode) {
            if (this.validCouponCode.percent) return (totalAvailableForDiscount * (this.validCouponCode.percent / 100.00));
            let totalQuantity = 0;
            for (var i = 0; i < this.items.length; i++) {
                totalQuantity += this.items[i].quantity;
            }
            return totalQuantity * this.validCouponCode.amount;
        }
        return discountAmount;
    }

    getDiscountDisplay() {
        return this.getDiscountAmount().toFixed(2);
    }

    getShipping(items) {
        let quan = 0;
        if (items && items.length) {
            for (var i = 0; i < items.length; i++) {
                quan += items[i].quantity;
            }
        }
        return this.dbService.getShippingCost(quan);
        //if (quan > 0) {
        //    if (quan > 2) return 310.00 + (100.00 * (quan - 2))
        //    return 310.00
        //}
        //return 0.00
    }

    onSendQuote() {
        this.isSendQuoteDisabled = true;
        let quoteSummaryItems = [];
        this.items = this.cartService.getItems();
        let shippingAmount = this.getShipping(this.items);
        for (var i = 0; i < this.items.length; i++) {
            let summaryItems = this.dbService.getSummaryItems(this.items[i], true);
            quoteSummaryItems.push(summaryItems);
        }
        var quoteData = {
            amount: this.getTotalPrice(),
            tax: this.totalTaxes,
            shipping: shippingAmount,
            customer: {
                contact: {
                    emailAddress: this.email.value,
                },
            },
            customerDesignedDoors: this.items,
            emailAddress: this.email.value,
            summaryItems: quoteSummaryItems,
        }
        this.orderService.sendQuote(quoteData).subscribe(result => {
            if (result && result['contact']) {
                // popup modal saying thanks
                this.showThankYouWindow();
            }
            this.isSendQuoteDisabled = false;
        }, err => {
            this.isSendQuoteDisabled = false;
        });
    }

    showThankYouWindow() {
        this.modalService.open('cart-thank-you');
    }

    hideThankYouWindow() {
        this.modalService.close('cart-thank-you');
    }

    isAdmin() {
        return this.isUserLoggedIn && this.isUserAdmin;
    }

    getPromotionDiscountDisplay(d: CustomerDesignedDoor) {
        return '$' + this.dbService.getPriceDiscountDisplay(d);
    }

    getAppliedPrice(d: CustomerDesignedDoor) {
        //if (this.dbService.getAppliedPromotion(d))
            return this.dbService.getAppliedPriceDisplay(d);
        //return this.getTotalPrice();
    }
}
