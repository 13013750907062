﻿import { Component } from '@angular/core';
import { BuildADoorService, DBFooterService, ModalService, ProductService } from 'src/app/shared/services';
import { Router } from '@angular/router';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { Headroom, TrackAndSpring, TrackMountingOption, SpringWindingOption } from 'src/app/shared/models';
@Component({
    selector: 'app-db-headroom',
    templateUrl: './db-headroom.component.html',
    styleUrls: ['./db-headroom.component.css'],
})
export class DBHeadroomComponent extends DBStateComponent {
    private headrooms: Headroom[] = [];
    private tracksAndSprings: TrackAndSpring[] = [];
    private allTracksAndSprings: Object = {};
    private allTrackMountingOptions: TrackMountingOption[] = [];
    private allSpringWindingOptions: SpringWindingOption[] = [];
    private isScrollToBottom: boolean = false;
    //private springs: string[] = ['12" radius track with torsion spring', '15" radius track with extension spring'];
    private devices: SpringWindingOption[] = [];
    private buttonClasses: Object = {
        less: 'Button-Label-Div',
        between: 'Button-Label-Div',
        more: 'Button-Label-Div'
    };
    private wrapperStyling: Object;

    constructor(public dbService: BuildADoorService, public dbFooterService: DBFooterService, public router: Router, private modalService: ModalService, private productService: ProductService) {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        if (!this.dbService.customerDesignedDoor.productGroup || !this.dbService.customerDesignedDoor.productGroup.style ||
            !this.dbService.customerDesignedDoor.insulation || !this.dbService.customerDesignedDoor.insulation.insulationLevel ||
            !this.dbService.customerDesignedDoor.width || !this.dbService.customerDesignedDoor.width.feet ||
            !this.dbService.customerDesignedDoor.height || !this.dbService.customerDesignedDoor.height.feet) {
            this.router.navigate(['/door-builder/design/design-home']);
        }

        this.initHeadrooms();
        this.dbFooterService.setParentState();
        this.dbFooterService.completeState(this.dbFooterService.DESIGN_STATE);
        this.dbFooterService.updateParentState(this.dbFooterService.DESIGN_POSITION);
        this.dbFooterService.updateNumComplete();
    }

    ngAfterViewChecked() {
        if (this.isScrollToBottom) super.scrollToDiv('continueButtonDiv', true);
    }

    initHeadrooms() {
        if (!this.headrooms || this.headrooms.length === 0) {
            this.productService.getHeadrooms(this.dbService.customerDesignedDoor).subscribe(result => {
                if (result) {
                    if (result['headrooms']) {
                        this.headrooms = [];
                        for (var i = 0; i < result['headrooms'].length; i++) {
                            this.headrooms.push(new Headroom(result['headrooms'][i]));
                        }
                    }
                    if (result['tracksAndSprings']) {
                        for (var key in result['tracksAndSprings']) {
                            this.allTracksAndSprings[key] = [];
                            for (var i = 0; i < result['tracksAndSprings'][key].length; i++) {
                                if (!this.dbService.opener || !this.dbService.opener.noLowHeadroom || !result['tracksAndSprings'][key][i]['isLowHeadroom'])
                                    this.allTracksAndSprings[key].push(new TrackAndSpring(result['tracksAndSprings'][key][i]));
                            }
                        }
                    }
                    if (result['trackMountingOptions']) {
                        this.allTrackMountingOptions = [];
                        for (var i = 0; i < result['trackMountingOptions'].length; i++) {
                            this.allTrackMountingOptions.push(new TrackMountingOption(result['trackMountingOptions'][i]));
                        }
                    }
                    if (result['springWindingOptions']) {
                        this.allSpringWindingOptions = [];
                        for (var i = 0; i < result['springWindingOptions'].length; i++) {
                            this.allSpringWindingOptions.push(new SpringWindingOption(result['springWindingOptions'][i]));
                        }
                    }

                    this.setInitButtons();
                }
            });
        } else {
            this.setInitButtons();
        }
    }

    setInitButtons() {
        if (this.dbService.customerDesignedDoor.headroom) {
            let hr = this.headrooms.find(h => h._id === this.dbService.customerDesignedDoor.headroom._id);
            this.tracksAndSprings = this.allTracksAndSprings[hr._id];
            if (this.dbService.customerDesignedDoor.trackAndSpring) {
                this.setDevices(this.dbService.customerDesignedDoor.trackAndSpring);
            }
        }
    }

    nextState() {
        this.dbService.onNavMenuNextState();
    }

    getCollectionStyle(index) {
        let style = super.collectionStyle(index);
        delete style['grid-template-columns'];
        delete style['display'];
        delete style['line-height'];
        if (!this.isMobile()) {
            var colNum = 1 + (index % 2);
            style['grid-column'] = '' + colNum;
        }
        return style;
    }

    getTracksAndSpringsCollectionStyle(index, tas) {
        let style = this.getCollectionStyle(index);
        style['display'] = 'grid';
        style['grid-auto-rows'] = 'minmax(min-content, max-content)';
        return style;
    }

    getHeadroomsStyling(index) {
        let style = {};
        let position = index + 1;
        if (this.isMobile()) {
            style['grid-row'] = '' + position;
        } else {
            style['grid-column'] = '' + position;
        }
    }

    getWrapperStyling() {
        if (this.isMobile()) {
            this.wrapperStyling = {};
            this.wrapperStyling['grid-template-rows'] = 'minmax(min-content, max-content)';
        } else {
            this.setCollectionWrapperStyling(this.tracksAndSprings);
            this.wrapperStyling = this.collectionWrapperStyling;
            var gridStyling = '';
            if (this.tracksAndSprings && this.tracksAndSprings.length > 0) {
                var numRows = this.tracksAndSprings.length / 2 + this.tracksAndSprings.length % 2;
                for (var i = 0; i < numRows; i++) {
                    if (i != numRows - 1)
                        gridStyling += 'auto ';
                }
                gridStyling += 'auto';
            }
            this.wrapperStyling['grid-template-rows'] = gridStyling;
            this.wrapperStyling['grid-template-columns'] = '50% 50%';
        }
        return this.wrapperStyling;
    }

    buttonClick(hr) {
        if (hr !== this.dbService.customerDesignedDoor.headroom) {
            this.dbService.customerDesignedDoor.headroom = hr;
            this.tracksAndSprings = this.allTracksAndSprings[hr._id];
            this.tracksAndSprings = super.sortCollectionByDisplayOrder(this.tracksAndSprings);
            this.dbService.customerDesignedDoor.trackAndSpring = null;
            this.dbService.customerDesignedDoor.springWindingOption = null;
        }
        this.isScrollToBottom = false;
    }

    springButtonClick(s) {
        if (s !== this.dbService.customerDesignedDoor.trackAndSpring) {
            this.dbService.customerDesignedDoor.trackAndSpring = s;
            this.dbService.customerDesignedDoor.springWindingOption = null;
            this.setDevices(s);
            if (this.dbService.customerDesignedDoor.opener) {
                if ((this.dbService.customerDesignedDoor.trackAndSpring.isExtensionSpring && this.dbService.customerDesignedDoor.opener.noExtensionSprings) ||
                    (this.dbService.customerDesignedDoor.trackAndSpring.isHighLift && this.dbService.customerDesignedDoor.opener.noHighLift) ||
                    (this.dbService.customerDesignedDoor.trackAndSpring.isLowHeadroom && this.dbService.customerDesignedDoor.opener.noLowHeadroom) ||
                    (this.dbService.customerDesignedDoor.trackAndSpring.isRoofPitch && this.dbService.customerDesignedDoor.opener.noRoofPitch) ||
                    (this.dbService.customerDesignedDoor.trackAndSpring.isVerticalLift && this.dbService.customerDesignedDoor.opener.noVerticalLift)) {
                    this.dbService.customerDesignedDoor.opener = null;
                    this.productService.getDoorExtras(this.dbService.customerDesignedDoor).subscribe(result => {
                        if (result) {
                            if (result['doorExtras']) {
                                this.dbService.possibleExtras = result['doorExtras'];
                                this.dbService.possibleExtras['extras'].forEach(pe => {
                                    pe.quantity = 0;
                                });
                                this.dbService.extras = this.dbService.possibleExtras['extras'];
                                if (this.dbService.possibleExtras['openers']) {
                                    this.dbService.extras = this.dbService.extras.concat(this.dbService.possibleExtras['openers'].filter(ex => ex.isOperator === false));
                                }
                            }
                        }
                    });
                }
            }
        }
        //super.scrollToDiv('springWindingToolsDiv', true);
        //var scrollingElement = (document.scrollingElement || document.body);
        ////scrollingElement.scrollTop = scrollingElement.scrollHeight - scrollingElement.clientHeight;
        //window.scrollTo(0, document.querySelector('.Headroom-Screen-Container').scrollHeight);
    }

    getSpringWindingStyle(spring: TrackAndSpring) {
        if (this.dbService.customerDesignedDoor.trackAndSpring && this.dbService.customerDesignedDoor.trackAndSpring._id === spring._id) {
            return { 'visibility': 'visible' };
        }
        return { 'visibility': 'hidden' };
    }

    setDevices(s) {
        this.devices = [];
        if (!s.isExtensionSpring) {
            let height = this.dbService.customerDesignedDoor.height.feet;
            if (this.dbService.customerDesignedDoor.height.inches) height++;
            for (var i = 0; i < this.allSpringWindingOptions.length; i++) {
                if (this.allSpringWindingOptions[i].maxHeight && this.allSpringWindingOptions[i].maxHeight < height) continue;
                this.devices.push(this.allSpringWindingOptions[i]);
                this.isScrollToBottom = true;
            }
        }
    }

    deviceButtonClick(d) {
        if (this.dbService.customerDesignedDoor.springWindingOption === d) {
            this.dbService.customerDesignedDoor.springWindingOption = null;
            this.isScrollToBottom = false;
        } else {
            this.dbService.customerDesignedDoor.springWindingOption = d;
            this.isScrollToBottom = true;
        }
    }

    tmoButtonClick(t) {
        this.dbService.customerDesignedDoor.trackMountingOption = t;
        this.isScrollToBottom = false;
    }

    showSizingInfoWindow() {
        this.modalService.open('sizing-info');
    }

    hideSizingInfoWindow() {
        this.modalService.close('sizing-info');
    }

    showNotSureWindow() {
        this.modalService.open('not-sure');
    }

    hideNotSureWindow() {
        this.modalService.close('not-sure');
    }

    buttonClass(hr, matchAgainst, isRemoveVerticalCenter?) {
        let bClass: string = 'Collection-Label-Div';
        if (!isRemoveVerticalCenter) bClass += ' Collection-Label-Centered';
        if (hr && matchAgainst && hr._id === matchAgainst._id) bClass += ' Collection-Label-Div-Selected';
        return bClass;
    }

    getTotalPrice() {
        return this.dbService.getTotalPriceDisplay();
    }

    getTotalPriceDisplay() {
        if (this.dbService.getAppliedPromotion()) {
            return '$' + this.dbService.getTotalPriceDisplay();
        }
        return '';
    }

    getAppliedPrice() {
        if (this.dbService.getAppliedPromotion())
            return this.dbService.getAppliedPriceDisplay();
        return this.getTotalPrice();
    }

    isContinueButtonDisabled() {
        if (this.dbService.customerDesignedDoor.headroom && this.dbService.customerDesignedDoor.trackAndSpring && this.dbService.customerDesignedDoor.trackMountingOption) {
            this.dbFooterService.message = 'Click Continue to Select Hardware and Extras';
            return false;
            //return { 'visibility': 'visible' };
        }
        //return { 'visibility': 'hidden' };
        return true;
    }

}