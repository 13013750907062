﻿import { ProductGroup } from './product-group';

export class DoorExtra {
    _id: string;
    name: string;
    imageURL: string;
    price: number;
    isAutoSelect: boolean;
    description: string;
    details: string[];
    productGroup: ProductGroup;
    isHidden: boolean;
    canChangeQuantity: boolean;
    quantity: number = 1;

    constructor(e?) {
        if (e) {
            this._id = e._id;
            this.name = e.name;
            this.imageURL = e.imageURL;
            this.price = e.price;
            this.isAutoSelect = e.isAutoSelect;
            this.description = e.description;
            this.details = e.details;
            this.productGroup = e.productGroup;
            this.isHidden = e.isHidden;
            this.canChangeQuantity = e.canChangeQuantity;
            this.quantity = 0;
        }
    }


}
