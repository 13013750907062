﻿import { Component } from '@angular/core';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { DoorLock } from 'src/app/shared/models';

@Component({
    selector: 'app-locks',
    templateUrl: './locks.component.html',
    styleUrls: ['./locks.component.css'],
})
export class DBLocksComponent extends DBStateComponent {
    private locks: DoorLock[];

    async ngOnInit() {
        if (!this.dbService.customerDesignedDoor.doorDesign) {
            this.router.navigate(['/door-builder/design/design-home']);
        }

        this.dbService.hasViewedLocks = true;
        this.locks = this.dbService.customerDesignedDoor.doorDesign.lockOptions;
        this.setCollectionWrapperStyling(this.locks);
        super.ngOnInit();
    }

    getCollectionStyle(index) {
        let style = super.collectionStyle(index);
        style['grid-template-columns'] = '30% 70%';
        return style;
    }

    getCollectionClass(l) {
        if (!l || !this.dbService.customerDesignedDoor.locks || this.dbService.customerDesignedDoor.locks.length < 1) return 'Collection-Button-Unselected';
        for (var i = 0; i < this.dbService.customerDesignedDoor.locks.length; i++) {
            if (this.dbService.customerDesignedDoor.locks[i] === l) return 'Collection-Button-Selected';
        }
        return 'Collection-Button-Unselected';
    }

    onClickLock(l) {
        let found = this.dbService.customerDesignedDoor.locks.find(ce => ce._id === l._id);
        if (!found) {
            l.quantity = 1;
            this.dbService.customerDesignedDoor.locks.push(l);
        }
        else {
            this.dbService.customerDesignedDoor.locks = this.dbService.customerDesignedDoor.locks.filter(ce => ce._id !== l._id);
            found.quantity = 0;
        }
        //let originalLength = this.dbService.customerDesignedDoor.locks.length;
        //this.dbService.customerDesignedDoor.locks = this.dbService.customerDesignedDoor.locks.filter(function (value, index, arr) {
        //    return value != l;
        //});
        //if (originalLength === this.dbService.customerDesignedDoor.locks.length)
        //    this.dbService.customerDesignedDoor.locks.push(l);
    }


    getButtonClass() {
        if (this.dbService.customerDesignedDoor.locks && this.dbService.customerDesignedDoor.locks.length > 0) return 'Rectangle';
        return 'Changed-Mind-Button';
    }

    getButtonText() {
        if (this.dbService.customerDesignedDoor.locks && this.dbService.customerDesignedDoor.locks.length > 0)
            return 'Continue';
        return 'No Thanks';
    }

    getPriceDisplay(lock) {
        if (lock.widthBasedPricing && lock.widthBasedPricing.length > 0) {
            let potentialWidthBasedPrice = lock.widthBasedPricing.filter(wbp => (wbp.minWidth == null || wbp.minWidth <= this.dbService.customerDesignedDoor.width.feet) && (wbp.maxWidth == null || wbp.maxWidth >= this.dbService.customerDesignedDoor.width.feet));
            if (potentialWidthBasedPrice && potentialWidthBasedPrice.length === 1) {
                let priceToDisplay = lock.quantity && lock.quantity > 0 ? potentialWidthBasedPrice[0].price * lock.quantity : potentialWidthBasedPrice[0].price;
                return '$' + this.dbService.getPriceDisplayNumericOnly(priceToDisplay);
            }
        }
        if (lock && lock.price > 0) {
            let priceToDisplay = lock.quantity && lock.quantity > 0 ? lock.price * lock.quantity : lock.price;
            return '$' + this.dbService.getPriceDisplayNumericOnly(priceToDisplay);
        }
        return 'Price Included';
    }

    onClickContinue() {
        if (!this.dbService.hasViewedHardware) {
            if (this.dbService.customerDesignedDoor.doorDesign && this.dbService.customerDesignedDoor.doorDesign.hardwareOptions && this.dbService.customerDesignedDoor.doorDesign.hardwareOptions.length > 0) {
                this.router.navigate(['/door-builder/design/select-hardware']);
            } else {
                this.router.navigate(['/door-builder/design/select-extras']);
            }
        } else if (!this.dbService.hasViewedExtras) {
            this.router.navigate(['/door-builder/design/select-extras']);
        } else {
            this.router.navigate(['/door-builder/design/extras-home']);
        }
    }
}