import { Component, ElementRef, OnInit, HostListener, ViewChild } from '@angular/core';
//import { ShoppingCartModule } from 'ng-shopping-cart';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ProductService } from '../shared/services/product.service';
import { AppRoutingModule } from '../app-routing.module';
import { NavButton } from '../shared/models/ui-models/nav-button';
import { DoorStyle, BuildingZone, ProductGroup } from '../shared/models';
import { BuildADoorService } from '../shared/services';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css'],
    host: {
        '(document:click)': 'onClick($event)'
    }
})
export class NavMenuComponent implements OnInit {
    @ViewChild('topBar') topBarElement: ElementRef;
    @ViewChild('bottomBar') bottomBarElement: ElementRef;
    private showProductsMenu: boolean = false;
    private productsMenuStyling: Object;
    private zones: Object[];
    private zoneLinkStyling: Object;
    private styleLinkStyling: Object;
    private productsButtonStyling: Object;
    private navButtons: NavButton[];
    private activeButton: NavButton;
    private scrWidth: any;
    private ourProductsButton: NavButton;
    private selectedMobileMenu: string;
    private selectedZone: BuildingZone;
    private selectedStyle: DoorStyle;
    private productGroupMapping: Object;
    private showMobileMenu: boolean;
    private baseUrl: string = environment.linkUrl;

    constructor(private router: Router,
        private activeRoute: ActivatedRoute, private _el: ElementRef, private productService: ProductService, private dbService: BuildADoorService) {
        this.getScreenSize();
        this.productsMenuStyling = {
            'display': 'none',
            'height': '0px'
        };
        this.zoneLinkStyling = {
            'text-align': 'left',
            'display': 'grid'
        };
        this.styleLinkStyling = {
            'text-align': 'left',
            'display': 'grid'
        };
        this.productsButtonStyling = {
            'display': 'grid',
            'width': '100%',
            'height': 'auto',
            'vertical-align': 'middle',
            'margin': '0 auto',
            'justify-content': 'space-between'
        };
        this.ourProductsButton = new NavButton({
            index: 1, name: 'Our Products', pageURL: '/products', isActive: false
            //callback: function (me) {
            //    me.toggleProductsMenu()
            //}
        });
        let ourStoryButton = new NavButton({ index: 2, name: 'Our Story', pageURL: '/our-advantage', isActive: false });
        let reviewsButton = new NavButton({ index: 3, name: 'Reviews', pageURL: '/door-reviews', isActive: false });
        let promotionsButton = new NavButton({ index: 4, name: 'Promotions', pageURL: '/specials', isActive: false });
        let supportButton = new NavButton({ index: 5, name: 'FAQs', pageURL: '/frequently-asked-questions', isActive: false });
        let galleryButton = new NavButton({ index: 6, name: 'Gallery', pageURL: '/gallery', isActive: false });
        this.navButtons = [this.ourProductsButton, ourStoryButton, reviewsButton, promotionsButton, supportButton, galleryButton];
        this.selectedMobileMenu = null;
    }

    ngOnInit() {
        this.productService.getZoneAndGroupIds().subscribe(result => {
            if (result && result['buildingZones']) {
                this.productGroupMapping = {};
                this.zones = result['buildingZones'];
                let numZones = 0;
                let maxLinks = 0;
                let totalLinks = 0;
                for (var z in this.zones) {
                    let zone = this.zones[z];
                    let numCurrentLinks = 1 + zone['doorStyles'].length;
                    if (numCurrentLinks > maxLinks) maxLinks = numCurrentLinks;
                    numZones++;
                    totalLinks += numCurrentLinks;
                }

                for (var z in this.zones) {
                    let zone = this.zones[z];
                    while ((maxLinks - 1) > zone['doorStyles'].length) {
                        zone['doorStyles'].push({ id: -1, name: '' });
                    }
                    for (var s in zone['doorStyles']) {
                        let style = zone['doorStyles'][s];
                        this.productService.getProductGroupsForStyleSync(style.id, null).then((result) => {
                            this.productGroupMapping[style.id] = result;
                        });
                    }
                }

                let zoneWidth = Math.round(100 / numZones);
                let zoneWidthStyle = '';
                for (var i = 0; i < numZones; i++) {
                    zoneWidthStyle += zoneWidth + '%';
                    if (i < numZones - 1) zoneWidthStyle += ' ';
                }
                let zoneHeight = Math.round(100 / maxLinks);
                let otherLinksHeight = Math.round(100 - zoneHeight);
                this.zoneLinkStyling['grid-auto-columns'] = zoneWidthStyle;
                this.zoneLinkStyling['grid-auto-rows'] = '' + zoneHeight + '% ' + otherLinksHeight + '%';

                let styleHeight = Math.round(100 / (maxLinks - 1));
                let styleHeightStyle = '';
                for (var i = 0; i < maxLinks - 1; i++) {
                    styleHeightStyle += styleHeight + '%';
                    if (i < maxLinks - 1) styleHeightStyle += ' ';
                }
                this.styleLinkStyling['grid-auto-rows'] = styleHeightStyle; 

                let buttonWidthStyle = '';
                for (var i = 0; i < totalLinks; i++) {
                    buttonWidthStyle += 'auto';
                    if (i < totalLinks - 1) buttonWidthStyle += ' ';
                }
                buttonWidthStyle += ' auto';
                this.productsButtonStyling['grid-template-columns'] = buttonWidthStyle; 

            }
        });
        this.selectedMobileMenu = null;
    }

    ngAfterViewChecked() {
        this.dbService.navHeight = this.topBarElement.nativeElement.offsetHeight + this.bottomBarElement.nativeElement.offsetHeight;
    }



    cartView(route: ActivatedRouteSnapshot) {
        this.router.navigate(['cart'], { queryParams: { returnUrl: this.createURL(route) } });
    }

    createURL(route: ActivatedRouteSnapshot): string {
        var url = this.router.routerState.snapshot.url;
        if (route && route.url && route.url.length > 0) {
            url = "";
            for (var i = 0; i < route.url.length; i++) {
                url += "/" + route.url[i];
            }
        }
        return url;
    }

    onButtonClick(button) {
        if (this.activeButton) this.activeButton.deactivate();
        this.activeButton = button;
        this.activeButton.activate();
        if (button.pageURL) {
            this.router.navigate([button.pageURL]);
        } else if (button.callback) {
            button.callback(this);
        }
    }
    onClick(event) {
        if (!this._el.nativeElement.contains(event.target)) {
            this.showProductsMenu = false;
            this.productsMenuStyling = this.hideMenuStyling();
        }
    }

    hideMenuStyling() {
        return {
            'display': 'none',
            'height': '0px'
        };
    }

    showMenuStyling() {
        return {
            'display': 'grid',
            'height': 'auto',
            'width': '100%',
            'background-color': '#ffffff'
        };
    }

    toggleProductsMenu() {
        if (this.scrWidth <= 1224 || this.showProductsMenu) {
            this.showProductsMenu = false;
            this.productsMenuStyling = this.hideMenuStyling();
            this.ourProductsButton.isActive = false;
            this.ourProductsButton.getClass();
        } else {
            this.showProductsMenu = true;
            this.ourProductsButton.isActive = true;
            this.ourProductsButton.getClass();
            this.productsMenuStyling = this.showMenuStyling();
        }
    }

    toggleMobileMenu() {
        this.showMobileMenu = !this.showMobileMenu;
    }

    getMobileMenuClass() {
        if (this.showMobileMenu) return 'White-BG Mobile-Menu on';
        return 'off';
    }

    onProductLinkClick(destination: string) {
        this.router.navigateByUrl(destination);
    }

    onMobileTopLevelMenuClick(clicked: string) {
        this.selectedMobileMenu = clicked;
    }

    getTopLevelMobileMenuClass() {
        if (!this.showMobileMenu) return "off";
        if (this.selectedMobileMenu) return "off";
        return "on";
    }

    getZoneMobileMenuClass() {
        if (!this.showMobileMenu) return "off";
        if (this.selectedMobileMenu == 'products' && !this.selectedZone) return "on";
        return "off";
    }

    getStyleMobileMenuClass() {
        if (!this.showMobileMenu) return "off";
        if (this.selectedMobileMenu == 'products' && this.selectedZone && !this.selectedStyle) return "on";
        return "off";
    }

    getProductGroupMobileMenuClass() {
        if (!this.showMobileMenu) return "off";
        if (this.selectedMobileMenu == 'products' && this.selectedZone && this.selectedStyle) return "on";
        return "off";
    }

    onZoneMenuClick(zone: BuildingZone) {
        if (!zone) {
            this.selectedMobileMenu = null;
        } else {
            this.selectedZone = zone;
        }
    }

    onStyleMenuClick(style: DoorStyle) {
        if (!style) {
            this.selectedZone = null;
        } else {
            this.selectedStyle = style;
        }
    }

    onProductGroupMenuClick(pg: ProductGroup) {
        if (!pg) {
            this.selectedStyle = null;
        } else {
            this.router.navigate(['/product-details/' + pg.urlFriendlyId]);
        }
    }

    getStyles(): DoorStyle[] {
        if (this.selectedZone) return this.selectedZone['doorStyles'];
        return [];
    }

    getProductGroups(): ProductGroup[] {
        if (this.selectedStyle && this.productGroupMapping[this.selectedStyle.id]) return this.productGroupMapping[this.selectedStyle.id];
        return [];
    }

    onClickBuildDoor() {
        this.dbService.startOver();
        this.router.navigate(['/door-builder-home']);
    }

    onClickLogo() {
        this.dbService.startOver();
        this.router.navigate(['/']);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
        if (this.scrWidth <= 1224) {
            this.showProductsMenu = false;
            this.productsMenuStyling = this.hideMenuStyling();
            if (this.ourProductsButton) {
                this.ourProductsButton.isActive = false;
                this.ourProductsButton.getClass();
            }
        }
    }
}
