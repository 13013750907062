﻿import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { BuildADoorService } from '../../services';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
    private zones: Object[];
    private links: Object[] = [];
    private wrapperStyling: Object = {
        'text-align': 'left',
        'display': 'grid',
    };
    private scrHeight: any;
    private scrWidth: any;

    constructor(private router: Router, private productService: ProductService, private dbService: BuildADoorService) {
        this.getScreenSize();
    }

    ngOnInit() {
        this.getScreenSize();
        this.productService.getZoneAndGroupIds().subscribe(result => {
            if (result && result['buildingZones']) {
                this.zones = result['buildingZones'];
                if (this.zones && this.zones.length > 0) {
                    for (var i = 0; i < this.zones.length; i++) {
                        let zone = this.zones[i];
                        if (zone['doorStyles']) {
                            for (var styleIdx = 0; styleIdx < zone['doorStyles'].length; styleIdx++) {
                                let style = zone['doorStyles'][styleIdx];
                                this.links.push({
                                    name: style.name + ' Doors',
                                    class: 'Normal-Text Subcategory SlateGrey',
                                    url: '/products/' + zone['id'] + '/' + style.id
                                });
                            }
                        }
                    }
                }
                this.links.unshift({
                    name: 'Build Your Door',
                    class: 'Normal-Text Subcategory SlateGrey',
                    url: '/door-builder-home'
                });
                this.links.unshift({
                    name: 'Our Products',
                    class: 'Normal-Text Category Black',
                    url: '/products'
                });
                this.calculateOurProductsStyling();
            }
        });
    }

    calculateOurProductsStyling() {
        var gridStyling = '';
        let rowHeight = '20px';
        if (this.scrWidth <= 1224) {
            gridStyling += '3vh 2vh ';
            rowHeight = '2vh';
        } else {
            gridStyling += '30px 20px ';
        }
        if (this.zones && this.zones.length > 0) {
            for (var i = 0; i < this.zones.length; i++) {
                let zone = this.zones[i];
                if (zone['doorStyles']) {
                    for (var styleIdx = 0; styleIdx < zone['doorStyles'].length; styleIdx++) {
                        if (styleIdx != zone['doorStyles'].length - 1)
                            gridStyling += rowHeight + ' ';
                    }
                    gridStyling += rowHeight;
                }
            }
        }
        this.wrapperStyling['grid-template-rows'] = gridStyling;
    }

    onClickLogo() {
        this.dbService.startOver();
        this.router.navigate(['/']);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrHeight = window.innerHeight;
        this.scrWidth = window.innerWidth;
        this.calculateOurProductsStyling();
    }

}
