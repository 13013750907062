﻿import { Component } from '@angular/core';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';
import { Router } from '@angular/router';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { DoorDesign, DoorHardware } from 'src/app/shared/models';

@Component({
    selector: 'app-select-hardware',
    templateUrl: './hardware.component.html',
    styleUrls: ['./hardware.component.css'],
})
export class DBDesignHardwareComponent extends DBStateComponent {
    private hardwareOptions: DoorHardware[];
    //private wrapperStyling: Object;

    async ngOnInit() {
        if (!this.dbService.customerDesignedDoor.doorDesign) {
            this.router.navigate(['/door-builder/design/design-home']);
        }

        this.dbService.hasViewedHardware = true;
        this.hardwareOptions = this.dbService.customerDesignedDoor.doorDesign.hardwareOptions;
        //if (this.hardwareOptions && this.hardwareOptions.length > 0 && this.hardwareOptions[0] !== this.dbService.noneHardware) {
        //    this.hardwareOptions.unshift(this.dbService.noneHardware);
        //}

        this.setCollectionWrapperStyling(this.hardwareOptions);
        //window.console && console.log("hardwareOptions = " + JSON.stringify(this.hardwareOptions));
        //if (this.isMobile()) {
        //    this.wrapperStyling = {};
        //    this.wrapperStyling['grid-template-rows'] = 'minmax(min-content, max-content)';
        //} else {
        //    this.wrapperStyling = this.collectionWrapperStyling;
        //    var gridStyling = '';
        //    if (this.hardwareOptions && this.hardwareOptions.length > 0) {
        //        var numRows = this.hardwareOptions.length / 2 + this.hardwareOptions.length % 2;
        //        for (var i = 0; i < numRows; i++) {
        //            if (i != numRows - 1)
        //                gridStyling += 'auto ';
        //        }
        //        gridStyling += 'auto';
        //    }
        //    this.wrapperStyling['grid-template-rows'] = gridStyling;
        //    this.wrapperStyling['grid-template-columns'] = '50% 50%';
        //}
        super.ngOnInit();
    }

    getCollectionStyle(index) {
        let style = super.collectionStyle(index);
        if (!this.isMobile()) {
            var colNum = 1 + (index % 2);
            style['grid-column'] = '' + colNum;
            style['max-height'] = '600px';
        }
        return style;
    }

    getCollectionClass(hw) {
        let existing = this.dbService.customerDesignedDoor.hardware.find(h => h === hw);
        if (existing) {
            return 'Collection-Button-Selected';
        } else {
            return 'Collection-Button-Unselected';
        }
    }

    onClickHardware(hw) {
        //if (hw === this.dbService.noneHardware) {
        //    this.dbService.customerDesignedDoor.hardware = [this.dbService.noneHardware];
        //} else {
            //if (this.dbService.customerDesignedDoor.hardware && this.dbService.customerDesignedDoor.hardware.length === 1 &&
            //    this.dbService.customerDesignedDoor.hardware[0] === this.dbService.noneHardware) {
            //    this.dbService.customerDesignedDoor.hardware = [];
            //}
            let originalLength = this.dbService.customerDesignedDoor.hardware.length;
            this.dbService.customerDesignedDoor.hardware = this.dbService.customerDesignedDoor.hardware.filter(function (value, index, arr) {
                return value != hw;
            });
            if (originalLength === this.dbService.customerDesignedDoor.hardware.length)
                this.dbService.customerDesignedDoor.hardware.push(hw);
        //}
        //this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
    }

    getPrice(hw) {
        if (hw.price > 0) {
            if (hw.setOf && this.dbService.customerDesignedDoor.width.feet >= 12) return '$' + (2 * hw.price);
            return '$' + hw.price;
        }

        //if (hw === this.dbService.noneHardware) {
        //    return '';
        //}
        return 'Price Included';
    }

    getHardwareLabel(hw) {
        //if (hw === this.dbService.noneHardware) {
        //    return hw.name;
        //}
        let label = '';
        if (hw) {
            label = hw.size + '" ' + hw.name;
        }
        return label;
    }

    getButtonClass() {
        if (this.dbService.customerDesignedDoor.hardware && this.dbService.customerDesignedDoor.hardware.length > 0) return 'Rectangle';
        return 'Changed-Mind-Button';
    }

    getButtonText() {
        if (this.dbService.customerDesignedDoor.hardware && this.dbService.customerDesignedDoor.hardware.length > 0)
            return 'Continue';
        return 'No Thanks';
    }

    isShowContinue() {
        return true;
        //if (this.dbService.customerDesignedDoor.hardware && this.dbService.customerDesignedDoor.hardware.length > 0) return true;
        //return false;
    }

    onClickContinue() {
        if (!this.dbService.hasViewedExtras) {
            this.router.navigate(['/door-builder/design/select-extras']);
        } else {
            this.router.navigate(['/door-builder/design/extras-home']);
        }
    }

    getDescription(hw) {
        if (hw.setOf) {
            if (this.dbService.customerDesignedDoor.width.feet >= 12) {
                return '2 sets of ' + hw.setOf;
            }
            return '1 set of ' + hw.setOf;
        } else {
            return hw.description;
        }
    }
}