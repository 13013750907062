﻿import { Component } from '@angular/core';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';
import { Router } from '@angular/router';
import { DBStateComponent } from '../../shared/components/db-state.component';
import { ModalService, ProductService } from 'src/app/shared/services';

@Component({
    selector: 'app-select-wind-rating',
    templateUrl: './select-wind-rating.component.html',
    styleUrls: ['./select-wind-rating.component.css'],
})
export class DBSetupSelectWindRatingComponent extends DBStateComponent {
    disabledYesButtonText = 'No Wind Speed Options Available for door width of ' + this.dbService.customerDesignedDoor.width.feet + ' feet';
    private buttonClasses: Object = {
        Yes: 'Button-Label-Div',
        no: 'Button-Label-Div',
        notSure: 'Button-Label-Div'
    };
    public windSpeeds: string[];
    public impactLevels: string[];
    selectedWindSpeed: string;
    selectedImpactLevel: string;
    isNextButtonDisabled: boolean = true;
    isYesButtonDisabled: boolean = false;
    selectedButton: string;
    yesButtonText = 'Yes';
    isImpactLevelDisabled: boolean = false;
    private isShowWindSpeedDiv: boolean = false;
    private isResetState: boolean = false;
    private windSpeedDivStyling: Object;
    private windSpeedLabelStyling: Object;

    constructor(public dbService: BuildADoorService, public dbFooterService: DBFooterService, public router: Router, private modalService: ModalService, private productService: ProductService) {
        super(dbService, router, dbFooterService);
        this.windSpeedDivStyling = {
            'display': 'none',
            'height': '0px'
        };
        this.windSpeedLabelStyling = {
            'display': 'none',
            'height': '0px'
        };
        this.buttonClasses[this.disabledYesButtonText] = 'Disabled-Button-Div';
        this.impactLevels = [this.dbService.NON_IMPACT, this.dbService.IMPACT, this.dbService.IMPACT_DADE];
    }

    async ngOnInit() {
        super.verifyState();
        //if (!this.dbService.customerDesignedDoor.width || !this.dbService.customerDesignedDoor.height || !this.dbService.customerDesignedDoor.width.feet || !this.dbService.customerDesignedDoor.height.feet) {
        //    this.router.navigate(['/setup/select-size']);
        //}
        this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumSetupStepsComplete());
        if (this.dbService.customerDesignedDoor.width.inches) this.disabledYesButtonText = this.disabledYesButtonText + ', ' + this.dbService.customerDesignedDoor.width.inches + ' inches';

        if (this.selectedWindSpeed || this.selectedButton == 'no') {
            this.isNextButtonDisabled = false;
        } else {
            this.isNextButtonDisabled = true;
        }

        let query = {
            width: this.dbService.customerDesignedDoor.width,
            height: this.dbService.customerDesignedDoor.height
        };
        this.productService.getPossibleMPHs(query).subscribe(result => {
            if (result) {
                if (result['mphs']) {
                    this.windSpeeds = result['mphs'];
                    if (this.windSpeeds && Object.keys(this.windSpeeds).length > 0) {
                        this.isYesButtonDisabled = false;
                        this.yesButtonText = 'Yes';
                        if (this.dbService.customerDesignedDoor.isWindSpeedAnswered) {
                            if (this.dbService.customerDesignedDoor.windSpeed) {
                                this.buttonClick('Yes');
                                this.selectedWindSpeed = this.windSpeeds.find(ws => ws['mph'] == this.dbService.customerDesignedDoor.windSpeed);
                                this.selectedImpactLevel = this.dbService.customerDesignedDoor.impactType;
                                this.onWindSpeedChange();
                            } else {
                                this.buttonClick('no');
                            }
                        }
                    } else {
                        this.isYesButtonDisabled = true;
                        this.yesButtonText = this.disabledYesButtonText;
                    }
                }
            }
        });

        super.ngOnInit();
    }

    isShowWindSpeeds(): boolean {
        if (this.windSpeeds && Object.keys(this.windSpeeds).length > 0) return true;
        return false;
    }

    buttonClick(button) {
        this.buttonClasses['Yes'] = 'Button-Label-Div';
        this.buttonClasses['no'] = 'Button-Label-Div';
        this.buttonClasses['notSure'] = 'Button-Label-Div';
        if (button !== this.disabledYesButtonText) this.buttonClasses[button] += ' Div-Selected';
        if (this.selectedButton && this.selectedButton !== button) {
            this.isResetState = true;
        }
        this.selectedButton = button;
        if (this.selectedButton == this.disabledYesButtonText) return;
        if (this.selectedButton == 'Yes') {
            this.isShowWindSpeedDiv = true;
            this.windSpeedDivStyling = this.showWindSpeedDivStyling();
            this.windSpeedLabelStyling = this.showWindSpeedLabelStyling();
        } else {
            this.selectedWindSpeed = null;
            this.selectedImpactLevel = null;
            this.isShowWindSpeedDiv = false;
            this.dbService.customerDesignedDoor.windSpeed = null;
            this.dbService.customerDesignedDoor.impactType = null;
            this.windSpeedDivStyling = this.hideWindSpeedDivStyling();
            this.windSpeedLabelStyling = this.hideWindSpeedDivStyling();
            this.onWindSpeedChange();
        }
        this.updateNextButton();
    }

    buttonClass(button) {
        return this.buttonClasses[button];
    }

    hideWindSpeedDivStyling() {
        return {
            'display': 'none',
            'height': '0px'
        };
    }

    showWindSpeedDivStyling() {
        let style = {
            'display': 'grid',
            'text-align': 'center',
            'grid-auto-rows': 'minmax(min-content, max-content)',
            'padding-top': '1vh',
            'padding-bottom': '1vh',
            'background-color': '#ffffff',
        }
        if (this.isMobile()) {
            style['margin'] = '1vw auto';
        } else {
            style['margin'] = '30px auto';
            style['width'] = '50%';
        }
        return style;
    }

    showWindSpeedLabelStyling() {
        return {
            'text-align': 'center'
        };
    }

    async onWindSpeedChange() {
        if (this.selectedWindSpeed) {
            if (this.dbService.customerDesignedDoor.windSpeed && this.dbService.customerDesignedDoor.windSpeed !== this.selectedWindSpeed['mph']) {
                this.isResetState = true;
                this.dbService.customerDesignedDoor.windSpeed = this.selectedWindSpeed['mph'];
            } else {
                this.dbService.customerDesignedDoor.windSpeed = this.selectedWindSpeed['mph'];
                let windLoadPrice = await this.dbService.checkExistingWindSpeedPrice();
                if (windLoadPrice == null) {
                    // reset product group and insulation
                    this.dbService.customerDesignedDoor.productGroup = null;
                    this.dbService.customerDesignedDoor.insulation = null;
                } else {
                    this.dbService.customerDesignedDoor.windLoadPrice = windLoadPrice;
                }
            }
            if (this.selectedWindSpeed['isImpact'] && !this.selectedWindSpeed['isDade'] && !this.selectedWindSpeed['isNoImpact']) {
                this.selectedImpactLevel = this.dbService.IMPACT;
                this.isImpactLevelDisabled = true;
                this.onImpactLevelChange();
            } else if (!this.selectedWindSpeed['isImpact'] && this.selectedWindSpeed['isDade'] && !this.selectedWindSpeed['isNoImpact']) {
                this.selectedImpactLevel = this.dbService.IMPACT_DADE;
                this.isImpactLevelDisabled = true;
                this.onImpactLevelChange();
            } else if (!this.selectedWindSpeed['isImpact'] && !this.selectedWindSpeed['isDade'] && this.selectedWindSpeed['isNoImpact']) {
                this.selectedImpactLevel = this.dbService.NON_IMPACT;
                this.isImpactLevelDisabled = true;
                this.onImpactLevelChange();
            } else {
                this.isImpactLevelDisabled = false;
            }
        }
        this.updateNextButton();
    }

    onImpactLevelChange() {
        this.dbService.customerDesignedDoor.impactType = this.selectedImpactLevel;
    }

    updateNextButton() {
        if (this.selectedWindSpeed || this.selectedButton != 'Yes') {
            this.isNextButtonDisabled = false;
        } else {
            this.isNextButtonDisabled = true;
        }

        if (this.selectedWindSpeed && !this.selectedImpactLevel) {
            this.dbService.customerDesignedDoor.impactType = this.dbService.NON_IMPACT;
        }
    }

    showModal() {
        this.modalService.open('notsure-modal');
    }

    hideModal() {
        this.modalService.close('notsure-modal');
    }

    async nextState() {
        if (this.selectedWindSpeed) {
            this.dbService.customerDesignedDoor.windSpeed = this.selectedWindSpeed['mph'];
        }
        this.dbService.customerDesignedDoor.isWindSpeedAnswered = true;
        if (this.isResetState) {
            this.isResetState = false;
            this.dbService.startOverWindLoad();
        } else if (this.dbService.selectedStyle || this.dbService.customerDesignedDoor.productGroup) {
            await this.dbService.validateSelections();
        }
        super.nextState(this.dbService.customerDesignedDoor.getNumSetupStepsComplete());
    }

}