﻿import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DBNavMenuComponent } from './db-nav-menu.component';
import {
    MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
    MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule
} from '@angular/material';
import { GDSHCartSummaryComponent } from '../../../cart/cart-summary/cart-summary.component';

@NgModule({
    imports: [MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
        MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule,
        RouterModule, CommonModule],
    declarations: [DBNavMenuComponent, GDSHCartSummaryComponent],
    exports: [DBNavMenuComponent]
})
export class DBNavMenuModule { }
