import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { BuildingZone, DoorStyle, ProductGroup, DoorDesign, ProductPrice, DoorInsulationLevel, DoorInsulationOption, DoorWindow, DoorSpecial, CustomerDesignedDoor, Headroom, ColorWheelOption } from '../models';
import Taxjar from 'taxjar';
import { LaborZoneRate } from '../models/labor-zone-rate';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    constructor(private http: HttpClient) { }

    getBuildingZones(): Observable<BuildingZone[]> {
        return this.http.get<BuildingZone[]>('products/zones');
    }

    getZoneAndGroupIds(): Observable<Object> {
        return this.http.get<Object>('products/zoneAndGroupIds');
    }

    getBuildingZone(zoneId: string): Observable<BuildingZone> {
        return this.http.get<BuildingZone>('products/zone', { params: { 'zoneId': zoneId } });
    }

    getStylesForZone(zone): Observable<DoorStyle[]> {
        return this.http.get<DoorStyle[]>('products/stylesForZone', { params: { 'zoneId': zone._id } });
    }

    getStyles(): Observable<DoorStyle[]> {
        return this.http.get<DoorStyle[]>('products/styles');
    }

    getStylesBySelectors(selectors): Observable<DoorStyle[]> {
        return this.http.post<DoorStyle[]>('products/styles', { query: selectors });
    }

    reloadDoor(door): Observable<CustomerDesignedDoor> {
        return this.http.post<CustomerDesignedDoor>('products/reloadDoor', { door: door });
    }

    loadDoorsById(ids): Observable<any> {
        return this.http.post('products/loadDoorsById', { ids: ids });
    }

    async getStylesBySelectorsSync(selectors) {
        let result = await this.getStylesBySelectors(selectors).toPromise();

        if (result) return result['styles'];
        return [];
    }

    getStyle(styleId): Observable<DoorStyle> {
        return this.http.get<DoorStyle>('products/style', {
            params: { 'styleId': styleId }
        });
    }

    getProductGroupsForStyle(styleId, width): Observable<ProductGroup[]> {
        let params = { 'styleId': styleId };
        if (width) {
            params['widthFeet'] = width.feet;
            if (width.inches > 0) {
                params['widthInches'] = width.inches;
            }
        }
        return this.http.get<ProductGroup[]>('products/productGroupsForStyle', {
            params: params
        });
    }

    async getProductGroupsForStyleSync(styleId, width) {
        let result = await this.getProductGroupsForStyle(styleId, width).toPromise();

        if (result) {
            return result['productGroups'];
        }
        return [];
    }

    getPossibleMPHs(selectors): Observable<number[]> {
        return this.http.post<number[]>('products/getPossibleMPHs', {query: selectors});
    }
   getDoorDesignsForProductGroup(selectors): Observable<DoorDesign[]> {
       return this.http.post<DoorDesign[]>('products/getDoorDesignsForProductGroup', { query: selectors });
    }

    async  getDoorDesignsForProductGroupSync(selectors) {
        let result = await this.getDoorDesignsForProductGroup(selectors).toPromise();

        if (result) return result['doorDesigns'];
        return [];
    }

    getDoorDesigns(querySelectors): Observable<DoorDesign[]> {
        return this.http.post<DoorDesign[]>('products/getDoorDesigns', { query: querySelectors });
    }

    getInsulationOptionPrices(querySelectors): Observable<DoorInsulationOption[]> {
        return this.http.post<DoorInsulationOption[]>('products/getInsulationOptionPrices', { query: querySelectors });
    }

    getWindowPrices(querySelectors): Observable<DoorWindow[]> {
        return this.http.post<DoorWindow[]>('products/getWindowPrices', { query: querySelectors });
    }

    getDoorExtras(selector: CustomerDesignedDoor): Observable<Object> {
        let query = {
            //zipcode: zip,
            height: selector.height,
            width: selector.width,
            productGroup: selector.productGroup._id,
            trackAndSpring: selector.trackAndSpring
        };
        return this.http.post<Object>('products/getExtras', { query: query });
    }

    async getDoorExtrasSync(selector: CustomerDesignedDoor) {
        let query = {
            //zipcode: zip,
            height: selector.height,
            width: selector.width,
            productGroup: selector.productGroup._id,
            trackAndSpring: selector.trackAndSpring
        };
        let result = await this.http.post<Object>('products/getExtras', { query: query }).toPromise();
        if (result) return result['doorExtras'];
        return [];
    }
    getProductGroup(productGroupId): Observable<ProductGroup> {
        return this.http.get<ProductGroup>('products/productGroup', {
            params: { 'productGroupId': productGroupId }
        });
    }

    async getAllProductGroups() {
        let result = await this.http.get<ProductGroup[]>('products/allProductGroups').toPromise();
        if (result) return result['productGroups'];
        return [];
    }

    getProductGroups(querySelectors): Observable<Object[]> {
        return this.http.post<Object[]>('products/getProductGroups', { query: querySelectors });
    }

    async  getProductGroupsSync(selectors) {
        let result = await this.getProductGroups(selectors).toPromise();

        if (result) return result['productGroups'];
        return [];
    }

    getProductPrice(selector: any): Observable<ProductPrice> {
        return this.http.post<ProductPrice>('products/getProductPrice', {
            selector: selector
        });
    }

    getInsulationLevels(): Observable<DoorInsulationLevel[]> {
        return this.http.get<DoorInsulationLevel[]>('products/doorInsulationLevels');
    }

    authenticatePaytrace(userData: any, headers: any): Observable<Object> {
        return this.http.post<string>('https://api.paytrace.com/oauth/token', userData, { headers: headers });
    }

    getSpecials(selector: Object) {
        return this.http.post("/productSpecials", selector);
    }
    getHeadrooms(selector: CustomerDesignedDoor): Observable<Object> {
        let query = {
            style: selector.productGroup.style._id,
            productGroup: selector.productGroup._id,
            insulationLevel: selector.insulation.insulationLevel._id,
            width: selector.width,
            height: selector.height,
            isWindLoad: selector.windSpeed
        }
        return this.http.post<Object>('/products/getHeadrooms', { query: query });
    }

    getLaborRate(querySelectors): Observable<LaborZoneRate> {
        return this.http.post<LaborZoneRate>('products/getLaborRate', { query: querySelectors });
    }

    hasLaborRateForZipcode(zipcode): Observable<Object> {
        return this.http.get<Object>('products/hasLaborRateForZipcode', { params: { 'zipcode': zipcode } });
    }

    getColorZoneOptions(): Observable<ColorWheelOption[]> {
        return this.http.get<ColorWheelOption[]>('products/getColorZoneOptions');
    }

    getWindLoadPrice(querySelectors: Object): Observable<Object> {
        return this.http.post<Object>('products/getWLPrice', { query: querySelectors });
    }

    getInsulationLevelsForWindLoad(querySelectors: Object): Observable<Object> {
        return this.http.post<Object>('products/getInsulationLevelsForWindLoad', { query: querySelectors });
    }
}