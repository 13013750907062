﻿import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FAQService } from '../shared/services/faq.service';
import { FAQ } from '../shared/models';
import { BuildADoorService } from '../shared/services';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {
    private faqs: FAQ[];

    constructor(private faqService: FAQService, private dbService: BuildADoorService, private changeDetector: ChangeDetectorRef) {
        this.faqService.getFAQs().subscribe(result => {
            if (result && result['faqs']) {
                this.faqs = result['faqs'];
                //for (var i = 0; i < this.faqs.length; i++) {
                //    this.faqs[i].answer = this.faqs[i].answer.replace(/-/g, '&#8288;—&#8288;');
                //}
            }
        });
    }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

    getArrowClass(faq: FAQ) {
        if (faq.isOpen) return "down-green";
        return "up-black";
    }

    onRowClick(faq: FAQ) {
        faq.isOpen = !faq.isOpen;
    }

    ngOnInit() {
    }
}