import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { BuildingZone, DoorStyle, DoorSpecial, ProductGroup } from 'src/app/shared/models';
import { ProductService } from 'src/app/shared/services/product.service';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { MatSelect } from '@angular/material';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-product-group-selection',
    templateUrl: './product-group-selection.component.html',
    styleUrls: ['./product-group-selection.component.css']
})
export class ProductGroupSelectionComponent implements OnInit {
    private productGroups: ProductGroup[];
    private zoneSpecials: {};
    private styleSpecials: {};
    private productGroupSpecials: {};
    private width: string;
    private height: string;
    private zoneId: string;
    private styleId: string;

    constructor(private http: HttpClient,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private productService: ProductService,
        private buildADoorService: BuildADoorService
    ) { }

    ngOnInit() {
        this.width = this.activeRoute.snapshot.paramMap.get('width');
        this.height = this.activeRoute.snapshot.paramMap.get('height');
        this.zoneId = this.activeRoute.snapshot.paramMap.get('zone');
        this.styleId = this.activeRoute.snapshot.paramMap.get('style');
        this.zoneSpecials = {};
        this.styleSpecials = {};
        this.productGroupSpecials = {};
        this.buildADoorService.getSpecials({ zone: this.zoneId, style: this.styleId }).forEach(sp => {
            if (sp.zone)
                this.getZoneSpecial(sp);
            if (sp.style)
                this.getStyleSpecial(sp);
            if (sp.productGroup)
                this.productGroupSpecials[sp.productGroup._id] = sp;
        });
        this.getProductGroupsForStyle();
        //this.buildADoorService.getSpecials({ zone: this.zoneId, style: this.styleId }).subscribe(result => {
        //    if (result && result['specials']) {
        //        result['specials'].forEach(sp => {
        //            if (sp.zone)
        //                this.getZoneSpecial(sp);
        //            if (sp.style)
        //                this.getStyleSpecial(sp);
        //            if (sp.productGroup)
        //                this.productGroupSpecials[sp.productGroup._id] = sp;
        //        });
        //    }
        //    this.getProductGroupsForStyle();
        //});
    }

    getZoneSpecial(sp: DoorSpecial) {
        if (sp.zone && !sp.style && !sp.productGroup && !sp.product && !sp.insulationLevel && !sp.MPH && !sp.width && !sp.height)
            this.zoneSpecials[sp.zone._id] = sp;
    }

    getStyleSpecial(sp: DoorSpecial) {
        if (sp.style && !sp.productGroup && !sp.product && !sp.insulationLevel && !sp.MPH && !sp.width && !sp.height)
            this.styleSpecials[sp.style._id] = sp;
    }

    getProductGroupsForStyle() {
        this.productService.getProductGroupsForStyle(this.styleId, null).subscribe(result => {
            if (result && result['productGroups']) {
                this.productGroups = result['productGroups'];
                this.productGroups.forEach(pg => {
                    pg.special = this.productGroupSpecials[pg._id];
                    if (!pg.special)
                        pg.special = this.styleSpecials[this.styleId];
                    if (!pg.special)
                        pg.special = this.zoneSpecials[this.styleId];
                });
            }
        });
    }
}
