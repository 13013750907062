﻿export class SpringWindingOption {
    _id: string;
    name: string;
    maxHeight: number;
    price: number;
    details: string[];

    constructor(swo?) {
        if (swo) {
            this._id = swo._id;
            this.name = swo.name;
            this.maxHeight = swo.maxHeight;
            this.price = swo.price;
            this.details = swo.details ? swo.details : [];
        }
    }
}