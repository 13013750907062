import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BuildADoorComponent } from './build-a-door.component';
import { DBSetupComponent } from './db-setup/db-setup.component';
import { DBSetupSelectSizeComponent } from './db-setup/select-size/select-size.component';
import { DBSetupSelectWindRatingComponent } from './db-setup/select-wind-rating/select-wind-rating.component';
import { DBDesignComponent } from './db-design/db-design.component';
import { DBDesignHomeComponent } from './db-design/design-home/design-home.component';
import { DBDesignModelComponent } from './db-design/model/model.component';
import { DBDesignSelectCollectionComponent } from './db-design/select-collection/select-collection.component';
import { DBDesignPanelDesignComponent } from './db-design/panel-design/panel-design.component';
import { DBDesignInsulationComponent } from './db-design/insulation/insulation.component';
import { DBDesignWindowComponent } from './db-design/window/window.component';
import { DBDesignColorComponent } from './db-design/color/color.component';
import { DBDesignHardwareComponent } from './extras-home/hardware/hardware.component';
import { DBExtrasHomeComponent } from './/extras-home/extras-home.component';
import { DBExtrasOperatorsOpenersComponent } from './extras-home/operators-openers/operators-openers.component';
import { DBHeadroomComponent } from './db-headroom/db-headroom.component';
import { DBSummaryComponent } from './db-summary/db-summary.component';
import { DBLocksComponent } from './extras-home/locks/locks.component';
import { DBOperatorPrepOptionsComponent } from './extras-home/operator-prep-options/operator-prep-options.component';
import { DBExtrasComponent } from './extras-home/extras/extras.component';

export const routes: Routes = [
    {
        path: 'door-builder', component: BuildADoorComponent, data: { breadcrumb: 'Door Builder' }, children: [
            { path: 'setup', component: DBSetupComponent, data: { breadcrumb: 'Delivery Options' } },
            { path: 'setup/select-size', component: DBSetupSelectSizeComponent, data: { breadcrumb: 'Size Selection' } },
            { path: 'setup/select-wind-rating', component: DBSetupSelectWindRatingComponent, data: { breadcrumb: 'Wind Rating Selection' } },
            //{ path: ':width/:height', component: StyleSelectionComponent, data: { breadcrumb: 'Select Style' } },
            //{
            {
                path: 'design', component: DBDesignComponent, children: [
                    { path: 'design-home', component: DBDesignHomeComponent, data: { breadcrumb: 'Design Home' } },
                    { path: 'select-model', component: DBDesignModelComponent, data: { breadcrumb: 'Select Model' } },
                    { path: 'select-collection', component: DBDesignSelectCollectionComponent, data: { breadcrumb: 'Select Collection' } },
                    { path: 'panel-design', component: DBDesignPanelDesignComponent, data: { breadcrumb: 'Panel Design' } },
                    { path: 'select-insulation', component: DBDesignInsulationComponent, data: { breadcrumb: 'Select Insulation' } },
                    { path: 'select-window', component: DBDesignWindowComponent, data: { breadcrumb: 'Select Window' } },
                    { path: 'select-color', component: DBDesignColorComponent, data: { breadcrumb: 'Select Color' } },
                    { path: 'select-hardware', component: DBDesignHardwareComponent, data: { breadcrumb: 'Select Hardware' } },
                    { path: 'extras-home', component: DBExtrasHomeComponent, data: { breadcrumb: 'Select Extras' } },
                    { path: 'operators-openers', component: DBExtrasOperatorsOpenersComponent, data: { breadcrumb: 'Openers' } },
                    { path: 'locks', component: DBLocksComponent, data: { breadcrumb: 'Locks' } },
                    { path: 'operator-prep-options', component: DBOperatorPrepOptionsComponent, data: { breadcrumb: 'Operator Prep Options' } },
                    { path: 'select-extras', component: DBExtrasComponent, data: { breadcrumb: 'Extras' } }
            ]},
            //{ path: ':width/:height/:zone/:style', component: ProductGroupSelectionComponent, data: { breadcrumb: 'Select Product Group' } },
            //{
            //    path: 'extras', component: DBExtrasComponent, children: [
            //        { path: 'extras/operators-openers', component: DBExtrasUpgradeOptionsComponent, data: { breadcrumb: 'Extras' } },
            //    ]},
            //{ path: ':width/:height/:zone/:style/:productGroup', component: DoorOptionsSelectionComponent, data: { breadcrumb: 'Select Door Options' } },
            { path: 'headroom', component: DBHeadroomComponent, data: { breadcrumb: 'Headroom' } },
            { path: 'summary', component: DBSummaryComponent, data: { breadcrumb: 'Summary' } }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})

export class BuildADoorRoutingModule { }