﻿import { Component, OnInit, HostListener } from '@angular/core';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';

@Component({})
export class DBStateComponent implements OnInit {
    public routeSub: any;
    private scrWidth: any;
    public collectionWrapperStyling: Object;

    constructor(public dbService: BuildADoorService, public router: Router, public dbFooterService: DBFooterService) {
    }

    public async ngOnInit() {
        await this.dbService.initializeZonesAndSpecials();
   }

    public nextState(numComplete?: number) {
        if (numComplete) {
            this.dbFooterService.updateNumComplete(numComplete);
        }
        if (this.dbService.previousParentState) {
            this.dbService.onNaveMenuPreviousState();
        } else {
            this.dbService.onNavMenuNextState();
        }
    }

    public collectionStyleBase(index) {
        return {
            'grid-row': index,
            'display': 'grid',
            'text-align': 'center',
            'grid-template-columns': '40% 60%',
            'background-color': '#ffffff'
        };
    }

    public verifyState() {
        if (!this.dbService.customerDesignedDoor) {
            this.router.navigate(['/door-builder/design/design-home']);
        } else if (!this.dbService.previousParentState) {
            if (this.dbService.navButtonPressed) {
                this.dbService.navButtonPressed = null;
            } else {
                //if (!this.dbService.zipcode) {
                //    this.router.navigate(['/door-builder-home']);
                //} else if (!this.dbService.customerDesignedDoor.deliveryOption) {
                //    this.router.navigate(['/door-builder/setup']);
            //} else
                if (!this.dbService.customerDesignedDoor.width || !this.dbService.customerDesignedDoor.height || !this.dbService.customerDesignedDoor.width.feet || !this.dbService.customerDesignedDoor.height.feet ||
                    this.dbService.customerDesignedDoor.width.feet <= 0 || this.dbService.customerDesignedDoor.height.feet <= 0) {
                    this.router.navigate(['/door-builder/setup/select-size']);
                } else if (!this.dbService.customerDesignedDoor.isWindSpeedAnswered) {
                    this.router.navigate(['/door-builder/setup/select-wind-rating']);
                } else if (!this.dbService.selectedStyle) {
                    this.router.navigate(['/door-builder/design/select-collection']);
                } else if (!this.dbService.customerDesignedDoor.productGroup || !this.dbService.customerDesignedDoor.productGroup._id) {
                    this.router.navigate(['/door-builder/design/select-model']);
                } else if (!this.dbService.customerDesignedDoor.doorDesign) {
                    this.router.navigate(['/door-builder/design/panel-design']);
                } else if (!this.dbService.customerDesignedDoor.insulation) {
                    this.router.navigate(['/door-builder/design/select-insulation']);
                } else if (!this.dbService.customerDesignedDoor.window) {
                    this.router.navigate(['/door-builder/design/select-window']);
                } else if (!this.dbService.customerDesignedDoor.color) {
                    this.router.navigate(['/door-builder/design/select-color']);
                } else if (!this.dbService.customerDesignedDoor.color) {
                    this.router.navigate(['/door-builder/design/select-color']);
                }
            }
        }
    }

    public collectionStyleNoColumns(index, hasRows?: boolean) {
        let style = this.collectionStyleBase(index + 1);
        if (this.isMobile()) {
            style['padding'] = '2%';
            style['margin-bottom'] = '2px';
        } else {
            style['padding'] = '10px 5px';
            style['margin-bottom'] = '5px';
            style['margin-right'] = '5px';
        }
        if (hasRows) {
            style['grid-auto-rows'] = 'minmax(min-content, max-content)';
        };
        return style;
    }

    public collectionStyle(index, hasRows?: boolean) {
        let style = {};
        if (this.isMobile()) {
            style = this.collectionStyleBase(index + 1);
            style['padding'] = '2%';
            style['margin-bottom'] = '2px';
            //style = {
            //    'grid-row': index,
            //    'display': 'grid',
            //    'text-align': 'center',
            //    'grid-template-columns': '40% 60%',
            //    'padding': '2%',
            //    'background-color': '#ffffff',
            //    'margin-bottom': '2px'
            //}
        } else {
            var rowNum = (index / 2) + 1;
            style = this.collectionStyleBase(rowNum);
            style['padding'] = '10px 5px';
            style['margin-bottom'] = '5px';
            style['margin-right'] = '5px';
            //style = {
            //    'grid-row': '' + rowNum,
            //    'display': 'grid',
            //    'text-align': 'center',
            //    'grid-template-columns': '40% 60%',
            //    'margin-right': '5px',
            //    'margin-bottom': '5px',
            //    'padding': '10px 5px',
            //    'background-color': '#ffffff'
            //};
        }
        if (hasRows) {
            style['grid-auto-rows'] = 'minmax(min-content, max-content)';
        };
        return style;

    }

    public setCollectionWrapperStyling(collection, hasOnlyRows?: boolean) {

        if (this.isMobile()) {
            this.collectionWrapperStyling = {};
        } else {
            this.collectionWrapperStyling = {
                'text-align': 'center',
                'display': 'grid',
                'margin': '5px 40px 5px 0',
                //'max-height': '450px',
                'overflow-y': 'auto'
            };
        }
        var gridStyling = '';
        if (this.isMobile()) {
            this.collectionWrapperStyling['grid-auto-rows'] = 'minmax(min-content, max-content)';
        } else {
            if (collection && collection.length > 0) {
                for (var i = 1; i <= collection.length; i++) {
                    if (this.isMobile()) {
                        gridStyling += 'auto ';
                    } else if (i % 2 == 1) {
                        gridStyling += 'auto ';
                    }
                }
                gridStyling += 'auto';
            }
            this.collectionWrapperStyling['grid-template-rows'] = gridStyling;
            if (!hasOnlyRows) {
                this.collectionWrapperStyling['grid-template-columns'] = '50% 50%';
            }
        }
    }

    public collectionClass(matchAgainst, selection) {
        if (!matchAgainst || !selection) return 'Collection-Button-Unselected';
        if ((matchAgainst._id && selection._id && matchAgainst._id === selection._id) ||
            (matchAgainst.id && selection.id && matchAgainst.id === selection.id)) {
            return 'Collection-Button-Selected';
        } else if (matchAgainst === selection) {
            return 'Collection-Button-Selected';
        } else {
            return 'Collection-Button-Unselected';
        }
    }

    public getPrice(item, flag): string {
        if (flag) {
            if (item.price > 0) {
                if (item.quantity && item.quantity > 0) {
                    return '$' + (item.quantity * (Math.ceil(item.price * 100) / 100)).toFixed(2);
                }
                return '$' + (Math.ceil(item.price * 100) / 100).toFixed(2);
            }
            return 'Price Included';
        }
        return 'Loading...';
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
    }

    isMobile() {
        if (!this.scrWidth) this.getScreenSize();
        return this.scrWidth <= 1224
    }

    moveStates(previousState, newState) {
        this.dbService.previousParentState = previousState;
        previousState.deactivate();
        if (newState.parentState) {
            newState.parentState.activate();
            this.dbService.activeState = newState.parentState;
            this.dbService.activeSubstate = newState;
            newState.parentState.activeSubstate = newState;
            newState.parentState.activeSubState = newState;
        } else {
            newState.activate();
            this.dbService.activeSubstate = null;
            this.dbService.activeState = newState;
        }
        this.router.navigate([newState.pageURL]);
    }

    onClickContinue(checkObject: Object, unsetURL: string) {
        if (!checkObject) {
            this.router.navigate([unsetURL]);
        } else {
            this.onClickDesign();
        }
    }

    onClickDesign() {
        this.dbService.navButtonPressed = true;
        this.router.navigate(['/door-builder/design/design-home']);
    }

    sortCollectionByDisplayOrder<T>(collection: Array<T>): Array<T> {
        return collection.sort((a, b) => (a['displayOrder'] > b['displayOrder']) ? 1 : ((b['displayOrder'] > a['displayOrder']) ? -1 : 0));
    }

    scrollToDiv(id: string, isDown?: boolean) {
        let top = document.getElementById(id);
        let rect = top.getBoundingClientRect();
        if (top !== null) {
            let elementTop = rect.top;
            // now account for fixed header
            var scrolledY = window.scrollY;

            if (scrolledY) {
                if (isDown) {
                    window.scroll(0, elementTop + window.scrollY);
                } else {
                    let moveTo = scrolledY - this.dbService.dbNavHeight;
                    if (!this.isMobile()) {
                        moveTo = moveTo - 40;
                    }
                    window.scroll(0, moveTo > 0 ? moveTo : 0);
                }
            }

            top = null;
        }
    }

    getQuantity(e) {
        return e.quantity;
    }

    incrementQuantity(e, collection) {
        let selectedItem = collection.find(ex => ex._id === e._id);
        if (selectedItem) {
            event.stopPropagation();
            selectedItem.quantity++;
        }
    }

    decrementQuantity(e, collection) {
        let selectedItem = collection.find(ex => ex._id === e._id);
        if (selectedItem) {
            if (selectedItem.quantity > 0) {
                selectedItem.quantity--;
            }
            if (selectedItem.quantity > 0) {
                event.stopPropagation();
            }
        }
    }


}