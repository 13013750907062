﻿import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DBFooterService {
    public percentComplete: number = 0;
    public totalStates: number;
    public message: string;
    public footerDisplayClass: string = 'FooterMainDiv';
    public readonly SETUP_STEPS = 3;
    public readonly DESIGN_STEPS = 6;
    public readonly BUILDER_STEPS = 4;
    public readonly SETUP_POSITION = 1;
    public readonly DESIGN_POSITION = 2;
    public readonly HEADROOM_POSITION = 3;
    public readonly EXTRAS_POSITION = 4;
    public state: string;
    public readonly SETUP_STATE = 'SETUP';
    public readonly DESIGN_STATE = 'DESIGN';
    public readonly PARENT_STATE = 'PARENT';
    public stateProgress: Object;
    public totals: Object;

    updateNumComplete(numComplete?: number) {
        if (!this.stateProgress) this.initialize();
        if (!this.state) return 0;
        if (numComplete) {
            this.stateProgress[this.state] = numComplete;
        }
        let total = this.totals[this.state];
        this.percentComplete = Math.round(this.stateProgress[this.state] * 100.0 / total);
        //this.percentComplete = Math.round(numComplete * 100.0 / this.totalStates);
        //this.percentComplete = Math.round(numComplete * 100.0 / this.totalStates);
    }

    updateParentState(position: number) {
        if (this.stateProgress[this.PARENT_STATE] < position) this.stateProgress[this.PARENT_STATE] = position;
    }

    hideFooter() {
        this.footerDisplayClass = 'Hidden';
    }

    showFooter() {
        this.footerDisplayClass = 'FooterMainDiv';
    }

    completeState(state: string) {
        this.stateProgress[state] = this.totals[state];
    }

    setSetupState() {
        if (!this.stateProgress) this.initialize();
        this.state = this.SETUP_STATE;
        this.totalStates = this.SETUP_STEPS;
    }

    setDesignState() {
        if (!this.stateProgress) this.initialize();
        this.state = this.DESIGN_STATE;
        this.totalStates = this.DESIGN_STEPS;
    }

    setParentState() {
        if (!this.stateProgress) this.initialize();
        this.state = this.PARENT_STATE;
        this.totalStates = this.BUILDER_STEPS;
    }
    initialize() {
        this.stateProgress = {};
        this.stateProgress[this.SETUP_STATE] = 0;
        this.stateProgress[this.DESIGN_STATE] = 0;
        this.stateProgress[this.PARENT_STATE] = 0;
        this.totals = {};
        this.totals[this.SETUP_STATE] = this.SETUP_STEPS;
        this.totals[this.DESIGN_STATE] = this.DESIGN_STEPS;
        this.totals[this.PARENT_STATE] = this.BUILDER_STEPS;
    }
}