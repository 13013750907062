﻿import { Component, OnInit } from '@angular/core';
//import { ShoppingCartModule, CartService } from 'ng-shopping-cart';
import { CustomerDesignedDoor } from 'src/app/shared/models';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { BuildADoorService } from 'src/app/shared/services';
import { GDSHCartService } from '../gdsh-cart.service';

@Component({
    selector: 'app-cart-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

    constructor(private cartService: GDSHCartService<CustomerDesignedDoor>, public router: Router, public dbService: BuildADoorService) { }

    ngOnInit() {
    }

    isCheckoutDisabled() {
        if (this.cartService.getItems().length < 1)
            return true;
        return false;
    }

    onBuildAnotherDoorClick() {
        this.dbService.startOver();
        this.router.navigate(['/door-builder-home']);
   }
}
