﻿import { Component, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { DBNavState } from 'src/app/shared/models';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { DOCUMENT, Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-db-nav-menu',
    templateUrl: './db-nav-menu.component.html',
    styleUrls: ['./db-nav-menu.component.css'],
})
export class DBNavMenuComponent {
    private navStateTabDivStyling: Object;
    @ViewChild('dbNavMenu') elementView: ElementRef;
    private pageUrl: string;

    constructor(private router: Router,
        private activeRoute: ActivatedRoute, private dbService: BuildADoorService, @Inject(DOCUMENT) private document: Document, private readonly location: Location) {
    }

    ngOnInit() {
        this.setTabDivStyling();
        if (!this.location.path().includes('/cart') && !this.location.path().endsWith('/checkout') && this.location.path().indexOf('/successful-order') < 0) {
            if (!this.dbService.navMenuEventSubscriber) {
                this.dbService.navMenuEventSubscriber = this.dbService.invokeNavMenuEvent.subscribe(() => {
                    this.nextState();
                });
            }
            if (!this.dbService.navMenuPreviousStateEventSubscriber) {
                this.dbService.navMenuPreviousStateEventSubscriber = this.dbService.invokeNavMenuPreviousStateEvent.subscribe(() => {
                    this.previousState();
                });
            }
            if (!this.dbService.navMenuChangeStateEventSubscriber) {
                this.dbService.navMenuChangeStateEventSubscriber = this.dbService.invokeNavMenuChangeStateEvent.subscribe(() => {
                    this.startState();
                });
            }

            this.router.navigate([this.getCurrentPage()]);
        }
    }

    ngAfterViewInit() {
        this.dbService.dbNavHeight = this.elementView.nativeElement.offsetHeight;
    }

    setTabDivStyling() {
        this.navStateTabDivStyling = {
            'grid-row': '2',
            'display': 'grid',
            'width': '100%',
            'margin': '0 auto',
            //'justify-content': 'space-between',
            'grid-template-rows': '90% 10%'
        };
        var gridStyling = '';
        for (var state in this.dbService.navStates) {
            gridStyling += 'auto ';
        }
        this.navStateTabDivStyling['grid-template-columns'] = gridStyling;
    }

    public nextState() {
        let pageUrl: string = this.dbService.nextState();
        this.router.navigate([pageUrl]);
    }

    public previousState() {
        let pageUrl: string = this.dbService.previousState();
        this.dbService.previousParentState = null;
        this.router.navigate([pageUrl]);
    }

    public startState() {
        let pageUrl: string = this.dbService.startState();
        this.router.navigate([pageUrl]);
    }

    getCurrentPage(): string {
        return this.dbService.getCurrentPage();
    }

    changeState(state: DBNavState) {
        this.dbService.navButtonPressed = true;
        let pageUrl: string = this.dbService.changeState(state);
        if (pageUrl) {
            this.router.navigate([pageUrl]);
        }
    }

    startOver() {
        this.dbService.startOver();
        this.setTabDivStyling();
        this.router.navigate(['/door-builder-home']);
    }

    createURL(route: ActivatedRouteSnapshot): string {
        var url = this.router.routerState.snapshot.url;
        if (route && route.url && route.url.length > 0) {
            url = "";
            for (var i = 0; i < route.url.length; i++) {
                url += "/" + route.url[i];
            }
        }
        return url;
    }

    onClickLogo() {
        this.dbService.startOver();
        this.document.location.href = environment.linkUrl;
        //this.router.navigate(['/']);
    }

    cartView(route: ActivatedRouteSnapshot) {
        this.dbService.navButtonPressed = true;
        //this.router.navigate(['cart'], { queryParams: { returnUrl: this.createURL(route) } });
        this.dbService.setApplicableTaxes(0);
        this.dbService.customerDesignedDoor.discount = [];
        this.dbService.customerDesignedDoor.imageURL = this.dbService.customerDesignedDoor.getImage();
        //let cartItem: GDSHCartItem = new GDSHCartItem({
        //    width: this.getDimensionDisplayValue(this.dbService.customerDesignedDoor.width),
        //    height: this.getDimensionDisplayValue(this.dbService.customerDesignedDoor.height),
        //    productGroupId: this.dbService.customerDesignedDoor.productGroup._id,
        //    designId: this.dbService.customerDesignedDoor.doorDesign._id,
        //    insulationOptionId: this.dbService.customerDesignedDoor.insulation._id,
        //    windowId: this.dbService.customerDesignedDoor.window._id,
        //    colorId: this.dbService.customerDesignedDoor.color._id,
        //    price: this.dbService.getTotalPrice(),
        //    quantity: 1
        //});
        this.router.navigate(['/cart']);
    }
}