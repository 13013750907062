﻿import { Component } from '@angular/core';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';
import { ProductService } from 'src/app/shared/services';

@Component({
    selector: 'app-db-setup',
    templateUrl: './db-setup.component.html',
    styleUrls: ['./db-setup.component.css'],
})
export class DBSetupComponent extends DBStateComponent {
    private hasRates: boolean = false;

    constructor(private productService: ProductService,
        public dbService: BuildADoorService,
        public dbFooterService: DBFooterService,
        public router: Router,
        private activeRoute: ActivatedRoute) {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        super.verifyState();
        //if (!this.dbService.zipcode) {
        //    this.router.navigate(['/door-builder-home']);
        //}

        this.dbFooterService.setSetupState();
        this.dbFooterService.updateParentState(0);
        //this.dbFooterService.totalStates = 3;
        this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumSetupStepsComplete());
        //this.productService.hasLaborRateForZipcode(this.dbService.zipcode).subscribe(result => {
        //    if (result && result['hasLaborRate']) {
        //        this.hasRates = true;
        //    } else {
        //        this.hasRates = false;
        //    }
        //});
        super.ngOnInit();
    }
    
    getCollectionClass(selection) {
        let classes = 'Clickable White-BG ';
        if (selection === this.dbService.INSTALL) classes += ' Install-Button-Container ';
        else classes += ' DIY-Button-Container '
        return classes + super.collectionClass(this.dbService.customerDesignedDoor.deliveryOption, selection);
    }

    localNextState(option) {
        if (this.dbService.customerDesignedDoor.width.feet && this.dbService.customerDesignedDoor.height.feet && !this.dbService.laborRate) {
            this.dbService.getLaborRate();
        }
        this.dbService.customerDesignedDoor.deliveryOption = option;
        super.nextState(this.dbService.customerDesignedDoor.getNumSetupStepsComplete());
    }

}