import { ColorZonePrice } from './color-zone-price';

export class DoorColor {
    _id: string;
    name: string;
    imageURL: string;
    price: number;
    isWoodGrained: boolean;
    code: string;
    childColors: [this];
    footnote: string;
    isColorZone: boolean;
    isWhite: boolean;
    sizeBasedPricing: ColorZonePrice[];
}
