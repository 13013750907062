﻿import { Component, OnInit } from '@angular/core';
//import { ShoppingCartModule } from 'ng-shopping-cart';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html'
})
export class CartComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
