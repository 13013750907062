﻿import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartRoutingModule } from './cart-routing.module';
import { CartComponent } from './cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SummaryComponent } from './summary/summary.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { GDSHCartService } from './gdsh-cart.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CartService } from 'ng-shopping-cart';
import { CategoryLinksModule } from '../shared/components/category-links/category-links.module';
import { FooterModule } from '../shared/components/footer/footer.module';
import { NavMenuModule } from '../nav-menu/nav-menu.module';
import {
    MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
    MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule
} from '@angular/material';
import { GDSHCartViewComponent } from './cart-view/cart-view.component';
import { ModalModule } from '../shared/components/modal/modal.module';
import { DBNavMenuModule } from '../shared/components/db-nav-menu/db-nav-menu.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CartRoutingModule,
        CategoryLinksModule,
        FooterModule,
        NavMenuModule,
        MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
        MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule,
        DBNavMenuModule, 
        ModalModule
    ],
    providers: [{ provide: CartService, useClass: GDSHCartService }],
    declarations: [CartComponent, CheckoutComponent, GDSHCartViewComponent, SummaryComponent, ThankYouComponent]
})
export class CartModule { }
