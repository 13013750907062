﻿import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { ProductService } from '../shared/services/product.service';
import { BuildingZone, DoorStyle, DoorSpecial, ProductGroup, CustomerDesignedDoor } from 'src/app/shared/models';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { NavMenuComponent } from '../nav-menu/nav-menu.component';
import { BuildADoorService } from '../shared/services';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
    private selectedZone: BuildingZone;
    private styles: DoorStyle[];
    private selectedStyle: DoorStyle;
    private activeProductGroups: ProductGroup[];
    private allProductGroups: ProductGroup[];
    private styleButtonDivStyling: Object;
    private isShowAllSelected: boolean = false;
    private scrWidth: number;

    constructor(private productService: ProductService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private dbService: BuildADoorService, private changeDetector: ChangeDetectorRef
    ) {
        activeRoute.params.subscribe(val => {
            let zone = this.activeRoute.snapshot.paramMap.get('zone');
            let style = this.activeRoute.snapshot.paramMap.get('style');
            if (!this.styles) {
                this.loadStyles(zone, style);
            } else {
                this.setSelectedStyle(style);
            }
        });
    }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

    loadStyles(zone, style) {
        // TODO - get styles for zone, change API to match getStyles
        this.productService.getStyles().subscribe(result => {
            if (result && result['styles']) {
                this.styles = result['styles'];
                this.styleButtonDivStyling = { };
                var gridStyling = '';
                this.allProductGroups = [];
                this.activeProductGroups = [];
                if (this.styles && this.styles.length > 0) {
                    for (var i = 0; i < this.styles.length; i++) {
                        gridStyling += 'auto ';
                        if (this.styles[i].productGroups && this.styles[i].productGroups.length > 0) {
                            this.allProductGroups.push.apply(this.allProductGroups, this.styles[i].productGroups);
                            for (var pgIdx = 0; pgIdx < this.styles[i].productGroups.length; pgIdx++) {
                                this.styles[i].productGroups[pgIdx].style = new DoorStyle({ name: this.styles[i].displayName });
                            }
                        }
                    }
                    gridStyling += 'auto';
                    this.setSelectedStyle(style);
                }
                this.styleButtonDivStyling['grid-template-columns'] = gridStyling;
            }
        });
    }

    setSelectedStyle(style) {
        if (this.styles && this.styles.length > 0) {
            if (style) {
                let foundStyle = this.styles.find(s => s.id === style);
                if (foundStyle) {
                    this.selectedStyle = foundStyle;
                    this.activeProductGroups = foundStyle.productGroups;
                    return;
                }
            } else {
                this.onSelectShowAll();
            }
            //this.selectedStyle = this.styles[0];
            //this.activeProductGroups = this.styles[0].productGroups;
        }
    }

    ngOnInit() {
        let zone = this.activeRoute.snapshot.paramMap.get('zone');
        let style = this.activeRoute.snapshot.paramMap.get('style');
        if (!this.styles) {
            this.loadStyles(zone, style);
        } else {
            this.setSelectedStyle(style);
        }
    }

    getBuildingZones() {
        this.productService.getBuildingZones().subscribe(result => {
            if (result && result['buildingZones']) {
                var zones = result['buildingZones'];
                if (zones && zones.length == 1) {
                    this.getDoorStyles(zones[0]);
                }
            }
        });
    }
    getDoorStyles(zone: BuildingZone) {
        if (this.selectedZone) {
            this.productService.getStylesForZone(this.selectedZone).subscribe(result => {
                if (result && result['styles']) {
                    this.styles = result['styles'];
                }
            });
        }
    }

    buttonClass(s) {
        if (s === this.selectedStyle) {
            return 'Button Button-Selected';
        }
        return 'Button Button-Unselected';
    }

    buttonClassShowAll() {
        if (this.isShowAllSelected) {
            return 'Button Button-Selected';
        }
        return 'Button Button-Unselected';
    }


    onSelectStyle(s) {
        if (s) this.selectedStyle = s;
        this.activeProductGroups = this.selectedStyle.productGroups;
        this.isShowAllSelected = false;
    }

    onCustomizeAndPrice(pg) {
        this.dbService.customerDesignedDoor = new CustomerDesignedDoor();
        this.dbService.selectedStyle = this.selectedStyle;
        this.dbService.customerDesignedDoor.productGroup = pg;
        //this.dbService.customerDesignedDoor.productGroup.style = this.selectedStyle;
        this.router.navigate(['/door-builder-home']);
    }
    onProductDetails(pg) {
        this.router.navigate(['/product-details/' + pg.urlFriendlyId]);
    }

    onSelectShowAll() {
        this.isShowAllSelected = true;
        this.activeProductGroups = this.allProductGroups;
        this.selectedStyle = null;
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
    }

    isMobile() {
        if (!this.scrWidth) this.getScreenSize();
        return this.scrWidth <= 1224
    }
}
