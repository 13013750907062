﻿import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, HostListener, ChangeDetectorRef } from '@angular/core';
import { OrderService, ModalService, BuildADoorService } from 'src/app/shared/services';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent {
    contactForm: FormGroup;
    disabledSubmitButton: boolean = true;
    optionsSelect: Array<any>;

    @HostListener('input') oninput() {

        if (this.contactForm.valid) {
            this.disabledSubmitButton = false;
        }
    }

    constructor(private fb: FormBuilder, private orderService: OrderService, private modalService: ModalService, private dbService: BuildADoorService, private changeDetector: ChangeDetectorRef) {

        this.contactForm = fb.group({
            'contactFormName': ['', Validators.required],
            'contactFormEmail': ['', Validators.compose([Validators.required, Validators.email])],
            'contactFormMessage': ['', Validators.required],
            'contactFormCopy': [''],
        });
    }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

    onSubmit() {
        let customer = {
            contact: {
                emailAddress: this.contactForm.get('contactFormEmail').value,
                firstName: this.contactForm.get('contactFormName').value
            },
        };
        let message = this.contactForm.get('contactFormMessage').value;

        this.orderService.contactUs(customer, message).subscribe(result => {
            this.contactForm.reset();
            this.disabledSubmitButton = true;
            if (result && result['contact']) {
                // popup modal saying thanks
                this.showThankYouWindow();
            }
        }, error => {
            console.log('Error', error);
        });
    }

    showThankYouWindow() {
        this.modalService.open('thank-you');
    }

    hideThankYouWindow() {
        this.modalService.close('thank-you');
    }

}