import { DoorStyle } from './door-style';
import { DoorSpecial } from './door-special';
import { ColorZonePrice } from './color-zone-price';

export class ProductGroup {
  style: DoorStyle;
    _id: string;
    urlFriendlyId: string;
    identifier: string;
  name: string;
    display_name: string;
    abbreviation: string;
  description: string;
    imageURL: string;
    special: DoorSpecial;
    numDoorDesigns: number;
    numInsulationOptions: number;
    minBasePrice: string;
    windLoadPrice: string;
    brochureURL: string;
    hasColorZone: boolean;
    colorZonePricing: ColorZonePrice[];
};