﻿export class Paytrace {
    hookFormSubmit: any;
    encryptValue: any;
    setKeyAjax: any;
    submitEncrypted: any;
    cloneForm: any;
    setKey: any;

    constructor(jsVersion: any) {
        if (jsVersion) {
            this.hookFormSubmit = jsVersion['hookFormSubmit'];
            this.encryptValue = jsVersion['encryptValue'];
            this.setKeyAjax = jsVersion['setKeyAjax'];
            this.submitEncrypted = jsVersion['submitEncrypted'];
            this.cloneForm = jsVersion['cloneForm'];
            this.setKey = jsVersion['setKey'];
        }
    }
}