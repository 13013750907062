﻿export class FAQ {
    _id: string;
    question: string;
    answer: string;
    isOpen: boolean = false;

    constructor(faq?) {
        if (faq) {
            this._id = faq._id;
            this.question = faq.question;
            this.answer = faq.answer;
        }
    }

}