import { DoorStyle , BuildingZone, ProductGroup, Product, DoorInsulationLevel } from './index';

export class DoorSpecial {
    _id: string;
    title: string;
    description: string;
  zone: BuildingZone;
  style: DoorStyle;
  productGroup: ProductGroup;
  product: Product;
  insulationLevel: DoorInsulationLevel;
  MPH: string;
  effectiveDate: Date;
  expirationDate: Date;
  width: number;
  height: number;
  salePrice: number;
  markdown: number;

  selected: boolean;

  constructor(s?) {
    if (s) {
        this._id = s._id;
        this.title = s.title;
        this.description = s.description;
      this.zone = s.zone;
      this.style = s.style;
      this.productGroup = s.productGroup;
      this.product = s.product;
      this.insulationLevel = s.insulationLevel;
      this.MPH = s.MPH;
      this.effectiveDate = s.effectiveDate;
      this.expirationDate = s.expirationDate;
      this.width = s.width;
      this.height = s.height;
      this.salePrice = s.salePrice;
      this.markdown = s.markdown;
      this.selected = false;
    }
  }

}