﻿import { Headroom } from './index';

export class TrackAndSpring {
    _id: string;
    name: string;
    headroom: Headroom;
    minHeight: number;
    maxHeight: number;
    minWidth: number;
    minHeadroom: string;
    price: number;
    isExtensionSpring: boolean;
    details: string[];
    displayOrder: number;
    isLowHeadroom: boolean;
    isVerticalLift: boolean;
    isHighLift: boolean;
    isRoofPitch: boolean;

    constructor(tas?) {
        if (tas) {
            this._id = tas._id;
            this.name = tas.name;
            this.headroom = tas.headroom;
            this.minHeadroom = tas.minHeadroom;
            this.minHeight = tas.minHeight;
            this.maxHeight = tas.maxHeight;
            this.minWidth = tas.minWidth;
            this.price = tas.price;
            this.isExtensionSpring = tas.isExtensionSpring;
            this.details = tas.details ? tas.details : [];
            this.displayOrder = tas.displayOrder;
            this.isLowHeadroom = tas.isLowHeadroom;
            this.isVerticalLift = tas.isVerticalLift;
            this.isHighLift = tas.isHighLift;
            this.isRoofPitch = tas.isRoofPitch;
        }
    }
}