﻿import { Component } from '@angular/core';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { BuildADoorService, DBFooterService, ProductService } from 'src/app/shared/services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-operator-prep-options',
    templateUrl: './operator-prep-options.component.html',
    styleUrls: ['./operator-prep-options.component.css'],
})
export class DBOperatorPrepOptionsComponent extends DBStateComponent {
    private buttonClasses: Object = {};

    constructor(public dbService: BuildADoorService, public router: Router, public dbFooterService: DBFooterService, private productService: ProductService) {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        this.buttonClasses[this.dbService.NO_OPERATOR_LABEL] = 'White-BG DB-Button-Container';
        this.buttonClasses[this.dbService.HAVE_OPERATOR_LABEL] = 'White-BG DB-Button-Container';
        this.buttonClasses[this.dbService.BUYING_OPERATOR_LABEL] = 'White-BG DB-Button-Container';
        if (this.dbService.operatorPrepOption) {
            this.buttonClasses[this.dbService.operatorPrepOption] += ' Div-Selected';
        } else {
            if (!this.dbService.extras) {
                this.productService.getDoorExtras(this.dbService.customerDesignedDoor).subscribe(result => {
                    if (result) {
                        if (result['doorExtras']) {
                            this.dbService.possibleExtras = result['doorExtras'];
                            this.dbService.possibleExtras['extras'].forEach(pe => {
                                pe.quantity = 0;
                            });
                            this.dbService.extras = this.dbService.possibleExtras['extras'];
                            if (this.dbService.possibleExtras['openers']) {
                                this.dbService.extras = this.dbService.extras.concat(this.dbService.possibleExtras['openers'].filter(ex => ex.isOperator === false));
                            }
                            this.buttonClick(this.dbService.HAVE_OPERATOR_LABEL);
                        }
                    }
                });
            } else {
                this.buttonClick(this.dbService.HAVE_OPERATOR_LABEL);
            }
        }
        super.ngOnInit();
    }

    buttonClick(button) {
        this.buttonClasses[this.dbService.HAVE_OPERATOR_LABEL] = 'White-BG DB-Button-Container';
        this.buttonClasses[this.dbService.NO_OPERATOR_LABEL] = 'White-BG DB-Button-Container';
        this.buttonClasses[this.dbService.BUYING_OPERATOR_LABEL] = 'White-BG DB-Button-Container';
        this.buttonClasses[button] += ' Div-Selected';
        if (button === this.dbService.NO_OPERATOR_LABEL) {
            this.dbService.customerDesignedDoor.needsOperatorInstall = false;
        } else {
            this.dbService.customerDesignedDoor.needsOperatorInstall = true;
        }
        if (button !== this.dbService.operatorPrepOption) {
            if (button === this.dbService.HAVE_OPERATOR_LABEL || button === this.dbService.BUYING_OPERATOR_LABEL) {
                // make sure autoselects are handled by adding all auto-selects
                if (this.dbService.extras && this.dbService.extras.length > 0) {
                    let autoSelectExtras = this.dbService.extras.filter(e => e.isAutoSelect);
                    for (var i = 0; i < autoSelectExtras.length; i++) {
                        let found = this.dbService.customerDesignedDoor.extras.find(ce => ce._id === autoSelectExtras[i]._id);
                        if (!found) this.dbService.customerDesignedDoor.extras.push(autoSelectExtras[i]);
                    }
                }
            } else {
                // update selected by removing auto-selects
                if (this.dbService.customerDesignedDoor.extras && this.dbService.customerDesignedDoor.extras.length > 0) {
                    this.dbService.customerDesignedDoor.extras = this.dbService.customerDesignedDoor.extras.filter(e => !e.isAutoSelect);
                }
            }
            this.dbService.operatorPrepOption = button;
        }
        if (button === this.dbService.BUYING_OPERATOR_LABEL) {
            this.router.navigate(['/door-builder/design/operators-openers']);
        } else {
            this.dbService.customerDesignedDoor.opener = null;
        }
    }

    buttonClass(button) {
        return this.buttonClasses[button];
    }

    isShowContinue() {
        if (this.dbService.operatorPrepOption) return true;
        return false;
    }

    onClickContinue() {
        if (!this.dbService.hasViewedLocks) {
            this.router.navigate(['/door-builder/design/locks']);
        } else {
            this.router.navigate(['/door-builder/design/extras-home']);
        }
    }
}