﻿import { Component } from '@angular/core';
import { BuildADoorService } from 'src/app/shared/services/build-a-door.service';
import { ProductGroup } from 'src/app/shared/models/product-group';
import { DoorStyle } from 'src/app/shared/models';
import { ProductService } from 'src/app/shared/services/product.service';
import { DBStateComponent } from '../../shared/components/db-state.component';
import { Router } from '@angular/router';
import { DBFooterService } from 'src/app/shared/services/db-footer.service';

@Component({
    selector: 'app-select-collection',
    templateUrl: './select-collection.component.html',
    styleUrls: ['./select-collection.component.css'],
})
export class DBDesignSelectCollectionComponent extends DBStateComponent {
    //private productGroups: Object[];
    private styles: DoorStyle[];

    constructor(public dbService: BuildADoorService,
        private productService: ProductService,
        public dbFooterService: DBFooterService,
        public router: Router) {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        super.verifyState();
        this.dbService.getStyles().subscribe(result => {
            if (result && result['styles']) {
                this.styles = result['styles'];
                this.setCollectionWrapperStyling(this.styles);
                if (this.dbService.customerDesignedDoor && this.dbService.customerDesignedDoor.productGroup && !this.dbService.selectedStyle) {
                    let styleToSet = this.styles.find(s => s._id === this.dbService.customerDesignedDoor.productGroup.style._id);
                    this.dbService.selectedStyle = styleToSet;
                    this.router.navigate(['/door-builder/design/select-model']);
                }
            }
        });

        super.ngOnInit();
    }

    getCollectionStyle(index) {
        let styling = super.collectionStyle(index, true);
        if (!this.isMobile()) {
            var colNum = 1 + (index % 2);
            styling['grid-column'] = '' + colNum;
        }
        return styling;
    }

    getCollectionClass(style) {
        return super.collectionClass(this.dbService.selectedStyle, style);
    }

    onClickStyle(style) {
        if (!this.dbService.selectedStyle || style._id != this.dbService.selectedStyle._id) {
            this.dbService.customerDesignedDoor.productGroup = null;
            this.dbService.removeSelections();
        }
        this.dbService.selectedStyle = style;
        this.dbService.price = 0;
        this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
        this.router.navigate(['/door-builder/design/design-home'])
    }
}