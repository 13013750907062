﻿import { ProductGroup } from './product-group';
import { DoorInsulationLevel } from './door-insulation-level';
import { WidthBasedPricing } from './width-based-pricing';

export class GlassType {
    _id: string;
    name: string;
    code: string;
    imageURL: string;
    isInsulated: boolean;
    productGroup: ProductGroup;
    insulationLevel: DoorInsulationLevel;
    insulatedVersion: this;
    price: number;
    widthBasedPricing: WidthBasedPricing[];
    customPrice: number;
}
