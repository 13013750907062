﻿import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BuildADoorService } from '../../services';

@Component({
    selector: 'app-category-links',
    templateUrl: './category-links.component.html',
    styleUrls: ['./category-links.component.css']
})
export class CategoryLinksComponent {
    constructor(private router: Router, private dbService: BuildADoorService) { }

    onClickBuildDoor() {
        this.dbService.startOver();
        this.router.navigate(['/door-builder-home']);
    }
}
