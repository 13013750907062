﻿import { Component } from '@angular/core';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { DoorExtra } from 'src/app/shared/models';
import { BuildADoorService, ProductService, DBFooterService } from 'src/app/shared/services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-extras',
    templateUrl: './extras.component.html',
    styleUrls: ['./extras.component.css'],
})
export class DBExtrasComponent extends DBStateComponent {
    private extras: DoorExtra[];

    constructor(public dbService: BuildADoorService, public router: Router, public dbFooterService: DBFooterService, private productService: ProductService) {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        if (!this.dbService.customerDesignedDoor.doorDesign) {
            this.router.navigate(['/door-builder/design/design-home']);
        }

        this.dbService.hasViewedExtras = true;
        if (!this.dbService.possibleExtras) {
            this.productService.getDoorExtras(this.dbService.customerDesignedDoor).subscribe(result => {
                if (result) {
                    if (result['doorExtras']) {
                        this.dbService.possibleExtras = result['doorExtras'];
                        this.dbService.possibleExtras['extras'].forEach(pe => {
                            pe.quantity = 0;
                        });
                        this.extras = this.dbService.possibleExtras['extras'];
                        this.dbService.extras = this.dbService.possibleExtras['extras'];
                        if (this.dbService.possibleExtras['openers']) {
                            if (this.dbService.customerDesignedDoor.trackAndSpring && this.dbService.customerDesignedDoor.trackAndSpring.isLowHeadroom) {
                                this.dbService.extras = this.dbService.extras.concat(this.dbService.possibleExtras['openers'].filter(ex => ex.isOperator === false && !ex.noLowHeadroom));
                            } else {
                                this.dbService.extras = this.dbService.extras.concat(this.dbService.possibleExtras['openers'].filter(ex => ex.isOperator === false));
                            }
                        }
                        this.setCollectionWrapperStyling(this.extras);
                        this.setupExtras();
                    }
                }
            });
        } else {
            this.extras = this.dbService.possibleExtras['extras'];
            this.setCollectionWrapperStyling(this.extras);
            this.setupExtras();
        }
        this.extras.forEach(e => {
            let existing = this.dbService.customerDesignedDoor.extras.find(h => h._id === e._id);
            if (existing) e.quantity = existing.quantity;
            else e.quantity = 0;
        });
        super.ngOnInit();
    }

    setupExtras() {
        if (this.dbService.operatorPrepOption == this.dbService.HAVE_OPERATOR_LABEL) {
            // if extra .isAutoSelected then show and select, otherwise hide
            for (var i = 0; i < this.extras.length; i++) {
                let ex = this.extras[i];
                if (ex.isAutoSelect) {
                    this.checkSelected(ex, true);
                    ex.isHidden = true;
                } else {
                    ex.isHidden = false;
                }
            }
        } else {
            // show all, do not auto select any
            for (var i = 0; i < this.extras.length; i++) {
                let ex = this.extras[i];
                if (ex.isAutoSelect) {
                    this.checkSelected(ex, false);
                    ex.isHidden = true;
                } else {
                    // hide
                    ex.isHidden = false;
                }
            }
        }
        let openerExtras = []
        if (this.dbService.possibleExtras['openers']) {
            if (this.dbService.customerDesignedDoor.trackAndSpring && this.dbService.customerDesignedDoor.trackAndSpring.isLowHeadroom) {
                openerExtras = this.dbService.possibleExtras['openers'].filter(ex => ex.isOperator === false && !ex.isLowHeadroom);
            } else {
                openerExtras = this.dbService.possibleExtras['openers'].filter(ex => ex.isOperator === false);
            }
            openerExtras.forEach(oe => {
                if (this.dbService.customerDesignedDoor.opener && this.dbService.customerDesignedDoor.opener._id === oe._id)
                    oe.quantity = this.dbService.customerDesignedDoor.opener.quantity;
                else
                    oe.quantity = 0;
            });
        }
        this.extras = this.extras.concat(openerExtras);
        for (var i = 0; i < openerExtras.length; i++) {
            let oe = openerExtras[i];
            oe.isHidden = false;
            //this.checkSelected(oe, false);
        }
    }

    //getQuantity(e) {
    //    return e.quantity;
    //}

    //incrementQuantity(e) {
    //    let selectedExtra = this.dbService.customerDesignedDoor.extras.find(ex => ex._id === e._id);
    //    if (selectedExtra) {
    //        event.stopPropagation();
    //        selectedExtra.quantity++;
    //    }
    //}

    //decrementQuantity(e) {
    //    let selectedExtra = this.dbService.customerDesignedDoor.extras.find(ex => ex._id === e._id);
    //    if (selectedExtra) {
    //        if (selectedExtra.quantity > 0) {
    //            selectedExtra.quantity--;
    //        }
    //        if (selectedExtra.quantity > 0) {
    //            event.stopPropagation();
    //        }
    //    }
    //}

    checkSelected(ex, invert) {
        let selectedExtra = this.dbService.customerDesignedDoor.extras.find(e => e._id === ex._id);
        if ((selectedExtra && !invert) || (!selectedExtra && invert)) this.onClickExtra(ex);
    }

    onClickExtra(e) {
        let found = this.dbService.customerDesignedDoor.extras.find(ce => ce._id === e._id);
        if (!found) {
            e.quantity = 1;
            this.dbService.customerDesignedDoor.extras.push(e);
        }
        else if (!e.isAutoSelect) {
            this.dbService.customerDesignedDoor.extras = this.dbService.customerDesignedDoor.extras.filter(ce => ce._id !== e._id);
            found.quantity = 0;
        }
    }

    getCollectionStyle(e, index) {
        if (e.isHidden) return { 'display': 'none' };
        let numHiddenBefore = 0;
        for (var i = 0; i < this.extras.length; i++) {
            if (this.extras[i]._id === e._id) break;
            if (this.extras[i].isHidden) numHiddenBefore++;
        }
        index = index - numHiddenBefore;
        let style = super.collectionStyle(index, true);
        if (!this.isMobile()) {
            var colNum = 1 + (index % 2);
            style['grid-column'] = '' + colNum;
        }
        return style;
    }

    getCollectionClass(e) {
        let existing = this.dbService.customerDesignedDoor.extras.find(h => h._id === e._id);
        if (existing) {
            return 'Collection-Button-Selected';
        } else {
            return 'Collection-Button-Unselected';
        }
    }

    getButtonText() {
        if (this.dbService.customerDesignedDoor.extras && this.dbService.customerDesignedDoor.extras.length > 0)
            return 'Continue';
        return 'No thanks';
    }

    getButtonClass() {
        if (this.dbService.customerDesignedDoor.extras && this.dbService.customerDesignedDoor.extras.length > 0) return 'Rectangle';
        return 'Changed-Mind-Button';
    }
}