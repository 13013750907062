﻿export class DBNavState {
    id: number;
    index: number;
    name: string;
    pageURL: string;
    next: DBNavState;
    subStates: DBNavState[];
    parentState: DBNavState;
    activeSubState: DBNavState;
    isActive: boolean = false;
    footerMessage: string;
    separatorClass: string;
    activeClass: string = "dbNavState-Active";
    activeSeparatorClass: string = "dbNavStateSeparator-Active";
    inactiveClass: string = "dbNavState-Inactive";
    inactiveSeparatorClass: string = "dbNavStateSeparator-Inactive";
    class: string = this.inactiveClass;
    isStateComplete: Function;

    constructor(ns?) {
        if (ns) {
            this.id = ns.id;
            this.index = ns.index;
            this.name = ns.name;
            this.pageURL = ns.pageURL;
            this.isActive = ns.isActive;
            this.footerMessage = ns.footerMessage;
            this.subStates = ns.subStates;
            this.parentState = ns.parentState;
            this.isStateComplete = ns.isStateComplete;
            this.getClass();
        }
    }

    nextState(): DBNavState {
        this.isActive = false;
        if (this.next) {
            this.next.isActive = true;
            this.getClass();
            this.next.getClass();
            return this.next;
        }
        this.parentState.next.isActive = true;
        this.parentState.getClass();
        this.parentState.next.getClass();
        return this.parentState.next;
    }

    activate() {
        this.isActive = true;
        this.getClass();
    }

    deactivate() {
        this.isActive = false;
        this.getClass();
    }

    getClass() {
        if (this.isActive) {
            this.class = this.activeClass;
            this.separatorClass = this.activeSeparatorClass;
        } else {
            this.class = this.inactiveClass;
            this.separatorClass = this.inactiveSeparatorClass;
        }
    }

    hasSubstates() {
        if (this.subStates != null && this.subStates.length > 0)
            return true;
        return false;
    }

    nextSubstate(activeSubState: DBNavState): DBNavState {
        if (!this.hasSubstates())
            return null;
        if (!activeSubState)
            return this.subStates[0];
        if (activeSubState.index == this.subStates.length) 
            return null;
        return this.subStates[activeSubState.index];
    }

    isOnLastSubstate() {
        if (!this.hasSubstates())
            return true;
        if (!this.activeSubState)
            return false;
        if (this.activeSubState.index === this.subStates.length)
            return true;
        return false;
    }
}