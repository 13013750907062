﻿export class NavButton {
    id: number;
    index: number;
    name: string;
    pageURL: string;
    callback: Function;
    isActive: boolean = false;
    activeClass: string = "NavButton-Active";
    inactiveClass: string = "NavButton-Inactive";
    class: string = this.inactiveClass;

    constructor(ns?) {
        if (ns) {
            this.id = ns.id;
            this.index = ns.index;
            this.name = ns.name;
            this.pageURL = ns.pageURL;
            this.callback = ns.callback;
            this.isActive = ns.isActive;
            this.getClass();
        }
    }

    activate() {
        this.isActive = true;
        this.getClass();
    }

    deactivate() {
        this.isActive = false;
        this.getClass();
    }

    getClass() {
        if (this.isActive) {
            this.class = this.activeClass;
        } else {
            this.class = this.inactiveClass;
        }
    }
}