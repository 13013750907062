﻿import { Component, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { ProductService, ModalService, BuildADoorService } from '../shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { DoorStyle } from '../shared/models';
import { GalleryEntry } from '../shared/models/gallery-entry';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
    private styles: DoorStyle[];
    private selectedStyle: DoorStyle;
    private activeGalleryImages: Object[];
    private allGalleryImages: Object[];
    private styleButtonDivStyling: Object;
    private isShowAllSelected: boolean = false;
    private scrWidth: number;
    private imageGridStyling: Object;
    private modalTitle: string;
    private modalImage: string;

    constructor(private productService: ProductService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private modalService: ModalService,
        private dbService: BuildADoorService,
        private changeDetector: ChangeDetectorRef) { }
    ngOnInit() {
        let zone = this.activeRoute.snapshot.paramMap.get('zone');
        let style = this.activeRoute.snapshot.paramMap.get('style');
        if (!this.styles) {
            this.loadStyles(zone, style);
        } else {
            this.setSelectedStyle(style);
        }
    }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

    loadStyles(zone, style) {
        // TODO - get styles for zone, change API to match getStyles
        this.productService.getStyles().subscribe(result => {
            if (result && result['styles']) {
                this.styles = result['styles'];
                this.styleButtonDivStyling = {};
                var gridStyling = '';
                this.allGalleryImages = [];
                this.activeGalleryImages = [];
                if (this.styles && this.styles.length > 0) {
                    for (var i = 0; i < this.styles.length; i++) {
                        gridStyling += 'auto ';
                        if (this.styles[i].galleryEntries && this.styles[i].galleryEntries.length > 0) {
                            this.allGalleryImages.push.apply(this.allGalleryImages, this.styles[i].galleryEntries);
                        }
                    }
                    gridStyling += 'auto';
                    this.setSelectedStyle(style);
                }
                this.styleButtonDivStyling['grid-template-columns'] = gridStyling;
            }
        });
    }

    setSelectedStyle(style) {
        if (this.styles && this.styles.length > 0) {
            if (style) {
                let foundStyle = this.styles.find(s => s.id === style);
                if (foundStyle) {
                    this.selectedStyle = foundStyle;
                    this.activeGalleryImages = foundStyle.galleryEntries;
                    return;
                }
            }
            this.selectedStyle = this.styles[0];
            this.activeGalleryImages = this.styles[0].galleryEntries;
        }
        this.imageGridStyling = this.setImageGridStyle(this.activeGalleryImages);
    }

    setImageGridStyle(collection) {
        let imageGridStyling = {
            'text-align': 'center',
            'display': 'grid',
            'margin': '5px 40px 5px 0',
            'overflow-y': 'auto'
        };
        var gridStyling = '';
        if (this.isMobile()) {
            imageGridStyling['grid-template-rows'] = 'minmax(min-content, max-content)';
            imageGridStyling['grid-auto-columns'] = '50% 50%';
        } else {
            imageGridStyling['grid-auto-columns'] = '25% 25% 25% 25%';
            var gridStyling = '';
            if (collection && collection.length > 0) {
                for (var i = 1; i <= collection.length; i++) {
                    if (this.isMobile()) {
                        gridStyling += 'auto ';
                    } else if (i % 2 == 1) {
                        gridStyling += 'auto ';
                    }
                }
                gridStyling += 'auto';
            }
            imageGridStyling['grid-template-rows'] = gridStyling;
        }
        return imageGridStyling;
    }

    getImageContainerStyle(index: number) {
        let modBy = 4;
        let padding = '10px';
        if (this.isMobile()) {
            modBy = 2;
            padding = '1px';
        }
        var colNum = 1 + (index % modBy);
        return { 
            'grid-column': '' + colNum,
            'padding': padding
         };
    }

    onClickImage(galleryEntry: GalleryEntry) {
        // TODO
        this.modalTitle = galleryEntry.description;
        this.modalImage = galleryEntry.largeImageURL;
        this.showCloseUpWindow();
    }

    buttonClass(s) {
        if (s === this.selectedStyle) {
            return 'Button Button-Selected';
        }
        return 'Button Button-Unselected';
    }

    buttonClassShowAll() {
        if (this.isShowAllSelected) {
            return 'Button Button-Selected';
        }
        return 'Button Button-Unselected';
    }


    onSelectStyle(s) {
        if (s) this.selectedStyle = s;
        this.activeGalleryImages = this.selectedStyle.galleryEntries;
        this.isShowAllSelected = false;
        this.imageGridStyling = this.setImageGridStyle(this.activeGalleryImages);
    }
    onSelectShowAll() {
        this.isShowAllSelected = true;
        this.activeGalleryImages = this.allGalleryImages;
        this.selectedStyle = null;
    }

    showCloseUpWindow() {
        this.modalService.open('close-up');
    }

    hideCloseUpWindow() {
        this.modalService.close('close-up');
    }

    onClickBuildDoor() {
        this.hideCloseUpWindow();
        this.dbService.startOver();
        this.router.navigate(['/door-builder-home']);
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
    }

    isMobile() {
        if (!this.scrWidth) this.getScreenSize();
        return this.scrWidth <= 1224
    }
}