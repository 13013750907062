﻿import { Component, OnInit, AfterViewInit, ElementRef, Inject, ViewChild } from '@angular/core';
//import { ShoppingCartModule, CartService } from 'ng-shopping-cart';
import { Paytrace, CustomerDesignedDoor } from 'src/app/shared/models';
import { ProductService, ModalService, BuildADoorService } from 'src/app/shared/services';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OrderService } from 'src/app/shared/services/order.service';
import { GDSHCartService } from '../gdsh-cart.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

declare var paypal;  
@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, AfterViewInit {
    @ViewChild('paypal') paypalElement: ElementRef;  
    state = new FormControl(null);
    zipCode = new FormControl(null);
    firstName = new FormControl(null);
    lastName = new FormControl(null);
    emailAddress = new FormControl(null);
    phoneNumber = new FormControl(null);
    streetAddress1 = new FormControl(null);
    streetAddress2 = new FormControl(null);
    city = new FormControl(null);
    shippingFirstName = new FormControl(null);
    shippingLastName = new FormControl(null);
    shippingPhoneNumber = new FormControl(null);
    shippingStreetAddress1 = new FormControl(null);
    shippingStreetAddress2 = new FormControl(null);
    shippingCity = new FormControl(null);
    shippingState = new FormControl(null);
    shippingZipCode = new FormControl(null);
    taxForm: FormGroup;
    paypalShowing: boolean = false;
    dropInstance;
    nonceVal = new FormControl(null);
    isPlaceOrderDisabled = false;
    paypalInitialized: boolean = false;
    private STATE_EMAIL = "EMAIL";
    private STATE_BILLING_ADDRESS = "BILLING_ADDRESS";
    private STATE_SHIPPING_ADDRESS = "SHIPPING_ADDRESS";
    private STATE_PAYMENT_INFO = "PAYMENT_INFO";
    public openState: string = this.STATE_EMAIL;
    private scrWidth: any;

    comments = new FormControl(null);
    cc = new FormControl(null,
        {
            validators: Validators.compose([Validators.required,
            Validators.pattern('^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$')
            ]),
            updateOn: 'blur'
        });
    expiry = new FormControl(null,
        {
            validators: Validators.compose([Validators.required,
            Validators.maxLength(5), Validators.minLength(4)]),
            updateOn: 'blur'
        });
    cvc = new FormControl(null, Validators.required);
    paymentType = new FormControl("credit", Validators.required)
    ccForm: FormGroup;
    cartItems: CustomerDesignedDoor[];
    private totalTaxes: number = 0.0;
    private totalTaxesDisplayed: string = '0.00';
    private totalPrice: number = 0.0;
    private totalPriceDisplayed: string = '0.00';
    private total: number = 0.0;
    private totalDisplayed: string = '0.00';
    private shipping: number = this.cartService.getShipping();
    private shippingDisplayed: string = this.shipping.toFixed(2);
    paytrace: Paytrace;
    ptBearerToken: string;
    isShippingAddressIncluded: boolean = true;
    isHideDeliveryAreaWarning: boolean = true;

    constructor(private cartService: GDSHCartService<CustomerDesignedDoor>,
        private formBuilder: FormBuilder,
        private productService: ProductService,
        private orderService: OrderService,
        private modalService: ModalService,
        @Inject(DOCUMENT) private document,
        private elementRef: ElementRef,
        public http: HttpClient,
        public router: Router,
        public dbService: BuildADoorService,
        @Inject('IS_TEST') private isTest: boolean)
    {
        this.taxForm = this.formBuilder.group({
            state: this.state,
            zipCode: this.zipCode,
            firstName: this.firstName,
            lastName: this.lastName,
            streetAddress1: this.streetAddress1,
            streetAddress2: this.streetAddress2,
            city: this.city
        });
        this.ccForm = this.formBuilder.group({
            comments: this.comments,
            cc: this.cc,
            expiry: this.expiry,
            cvc: this.cvc,
            paymentType: this.paymentType,
            nonce: this.nonceVal
        });
        this.openState = this.STATE_EMAIL;
    }
    ngOnInit() {
        if (this.zipCode.value) {
            let applicableZip = this.zipCode.value;
            if (!this.isShippingAddressIncluded) {
                applicableZip = this.shippingZipCode.value;
            }
            this.orderService.isValidZipcode(applicableZip).subscribe(result => {
                if (result && result['validZipcode'] !== undefined) {
                    this.isHideDeliveryAreaWarning = result['validZipcode'];
                    this.isPlaceOrderDisabled = !this.isHideDeliveryAreaWarning;
                    if (!this.isPlaceOrderDisabled) {
                        this.orderService.calculateShippingByDistance(this.getCustomer()).subscribe(result => {
                            if (result) {
                                console.log("result = " + result);
                                this.dbService.additionalShipping = result['additionalShipping'];
                                this.shipping = this.getShippingCost(this.cartItems);
                            }
                        });
                    }
                }
            });
        } else {
            this.isHideDeliveryAreaWarning = true;
            this.isPlaceOrderDisabled = false;
        }

        this.isHideDeliveryAreaWarning = true;
        this.cartItems = this.cartService.getItems();
        this.cartItems.forEach(item => {
            if (item.getPrice()) {
                this.totalPrice += item.getQuantity() * (typeof item.getPrice() === 'string' ? parseFloat(item.getPrice()) : item.getPrice());
            }
        });
        this.totalPrice = Math.ceil(this.totalPrice * 100) / 100;
        this.totalPriceDisplayed = this.totalPrice.toFixed(2);
        this.shipping = this.getShippingCost(this.cartItems);
        this.shippingDisplayed = this.shipping.toFixed(2);
        //this.totalPrice += this.shipping;
        this.total = this.totalPrice + this.shipping;
        this.totalDisplayed = this.total.toFixed(2);

        //this.orderService.getPaypalToken().subscribe(result => {
        //    braintree.client.create({
        //        authorization: result['clientToken'],
        //    }).then((clientInstance) => {
        //        braintree.hostedFields.create(
        //            {
        //                client: clientInstance,
        //                styles: {
        //                    input: {
        //                        "font-size": "16pt",
        //                        color: "#3A3A3A",
        //                    },

        //                    ".number": {
        //                        "font-family": "monospace",
        //                    },

        //                    ".valid": {
        //                        color: "green",
        //                    },
        //                },
        //                fields: {
        //                    number: {
        //                        selector: "#card-number",
        //                    },
        //                    cvv: {
        //                        selector: "#cvv",
        //                    },
        //                    expirationDate: {
        //                        selector: "#expiration-date",
        //                    },
        //                },
        //            },
        //        );
        //    });
        //});
    }

    ngAfterViewInit() {
        const s = this.document.createElement('script');
        s.type = 'text/javascript';
        //s.src = '/assets/local-paytrace.js';
        s.src = '//api.paytrace.com/assets/e2ee/paytrace-e2ee.js';
        const __this = this;
        s.onload = function () {
            __this.afterScriptAdded();
        };
        this.elementRef.nativeElement.appendChild(s);
    }

    afterScriptAdded() {
        if (window['paytrace']) {
            this.paytrace = new Paytrace(window['paytrace']);
            this.paytrace.setKeyAjax('/assets/public_key.pem');
        }
    }

    refreshCartItems() {
        this.cartItems = this.cartService.getItems();
    }

    calculateTotalPrice() {
        this.total = this.cartService.totalCost() - this.cartService.getTax() + this.totalTaxes + this.shipping;
        let discountAmount = 0;
        for (var i = 0; i < this.cartItems.length; i++) {
            discountAmount += (this.dbService.getDiscountAmount(this.cartItems[i]) * this.cartItems[i].getQuantity());
        }
        this.total = this.total - Math.abs(discountAmount);
        return this.total;
    }
    isStateActive(state) {
        if (state === this.STATE_EMAIL) return true;
        if (state === this.STATE_BILLING_ADDRESS) return !this.isContinueButtonDisabled(this.STATE_EMAIL);
        if (state === this.STATE_SHIPPING_ADDRESS) return !this.isContinueButtonDisabled(this.STATE_BILLING_ADDRESS);
        if (state === this.STATE_PAYMENT_INFO) return !this.isContinueButtonDisabled(this.STATE_SHIPPING_ADDRESS) && !this.isContinueButtonDisabled(this.STATE_BILLING_ADDRESS);
    }

    getStepTextClass(state) {
        if (this.isStateActive(state)) return 'Small-Label';
        return 'Deactivated-Small-Label';
    }

    getStepButtonBackground(state) {
        if (this.isStateActive(state)) return 'White-BG Step-Container';
        return 'SlateGrey-BG Step-Container';
    }

    onStepClick(state) {
        if (this.isStateActive(state))
            this.openState = state;
    }

    onEmailChange() {
    }

    ngAfterViewChecked() {
        if (this.openState !== this.STATE_PAYMENT_INFO || this.paymentType.value === 'credit') {
            this.paypalInitialized = false;
        }
        if (this.openState === this.STATE_PAYMENT_INFO && this.paypalElement && !this.paypalInitialized && this.paymentType.value === 'paypal') {
            var that = this;
            var FUNDING_SOURCES = [
                paypal.FUNDING.PAYPAL,
                paypal.FUNDING.VENMO,
                paypal.FUNDING.PAYLATER,
            ];
            // Loop over each funding source/payment method
            FUNDING_SOURCES.forEach(function (fundingSource) {

                // Initialize the buttons
                var button = paypal.Buttons({
                    fundingSource: fundingSource,
                    createOrder: function (data, actions) {
                        return actions.order.create({
                            purchase_units: [{
                                amount: {
                                    value: that.calculateTotalPrice()
                                }
                            }]
                        });
                    },
                    onApprove: function (data, actions) {
                        return actions.order.capture().then(function (orderData) {
                            that.onPaypalApprove(data, actions);
                        });
                    },
                    onCancel: function (data) {
                        // Show a cancel page, or return to cart  
                        //console.log(data);
                    },
                    onError: function (err) {
                        // Show an error page here, when an error occurs  
                        //console.log(err);
                    }
               });

                // Check if the button is eligible
                if (button.isEligible()) {

                    // Render the standalone button for that funding source
                    button.render(that.paypalElement.nativeElement);
                }
            });
            //paypal.Buttons({
            //    createOrder: function (data, actions) {
            //        return actions.order.create({
            //            purchase_units: [{
            //                amount: {
            //                    value: that.total
            //                }
            //            }]
            //        });
            //    },
            //    onApprove: function (data, actions) {
            //        console.log(data);
            //        this.onApprove(data, actions);
            //    },
            //    onCancel: function (data) {
            //        // Show a cancel page, or return to cart  
            //        console.log(data);
            //    },
            //    onError: function (err) {
            //        // Show an error page here, when an error occurs  
            //        console.log(err);
            //    }

            //}).render(this.paypalElement.nativeElement);
            this.paypalInitialized = true;
        }
    }

    nextStep() {
        if (this.openState === this.STATE_EMAIL) this.openState = this.STATE_BILLING_ADDRESS;
        else if (this.openState === this.STATE_BILLING_ADDRESS) this.openState = this.STATE_SHIPPING_ADDRESS;
        else if (this.openState === this.STATE_SHIPPING_ADDRESS) {
            this.openState = this.STATE_PAYMENT_INFO;
            //this.orderService.getPaypalToken().subscribe(result => {
            //    dropin.client.create({
            //        authorization: result['clientToken'],
            //    }).then((clientInstance) => {
            //        dropin.paypalCheckout.create({
            //            client: clientInstance
            //        });
            //        }).then((paypalCheckoutInstance) => {
            //            return paypal.Buttons({
            //            createOrder: function () {
            //                return paypalCheckoutInstance.createPayment({
            //                    flow: 'checkout',
            //                    currency: 'USD',
            //                    amount: '10.00',
            //                    intent: 'capture' // this value must either be `capture` or match the intent passed into the PayPal SDK intent query parameter
            //                    // your other createPayment options here
            //                });
            //            }
            //        });
            //    });
            //    //dropin.create({
            //    //    authorization: result['clientToken'],
            //    //    selector: '#dropin-container',
            //    //    paymentOptionPriority: ['paypal'],
            //    //    paypal: {
            //    //        flow: 'checkout',
            //    //        amount: this.total,
            //    //        currency: 'USD'
            //    //    }
            //    //    // ...plus remaining configuration
            //    //}).then((dropinInstance) => {
            //    //    // Use `dropinInstance` here
            //    //    this.dropInstance = dropinInstance;
            //    //    return dropinInstance.paypalCheckout.create({
            //    //        client: dropinInstance
            //    //    });
            //    //    // Methods documented at https://braintree.github.io/braintree-web-drop-in/docs/current/Dropin.html
            //    //}).then(function (paypalCheckoutInstance) {
            //    //    return paypalCheckoutInstance.loadPayPalSDK({
            //    //        currency: 'USD'
            //    //    });
            //    //}).catch((error) => {
            //    //    console.log(error)
            //    //});
            //});
        }
    }

    isContinueButtonDisabled(state) {
        if (state === this.STATE_EMAIL) {
            if (this.emailAddress.value && String(this.emailAddress.value)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) return false;
            return true;
        } else if (state === this.STATE_BILLING_ADDRESS) {
            if ((!this.firstName.value && !this.lastName.value) || !this.streetAddress1.value || !this.city.value || !this.state.value || !this.zipCode.value) return true;
            if (String(this.zipCode.value).match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)) return false;
            return true;
        } else if (state === this.STATE_SHIPPING_ADDRESS) {
            if (this.isShippingAddressIncluded) return false;
            if ((!this.shippingFirstName.value && !this.shippingLastName.value) || !this.shippingStreetAddress1.value || !this.shippingCity.value || !this.shippingState.value || !this.shippingZipCode.value) return true;
            if (String(this.shippingZipCode.value).match(/(^\d{5}$)|(^\d{5}-\d{4}$)/)) return false;
            return true;
        } else if (state === this.STATE_PAYMENT_INFO) {
            if (!this.cc.valid || !this.expiry.valid || !this.cvc.valid) return true;
            return false;
        }
    }

    getFormattedAddress(state) {
        let address = '';
        if (state === this.STATE_BILLING_ADDRESS) {
            if (!this.streetAddress1.value || !this.city.value || !this.state.value || !this.zipCode.value) return address;
            address = this.streetAddress1.value;
            if (this.streetAddress2.value) address += ', ' + this.streetAddress2.value;
            address += ', ' + this.city.value;
            address += ', ' + this.state.value;
            address += ' ' + this.zipCode.value;
        } else if (state === this.STATE_SHIPPING_ADDRESS) {
            if (this.isShippingAddressIncluded && this.zipCode.value) return '(same as billing address)';
            if (!this.shippingStreetAddress1.value || !this.shippingCity.value || !this.shippingState.value || !this.shippingZipCode.value) return address;
            address = this.shippingStreetAddress1.value;
            if (this.shippingStreetAddress2.value) address += ', ' + this.shippingStreetAddress2.value;
            address += ', ' + this.shippingCity.value;
            address += ', ' + this.shippingState.value;
            address += ' ' + this.shippingZipCode.value;
        }
        return address;
    }

    getShippingCost(items: CustomerDesignedDoor[]): number {
        if (!items) return 0;
        let totalItems = 0;
        for (var i = 0; i < items.length; i++) {
            totalItems += items[i].quantity;
        }
        let baseShipping = this.dbService.getShippingCost(totalItems);
        return baseShipping;
        //if (totalItems > 0) {
        //    if (totalItems > 2) return 310.00 + (100.00 * (totalItems - 2))
        //    return 310.00;
        //}
        //return 0.00;
    }

    onZipChange() {
        let applicableZip = this.zipCode.value;
        if (!this.isShippingAddressIncluded) {
            applicableZip = this.shippingZipCode.value;
        }
        this.refreshCartItems();
        if (applicableZip && this.cartItems && this.cartItems.length > 0) {
            let lineItems = [];
            this.orderService.calculateTaxes(applicableZip).subscribe(result => {
                if (result && result['taxAmount']) {
                    var taxRate = result['taxAmount'];
                    this.updateTaxes(taxRate);
                    //this.dbService.setTaxRate(taxRate);
                    //let totalApplicableTaxes = 0.00;
                    //for (var i = 0; i < this.cartItems.length; i++) {
                    //    let currentItem = this.cartItems[i];
                    //    let itemTaxableAmount = (typeof currentItem.getPrice() === 'string' ? parseFloat(currentItem.getPrice()) : currentItem.getPrice());
                    //    let itemTax = Math.round(itemTaxableAmount * taxRate * 100) / 100;
                    //    this.cartItems[i].setTax(itemTax);
                    //    totalApplicableTaxes += (itemTax * currentItem.getQuantity());
                    //}
                    //this.dbService.setApplicableTaxes(totalApplicableTaxes);
                    //this.totalTaxes = totalApplicableTaxes;
                    //this.totalTaxesDisplayed = this.totalTaxes.toFixed(2);
                    //this.total = this.totalPrice + this.totalTaxes;
                    //this.shipping = this.getShippingCost(this.cartItems);
                    //this.shippingDisplayed = this.shipping.toFixed(2);
                    //this.total += this.shipping;
                    //this.totalDisplayed = this.total.toFixed(2);
                } else {
                    this.updateTaxes(0.00);
                }
            });
            var that = this;
            this.orderService.isValidZipcode(applicableZip).subscribe(result => {
                if (result && result['validZipcode'] !== undefined) {
                    that.isHideDeliveryAreaWarning = result['validZipcode'];
                    that.isPlaceOrderDisabled = !that.isHideDeliveryAreaWarning;
                    if (!that.isPlaceOrderDisabled) {
                        this.orderService.calculateShippingByDistance(this.getCustomer()).subscribe(result => {
                            if (result) {
                                console.log("result = " + result);
                                this.dbService.additionalShipping = result['additionalShipping'];
                                this.shipping = this.getShippingCost(this.cartItems);
                            }
                        });
                    }
                }
            });
        }
    }

    updateTaxes(taxRate) {
        this.dbService.setTaxRate(taxRate);
        let totalApplicableTaxes = 0.00;
        for (var i = 0; i < this.cartItems.length; i++) {
            let currentItem = this.cartItems[i];
            let itemTaxableAmount = (typeof currentItem.getPrice() === 'string' ? parseFloat(currentItem.getPrice()) : currentItem.getPrice());
            let itemTax = Math.round(itemTaxableAmount * taxRate * 100) / 100;
            this.cartItems[i].setTax(itemTax);
            totalApplicableTaxes += (itemTax * currentItem.getQuantity());
        }
        this.dbService.setApplicableTaxes(totalApplicableTaxes);
        this.totalTaxes = totalApplicableTaxes;
        this.totalTaxesDisplayed = this.totalTaxes.toFixed(2);
        this.total = this.totalPrice + this.totalTaxes;
        this.shipping = this.getShippingCost(this.cartItems);
        this.shippingDisplayed = this.shipping.toFixed(2);
        this.total += this.shipping;
        this.totalDisplayed = this.total.toFixed(2);
    }

    onChangeIsShippingAddressIncluded() {
        this.isShippingAddressIncluded = !this.isShippingAddressIncluded;
    }

    onPaypalApprove(data, actions) {
        let orderSummaryItems = [];
        this.refreshCartItems();
        for (var i = 0; i < this.cartItems.length; i++) {
            let summaryItems = this.dbService.getSummaryItems(this.cartItems[i], true);
            orderSummaryItems.push(summaryItems);
        }

        var saleData = {
            amount: this.calculateTotalPrice(),
            tax: this.totalTaxes,
            shipping: this.getShippingCost(this.cartItems),
            customer: this.getCustomer(),
            customerDesignedDoors: this.cartItems,
            summaryItems: orderSummaryItems,
            billing_address: {
                name: this.getFormattedName(this.STATE_BILLING_ADDRESS),
                street_address: this.streetAddress2.value ? this.streetAddress1.value + ' ' + this.streetAddress2.value : this.streetAddress1.value,
                city: this.city.value,
                state: this.state.value,
                zip: this.zipCode.value
            },
            comments: this.comments.value ? this.comments.value : null,
        }
        this.orderService.recordPaypalOrder(saleData).subscribe(result => {
            if (result && result['paypalResponse']) {
                this.router.navigate(['/cart/successful-order/' + saleData.amount.toFixed(2)]);
            }
        }, err => {
            console.log(err);
            alert(err && err.error && err.error.error ? err.error.error : err.statusText);
        }, () => {
            console.log("how did I end up here?");
        });
    }

    onSubmitCC(form: FormGroup) {
        // TODO - null checking and such
        if (this.isPlaceOrderDisabled) {
            alert("Delivery in your area will require additional fees.  Please contact Garage Door Supply House at 1-(877)-870-DOOR (3667).");
            return;
        }
        this.isPlaceOrderDisabled = true;
        var encCC = this.cc.value ? this.paytrace.encryptValue(this.cc.value) : null;
        var encCSC = this.cc.value ? this.paytrace.encryptValue(this.cvc.value) : null;
        var expiryMonth = '';
        var expiryYear = '';
        var expiration = this.expiry.value;
        if (expiration) {
            if (expiration.indexOf('/') > 0) {
                var expireSegments = expiration.split("/");
                expiryMonth = expireSegments[0].trim();
                expiryYear = expireSegments[1].trim();
            } else {
                expiryMonth = expiration.substr(0, 2);
                expiryYear = expiration.substr(2);
            }
            var now = new Date(Date.now());
            var twoDigitYear = now.getFullYear().toString().substr(-2);
            var century = now.getFullYear().toString().substr(0, 2);
            //if (parseInt(expiryYear) <= parseInt(twoDigitYear)) {
                expiryYear = century + expiryYear;
            //} else {
            //    expiryYear = (parseInt(century) - 1) + '' + expiryYear;
            //}
            if (expiryMonth.length === 1) expiryMonth = '0' + expiryMonth;
        }
        var headers = {
            'Authorization': this.ptBearerToken,
            'Accept': '*/*',
            'Content-Type': 'application/json'
        };

        var shippingAddress = this.streetAddress1.value;
        if (this.shippingStreetAddress1.value) {
            shippingAddress = this.shippingStreetAddress1.value;
            if (this.shippingStreetAddress2.value) shippingAddress += ' ' + this.shippingStreetAddress2;
        } else if (this.streetAddress2.value) {
            shippingAddress += ' ' + this.streetAddress2.value;
        }

        let orderSummaryItems = [];
        this.refreshCartItems();
        for (var i = 0; i < this.cartItems.length; i++) {
            let summaryItems = this.dbService.getSummaryItems(this.cartItems[i], true);
            orderSummaryItems.push(summaryItems);
        }

        var saleData = {
            integrator_id: '9220992viy4k',
            amount: this.calculateTotalPrice(),
            tax: this.totalTaxes,
            shipping: this.getShippingCost(this.cartItems),
            credit_card: {
                encrypted_number: encCC,
                expiration_month: expiryMonth,
                expiration_year: expiryYear
            },
            encrypted_csc: encCSC,
            customer: this.getCustomer(),
            customerDesignedDoors: this.cartItems,
            summaryItems: orderSummaryItems,
            billing_address: {
                name: this.getFormattedName(this.STATE_BILLING_ADDRESS),
                street_address: this.streetAddress2.value ? this.streetAddress1.value + ' ' + this.streetAddress2.value : this.streetAddress1.value,
                city: this.city.value,
                state: this.state.value,
                zip: this.zipCode.value
            },
            comments: this.comments.value ? this.comments.value : null,
            paymentType: this.paymentType.value,
        }
        if (this.isTest) {
            saleData.credit_card.encrypted_number = this.paytrace.encryptValue('6011000993026909');
            saleData.encrypted_csc = this.paytrace.encryptValue('996');
            saleData.billing_address.street_address = '8320 Main St';
            saleData.billing_address.zip = '85284';
            saleData.credit_card.expiration_month = '12';
            saleData.credit_card.expiration_year = '2020';
        }
        this.orderService.submitOrder(saleData).subscribe(result => {
            if (result && result['paytraceResponse']) {
                let amount = saleData.amount.toFixed(2);
                if (result['paytraceResponse'] == "OUT_OF_DELIVERY_AREA") {
                    amount = result['paytraceResponse'];
                }
                this.router.navigate(['/cart/successful-order/' + amount]);
            }
            this.isPlaceOrderDisabled = false;
        }, err => {
            this.isPlaceOrderDisabled = false;
            console.log(err);
            alert(err && err.error && err.error.error ? err.error.error : err.statusText);
            }, () => {
                console.log("how did I end up here?");
            });
        //this.http.post('https://cors-anywhere.herokuapp.com/https://api.paytrace.com/v1/transactions/sale/keyed', saleData, { headers: headers }).subscribe(
        //    response => {
        //        this.processPaytraceRequest(response);
        //    }, error => {
        //        this.processPaytraceRequest(error['error']);
        //    }
        //);
    }

    //showThankYouWindow() {
    //    this.modalService.open('thank-you');
    //}

    //hideThankYouWindow() {
    //    this.modalService.close('thank-you');
    //    this.dbService.zipcode = null;
    //    this.dbService.selectedStyle = null;
    //    this.dbService.removeSelections();
    //    this.dbService.startOver();
    //    this.cartService.clear();
    //    this.router.navigate(['/door-builder-home']);
    //}

    getFormattedName(state) {
        if (state === this.STATE_BILLING_ADDRESS) {
            if (this.firstName.value && this.lastName.value) {
                return this.firstName.value + ' ' + this.lastName.value;
            } else if (this.firstName.value) {
                return this.firstName.value;
            } else {
                return this.lastName.value;
            }
        } else if (state === this.STATE_SHIPPING_ADDRESS) {
            if (this.shippingFirstName.value && this.shippingLastName.value) {
                return this.shippingFirstName.value + ' ' + this.shippingLastName.value;
            } else if (this.shippingFirstName.value) {
                return this.shippingFirstName.value;
            } else {
                return this.shippingLastName.value;
            }
        }
    }

    processPaytraceRequest(response: any) {
        var parsed = {
            success: response['success'],
            transaction_id: '',
            status_message: ''
        };
        if (response['transaction_id'])
            parsed.transaction_id = response['transaction_id'];
        else
            parsed.transaction_id = response['external_transaction_id'];
        if (response['errors'])
            parsed.status_message = response['errors'][0];
        else
            parsed.status_message = response['status_message'];

        if (parsed.success) {
            alert("Success! TransactionID = " + parsed.transaction_id);
        } else {
            alert("Denied - TransactionID = " + parsed.transaction_id);
        }

        // TODO - store success in database
    }

    getCustomer() {
        return {
            contact: {
                firstName: this.firstName.value,
                lastName: this.lastName.value,
                emailAddress: this.emailAddress.value,
                phoneNumber: this.phoneNumber.value,
            },
            // TODO - shipping address vs billing address
            shippingAddress: {
                contact: !this.isShippingAddressIncluded ? {
                    firstName: this.shippingFirstName.value,
                    lastName: this.shippingLastName.value,
                    phoneNumber: this.shippingPhoneNumber.value
                } : null,
                streetAddress: !this.isShippingAddressIncluded ? this.shippingStreetAddress1.value : this.streetAddress1.value,
                streetAddress2: !this.isShippingAddressIncluded ? this.shippingStreetAddress2.value : this.streetAddress2.value,
                city: !this.isShippingAddressIncluded ? this.shippingCity.value : this.city.value,
                state: !this.isShippingAddressIncluded ? this.shippingState.value : this.state.value,
                zip: !this.isShippingAddressIncluded ? this.shippingZipCode.value : this.zipCode.value
            }
        };
    }

    submitToPaypal() {
        this.dropInstance.requestPaymentMethod().then((payload) => {
            // Step four: when the user is ready to complete their
            //   transaction, use the dropinInstance to get a payment
            //   method nonce for the user's selected payment method, then add
            //   it a the hidden field before submitting the complete form to
            //   a server-side integration
            this.nonceVal.setValue(payload.nonce);
            //form.submit();
        });
    }

    isMobile() {
        if (!this.scrWidth) this.getScreenSize();
        return this.scrWidth <= 1224
    }
    getScreenSize(event?) {
        this.scrWidth = window.innerWidth;
    }

    disablePlaceOrderButton() {
        if (!this.paymentType.value) return true;
        //if (this.isPlaceOrderDisabled) return true;
        if (!this.ccForm.valid && this.paymentType.value === 'credit') return true;
        return false;
    }
    isPaypalDisabled() {
        return this.isPlaceOrderDisabled;
    }
}