﻿import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ContactUsComponent } from './contact-us.component';
import {
    MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
    MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavMenuModule } from '../nav-menu/nav-menu.module';
import { NewsletterSubscribeModule } from '../shared/components/newsletter-subscribe/newsletter-subscribe.module';
import { CategoryLinksModule } from '../shared/components/category-links/category-links.module';
import { FooterModule } from '../shared/components/footer/footer.module';
import { ModalModule } from '../shared/components/modal/modal.module';

@NgModule({
    imports: [MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
        MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule, NavMenuModule, NewsletterSubscribeModule, CategoryLinksModule, FooterModule, ModalModule,
        RouterModule, CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [ContactUsComponent],
    exports: [ContactUsComponent]
})
export class ContactUsModule { }
