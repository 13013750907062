﻿import { Component, ChangeDetectorRef } from '@angular/core';
import { BuildADoorService } from '../shared/services';

@Component({
    selector: 'app-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent {
    constructor(private dbService: BuildADoorService, private changeDetector: ChangeDetectorRef) { }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

}