import { ProductPrice } from './product-price';

export class Product {
  _id: string;
  name: string;
  pricingSheet: string;
  rating: string;
  rValue: string;
  productPrices: ProductPrice[];
  selected: boolean;

  constructor(p?) {
    if (p) {
      this._id = p._id;
      this.name = p.name;

      if (p.min_r_value && p.max_r_value) {
        this.rValue = p.min_r_value + ' - ' + p.max_r_value;
      } else if (p.min_r_value) {
        this.rValue = p.min_r_value;
      } else if (p.max_r_value) {
        this.rValue = p.max_r_value;
      } else {
        this.rValue = '';
      }

      if (p.pricing_sheet) {
        this.pricingSheet = p.pricing_sheet.replace(/^.*[\\\/]/, '');
      }

      this.rating = p.rating;
      this.selected = false;

      this.productPrices = p.productPrices;
    }
  }
}