import { Component } from '@angular/core';
import { DBNavMenuComponent } from '../shared/components/db-nav-menu/db-nav-menu.component';
import { BuildADoorService } from '../shared/services/build-a-door.service';
import { CustomerDesignedDoor } from '../shared/models';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-build-a-door',
    templateUrl: './build-a-door.component.html',
    styleUrls: ['./build-a-door.component.css']
})

export class BuildADoorComponent {
    constructor(private dbService: BuildADoorService, private activeRoute: ActivatedRoute) {
    }

    ngOnInit() {
    }
}
