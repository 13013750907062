﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { CustomerDesignedDoor } from '../models';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    constructor(private http: HttpClient) { }

    isValidZipcode(zipCode: string): Observable<Object> {
        return this.http.get<Object>('orders/isValidZipcode', { params: { zip: zipCode } });
    }
    calculateShippingByDistance(customer: Object): Observable<Object> {
        let order = {
            customer: customer
        };
        return this.http.post<Object>('orders/calculateShippingByDistance', { order: order });
    }

    calculateTaxes(zipCode: string): Observable<Object> {
        return this.http.get<Object>('orders/calculateTaxes', { params: { zip: zipCode } });
    }

    getOrderTaxes(doors: Object[], shipping: number, customer: Object): Observable<Object> {
        return this.http.post<Object>('order/getOrderTax', { params: { doors: doors, shipping: shipping, customer: customer } });
    }

    submitOrder(order: Object): Observable<Object> {
        return this.http.post<Object>('orders/submitOrder', { order: order });
    }

    recordPaypalOrder(order: Object): Observable<Object> {
        return this.http.post<Object>('orders/recordPaypalOrder', { order: order });
    }

    sendQuote(order: Object): Observable<Object> {
        return this.http.post<Object>('orders/sendQuote', { order: order });
    }

    contactUs(customer: Object, message: string): Observable<Object> {
        return this.http.post<Object>('orders/contactUs', { customer: customer, message: message });
    }

    newsletterSubscribe(emailAddress: string) {
        return this.http.post<Boolean>('orders/newsletterSubscribe', { emailAddress: emailAddress });
    }

    getPaypalToken() {
        return this.http.get('orders/paypal_token');
    }
    getCouponCode(code: string) {
        return this.http.post<Object>('orders/getCouponCode', { code: code });
    }
}