﻿import { CartItem } from 'ng-shopping-cart';

export class GDSHCartItem extends CartItem {
    public width: string;
    public height: string;
    public productGroupId: string;
    public designId: string;
    public insulationOptionId: string;
    public windowId: string;
    public colorId: string;
    public description: string;
    public price: any;
    public image: string;
    public quantity: number;

    constructor(itemData: any) {
        super();
        if (itemData) {
            this.productGroupId = itemData.productGroupId;
            this.width = itemData.width;
            this.height = itemData.height;
            this.designId = itemData.designId;
            this.insulationOptionId = itemData.insulationOptionId;
            this.windowId = itemData.windowId;
            this.colorId = itemData.colorId;
            this.description = itemData.description;
            this.price = itemData.price;
            this.image = itemData.image;
            this.quantity = itemData.quantity;
        }
    }

    public getId(): any {
        return this.productGroupId;
    }

    public getName(): string {
        return this.description;
    }

    public getPrice(): any {
        return this.price;
    }

    public getImage(): string {
        return this.image;
    }

    public getQuantity(): any {
        return this.quantity;
    }

    public setQuantity(quantity: number): void {
        this.quantity = quantity;
    }
}