import { DoorInsulationLevel } from './index';
import { InsulationSpecification } from './insulation-specification';
export class DoorInsulationOption {
    _id: string;
    name: string;
    imageURL: string;
    description: string;
details: string[];
displayOrder: number;
    insulationLevel: DoorInsulationLevel;
    rValue: number;
    price: number;
    specifications: InsulationSpecification[];
    disallowInches: boolean;
}
