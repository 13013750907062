﻿import { Component, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BuildADoorService } from '../shared/services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-db-home',
    templateUrl: './db-home.component.html',
    styleUrls: ['./db-home.component.css']
})
export class DoorBuilderHomeComponent {
    zipCode = new FormControl(null);

    constructor(public dbService: BuildADoorService, public router: Router, private changeDetector: ChangeDetectorRef, private activeRoute: ActivatedRoute) { }

    ngOnInit() {
        this.activeRoute.queryParams.subscribe(params => {
            let productName = params['productName'];
            if (productName) {
                this.dbService.startOver();
                this.dbService.getProductGroups().subscribe(result => {
                    if (result) {
                        if (result['productGroups']) {
                            let productGroups = result['productGroups'];
                            productGroups.sort((a, b) => Number(a['minBasePrice']) > Number(b['minBasePrice']) ? 1 : (Number(b['minBasePrice']) > Number(a['minBasePrice']) ? -1 : 0));
                            let incomingProductGroup = productGroups.find(pg => pg.identifier.replace(/\s+/g, '') === productName);
                            this.dbService.setProductGroupSelectionSync(incomingProductGroup);
                        }
                    }
                    this.router.navigate(['/door-builder']);
                });
            } else {
                this.router.navigate(['/door-builder']);
            }
        });

    }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }

    onZipChange() { }

    onClickGetStarted() {
        this.dbService.zipcode = this.zipCode.value
        this.router.navigate(['/door-builder']);
    }
}