﻿import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NewsletterSubscribeComponent } from './newsletter-subscribe.component';
import {
    MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
    MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from '../modal/modal.module';

@NgModule({
    imports: [MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
        MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule,
        RouterModule, CommonModule, FormsModule, ReactiveFormsModule, ModalModule],
    declarations: [NewsletterSubscribeComponent],
    exports: [NewsletterSubscribeComponent]
})
export class NewsletterSubscribeModule { }
