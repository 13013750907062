﻿import { Component } from '@angular/core';
import { DBStateComponent } from 'src/app/build-a-door/shared/components/db-state.component';
import { DoorColor, ColorWheelOption } from 'src/app/shared/models';
import { SlideInOutAnimation } from 'src/app/shared/styles/animations/slide-in-out-animation';
import { FormControl } from '@angular/forms';
import { BuildADoorService, DBFooterService, ProductService } from 'src/app/shared/services';
import { Router } from '@angular/router';

@Component({
    selector: 'app-select-color',
    templateUrl: './color.component.html',
    styleUrls: ['./color.component.css'],
    animations: [SlideInOutAnimation]
})
export class DBDesignColorComponent extends DBStateComponent {
    private colors: DoorColor[];
    private childColors: DoorColor[];
    private animationState: string = 'out';
    private colorZoneAnimationState: string = 'out';
    private colorZoneCode = new FormControl(null);
    private selectedColorZone: ColorWheelOption;
    private colorZoneOptions: ColorWheelOption[];
    private allColorZoneOptions: ColorWheelOption[];
    private currentColorZoneCode: string = '';
    private colorZoneColor: DoorColor;

    constructor(public dbService: BuildADoorService, public router: Router, public dbFooterService: DBFooterService, private productService: ProductService) {
        super(dbService, router, dbFooterService);
    }

    async ngOnInit() {
        super.verifyState();
        //if (!this.dbService.customerDesignedDoor.doorDesign || !this.dbService.customerDesignedDoor.insulation) {
        //    this.router.navigate(['/door-builder/design/design-home']);
        //}

        this.colors = this.dbService.customerDesignedDoor.doorDesign.getColors(this.dbService.customerDesignedDoor.insulation);
        this.colors = super.sortCollectionByDisplayOrder(this.colors)
        if (this.dbService.customerDesignedDoor.productGroup.hasColorZone) {
            // add color zone image
            this.productService.getColorZoneOptions().subscribe(result => {
                if (result) {
                    this.allColorZoneOptions = result['colorZones'];
                    this.allColorZoneOptions.sort(function (a, b) {
                        var baseColorCompare = a.baseColor > b.baseColor ? 1 : a.baseColor < b.baseColor ? -1 : 0
                        var fourDigitCodeCompare = parseInt(a.fourDigitCode) - parseInt(b.fourDigitCode);
                        return baseColorCompare || fourDigitCodeCompare;
                    });
                    this.colorZoneOptions = [];
                    this.colorZoneOptions = this.colorZoneOptions.concat(this.allColorZoneOptions);

                    if (this.dbService.customerDesignedDoor.color && this.dbService.customerDesignedDoor.color.isColorZone) {
                        this.currentColorZoneCode = this.dbService.customerDesignedDoor.color.code;
                        this.colorZoneOptions = this.filterColorZones(this.allColorZoneOptions);
                        this.selectedColorZone = this.colorZoneOptions[0];
                        this.colorZoneAnimationState = 'in';
                    } else {
                        this.colorZoneAnimationState = 'out';
                    }
                }
            });
            if (!this.colorZoneColor) {
                this.colorZoneColor = new DoorColor();
                this.colorZoneColor.name = this.dbService.customerDesignedDoor.COLOR_ZONE_NAME;
                this.colorZoneColor.imageURL = "/assets/images/color-chart.png";
                this.colors.push(this.colorZoneColor);
            }
        }
        this.setCollectionWrapperStyling(this.colors);
        super.ngOnInit();
    }

    getCollectionStyle(index) {
        let style = super.collectionStyle(index);
        if (!this.isMobile()) {
            var colNum = 1 + (index % 2);
            style['grid-column'] = '' + colNum;
        }
        return style;
    }

    getCollectionClass(c) {
        if (c.name === this.dbService.customerDesignedDoor.COLOR_ZONE_NAME) {
            if (this.isColorZoneCodeSelected()) {
                return 'Collection-Button-Selected';
            }
            return 'Collection-Button-Unselected';
        }
        return super.collectionClass(this.dbService.customerDesignedDoor.color, c);
    }

    onClickColor(c) {
        if (c === this.dbService.customerDesignedDoor.color) return;
        if (c.name === this.dbService.customerDesignedDoor.COLOR_ZONE_NAME) {
            this.colorZoneAnimationState = 'in';
            return;
        } else {
            this.colorZoneAnimationState = 'out';
            this.currentColorZoneCode = '';
            if (this.dbService.customerDesignedDoor.productGroup.hasColorZone && this.allColorZoneOptions) {
                this.colorZoneOptions = this.filterColorZones(this.allColorZoneOptions);
            }
            this.selectedColorZone = null;
        }
        this.dbService.customerDesignedDoor.color = c;
        this.dbService.customerDesignedDoor.childColor = null;
        if (this.dbService.customerDesignedDoor.color.childColors && this.dbService.customerDesignedDoor.color.childColors.length) {
            this.animationState = 'in';
            this.childColors = this.dbService.customerDesignedDoor.color.childColors;
            this.childColors = super.sortCollectionByDisplayOrder(this.childColors)
        } else {
            this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
            this.animationState = 'out';
        }
    }

    getChildCollectionStyle(index) {
        let style = super.collectionStyle(index);
        if (!this.isMobile()) {
            style['padding'] = '10px 10px';
            style['min-height'] = '91.2px';
            var colNum = 1 + (index % 2);
            style['grid-column'] = '' + colNum;
        }
        return style;
    }

    getChildCollectionClass(c) {
        return super.collectionClass(this.dbService.customerDesignedDoor.childColor, c);
    }

    onClickChildColor(c) {
        this.dbService.customerDesignedDoor.childColor = c;
        this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
    }

    isShowGroupLabels() {
        if (this.animationState === 'in') return true;
        return false;
    }

    isShowContinue() {
        if (this.dbService.customerDesignedDoor.color) {
            if (this.dbService.customerDesignedDoor.color.childColors && this.dbService.customerDesignedDoor.color.childColors.length) {
                if (this.dbService.customerDesignedDoor.childColor) return true;
                return false;
            } else {
                return true;
            }
        }
        return false;
    }

    getPrice(c, flag): string {
        let price = this.dbService.customerDesignedDoor.getColorPrice(c);
        if ((!price || price <= 0) && !c.isWhite && !(this.dbService.customerDesignedDoor.productGroup.name === 'Horizon') && !(this.dbService.customerDesignedDoor.productGroup.name === 'Vista')) {
            price = +this.dbService.price * 0.015;
        }
        return super.getPrice({ price: price }, flag);
    }

    onClickBack() {
        this.dbService.navButtonPressed = true;
        if (this.dbService.isHideWindows()) {
            if (this.dbService.isHideInsulation()) {
                if (this.dbService.isHidePanelDesigns()) {
                    this.router.navigate(['/door-builder/design/select-model']);
                } else {
                    this.router.navigate(['/door-builder/design/panel-design']);
                }
            } else {
                this.router.navigate(['/door-builder/design/select-insulation']);
            }
        } else {
            this.router.navigate(['/door-builder/design/select-window']);
        }

    }

    onColorZoneCodeChange() {
        this.colorZoneOptions = this.filterColorZones(this.allColorZoneOptions);
        if (this.colorZoneOptions && this.colorZoneOptions.length === 1) {
            this.selectedColorZone = this.colorZoneOptions[0];
            let czc = new DoorColor();
            czc.name = this.selectedColorZone.name;
            czc.code = this.selectedColorZone.fourDigitCode;
            czc.isColorZone = true;
            czc.price = this.dbService.customerDesignedDoor.getColorPrice(this.colorZoneColor);
            this.dbService.customerDesignedDoor.color = czc;
            this.dbService.customerDesignedDoor.childColor = null;
            this.dbFooterService.updateNumComplete(this.dbService.customerDesignedDoor.getNumDesignStepsComplete());
        }
    }

    isColorZoneCodeSelected() {
        return this.colorZoneOptions && this.colorZoneOptions.length === 1;
    }

    filterColorZones(values) {
        return values.filter(cz => cz.fourDigitCode.includes(this.currentColorZoneCode) || cz.name.toLowerCase().includes(this.currentColorZoneCode.toLowerCase()));
    }
}