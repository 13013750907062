﻿export class Discount {
    _id: string;
    code: string;
    amount: number;
    percent: number;

    constructor(c?) {
        if (c) {
            this._id = c._id;
            this.code = c.code;
            this.percent = c.percent;
            this.amount = c.amount;
        }
    }
}