import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { SliderComponent } from './shared/components/slider/slider.component';
import { SliderItemDirective } from './shared/components/slider/slider-item.directive';
import { AppComponent } from './app.component';
import { environment } from '../../src/environments/environment';
import { BreadcrumbComponent } from '../app/shared/components/breadcrumb/breadcrumb.component';
//import { MatDialogModule } from '@angular/material/dialog';
import {
    MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
    MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule
} from '@angular/material';
import { MatCardModule } from '@angular/material/card';
//import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { HomeComponent } from './home/home.component';
import { ProductsComponent } from './products/products.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { OurStoryComponent } from './our-story/our-story.component';
import { DoorBuilderHomeComponent } from './door-builder-home/db-home.component';
import { APIInterceptor } from './shared/interceptors/api.interceptor';
import { BuildADoorModule } from './build-a-door/build-a-door.module';
import { CartModule } from './cart/cart.module';
import { CART_ITEM_CLASS, CART_SERVICE_CONFIGURATION, LocalStorageCartService } from 'ng-shopping-cart';
import { NavMenuModule } from './nav-menu/nav-menu.module';
import { FooterModule } from './shared/components/footer/footer.module';
import { FindHelpModule } from '../app/shared/components/find-help/find-help.module';
import { HowToMeasureModule } from '../app/shared/components/how-to-measure/how-to-measure.module';
import { CategoryLinksModule } from '../app/shared/components/category-links/category-links.module';
import { NewsletterSubscribeModule } from 'src/app/shared/components/newsletter-subscribe/newsletter-subscribe.module';
import { ModalModule } from './shared/components/modal/modal.module';
import { DBNavMenuModule } from './shared/components/db-nav-menu/db-nav-menu.module';
//import { CartComponent } from './cart/cart.component';
import { CustomerDesignedDoor } from './shared/models';
import { FAQComponent } from './faq/faq.component';
import { Pipes } from './shared/pipes/pipes.module';
import { GalleryComponent } from './gallery/gallery.component';
import { ReviewsComponent } from './reviews/reviews.components';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { WarrantiesComponent } from './warranties/warranties.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { HowToMeasureScreenComponent } from './shared/components/how-to-measure/how-to-measure-screen/how-to-measure-screen.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactUsModule } from './contact-us/contact-us.module';
import { LoginComponent } from './authentication/login/login.component';
import { GDSHCartService } from './cart/gdsh-cart.service';

@NgModule({
  declarations: [
      AppComponent,
      BreadcrumbComponent,
      HomeComponent,
      ProductsComponent,
      GalleryComponent,
      ProductDetailsComponent,
      PromotionsComponent,
      OurStoryComponent,
      FAQComponent,
      DoorBuilderHomeComponent,
      ReviewsComponent,
      TermsAndConditionsComponent,
      HowToMeasureScreenComponent,
      WarrantiesComponent,
      ReturnPolicyComponent,
      LoginComponent,
      SliderComponent,
      SliderItemDirective
      //CartComponent
  ],
  imports: [
      BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
      MatCardModule,
    HttpClientModule,
    BrowserAnimationsModule,
      MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
      MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule,
      //MatAutocompleteModule, 
      FormsModule,
      BuildADoorModule,
      CartModule,
      NavMenuModule,
      FooterModule,
      FindHelpModule,
      HowToMeasureModule,
      CategoryLinksModule,
      NewsletterSubscribeModule,
      ContactUsModule,
      ModalModule,
      DBNavMenuModule,
      Pipes,
      //ShoppingCartModule.forRoot({
      //    itemType: CustomerDesignedDoor,
      //    serviceType: 'localStorage',
      //    serviceOptions: {
      //        storageKey: 'NgShoppingCart',
      //        clearOnError: true
      //    }
      //}),
    RouterModule.forRoot([
        { path: '', component: DoorBuilderHomeComponent, pathMatch: 'full', data: { breadcrumb: 'Home' } },
        //{ path: 'home', component: DoorBuilderHomeComponent, pathMatch: 'full', data: { breadcrumb: 'Home' } },
        //{ path: 'products', component: ProductsComponent, pathMatch: 'full', data: { breadcrumb: 'Products' } },
        //{ path: 'products/:zone', component: ProductsComponent, pathMatch: 'full', data: { breadcrumb: 'Products' } },
        //{ path: 'products/:zone/:style', component: ProductsComponent, pathMatch: 'full', data: { breadcrumb: 'Products' } },
        //{ path: 'product-details/:productGroup', component: ProductDetailsComponent, pathMatch: 'full', data: { breadcrumb: 'ProductDetails' } },
        { path: 'door-builder-home', component: DoorBuilderHomeComponent, data: { breadcrumb: 'Door Builder Home' } },
        //{ path: 'specials', component: PromotionsComponent, data: { breadcrumb: 'Promotions' } },
        //{ path: 'our-advantage', component: OurStoryComponent, data: { breadcrumb: 'Our Story' } },
        //{ path: 'frequently-asked-questions', component: FAQComponent, data: { breadcrumb: 'FAQ' } },
        //{ path: 'gallery', component: GalleryComponent, data: { breadcrumb: 'Gallery' } },
        //{ path: 'residential', redirectTo: 'products/residential' },
        //{ path: 'commercial', redirectTo: 'products/commercial' },
        //{ path: 'door-reviews', component: ReviewsComponent, data: { breadcrumb: 'Reviews' } },
        //{ path: 'terms-and-conditions', component: TermsAndConditionsComponent, data: { breadcrumb: 'Terms and Conditions' } },
        //{ path: 'warranties', component: WarrantiesComponent, data: { breadcrumb: 'Warranties' } },
        //{ path: 'return-policy', component: ReturnPolicyComponent, data: { breadcrumb: 'Cancellation Policy' } },
        //{ path: 'how-to-measure', component: HowToMeasureScreenComponent, data: { breadcrumb: 'How To Measure' } },
        //{ path: 'contact-us', component: ContactUsComponent, data: { breadcrumb: 'Contact Us' } },
        { path: 'admin-login', component: LoginComponent, data: { breadcrumb: 'Admin Login' } },
        //{ path: 'cart', component: CartComponent, pathMatch: 'full', data: { breadcrumb: 'My Cart' } }
      //{ path: 'signup', component: SignupComponent }
    ], {
        scrollPositionRestoration: 'enabled'
        }),
  ],
    providers: [{ provide: 'BASE_API_URL', useValue: environment.apiUrl },
        { provide: 'PAYTRACE_API_URL', useValue: environment.paytraceUrl },
        { provide: 'IS_TEST', useValue: environment.isTest ? true : false },
        { provide: 'BASE_URL', useValue: environment.linkUrl },
        { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: CART_ITEM_CLASS, useValue: CustomerDesignedDoor },
        { provide: CART_SERVICE_CONFIGURATION, useValue: { storageKey: 'NgShoppingCart', clearOnError: true } },
        { provide: GDSHCartService, useClass: LocalStorageCartService }],
  bootstrap: [AppComponent],
    exports: [BrowserAnimationsModule, MatButtonModule, MatProgressBarModule, MatIconModule, MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule,
        MatInputModule, MatFormFieldModule, MatSelectModule, MatOptionModule, MatMenuModule,
        //MatAutocompleteModule, 
        FormsModule, NavMenuModule, FooterModule, FindHelpModule, HowToMeasureModule, ModalModule, DBNavMenuModule]
  //entryComponents: [DeleteDialogComponent]
})
export class AppModule {}
