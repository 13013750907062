import { WidthBasedPricing } from './width-based-pricing';

export class DoorWindow {
    _id: string;
    name: string;
    code: string;
    imageURL: string;
    markupType: string;
    transparency: string;
    nameInURL: string;
    imageName: string;
    price: number;
    widthBasedPricing: WidthBasedPricing[];
    isPricePerLinearFoot: boolean;
    isPricePerSquareFoot: boolean;
    isInsulated: boolean;
    insulatedPrice: number;
    insulatedSelected: boolean;
    glassTypeSelected: boolean;
    displayOrder: number;
    isCustom: boolean;

    constructor(w?) {
        if (w) {
            this._id = w._id;
            this.name = w.name;
            this.code = w.code;
            this.imageURL = w.imageURL;
            this.markupType = w.markupType;
            this.transparency = w.transparency;
            this.nameInURL = w.nameInURL;
            this.imageName = w.imageName;
            this.price = w.price;
            this.isPricePerLinearFoot = w.isPricePerLinearFoot;
            this.isPricePerSquareFoot = w.isPricePerSquareFoot;
            this.isInsulated = w.isInsulated;
            this.insulatedPrice = w.insulatedPrice;
            this.insulatedSelected = w.insulatedSelected;
            this.glassTypeSelected = w.glassTypeSelected;
            this.displayOrder = w.displayOrder;
            this.isCustom = w.isCustom;
        }
    }

}
