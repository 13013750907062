﻿import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ProductService } from '../shared/services/product.service';
import { BuildADoorService } from '../shared/services';
import { DoorSpecial } from '../shared/models';

@Component({
    selector: 'app-our-story',
    templateUrl: './our-story.component.html',
    styleUrls: ['./our-story.component.css']
})
export class OurStoryComponent implements OnInit {

    constructor(private dbService: BuildADoorService, private changeDetector: ChangeDetectorRef) { }

    async ngOnInit() { }

    ngAfterViewChecked() { this.changeDetector.detectChanges(); }
}